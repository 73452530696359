import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';

const MyDataTable = (props) => {
    // Estado para armazenar a largura da janela
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Função para atualizar a largura
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        // Adiciona o event listener
        window.addEventListener('resize', handleResize);

        // Limpa o event listener ao desmontar o componente
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Defina a propriedade width do DataTable aqui, usando windowWidth
    // Você pode precisar de uma lógica adicional para ajustar o tamanho conforme necessário
    return (
        <DataTable
        {...props} // Espalha todas as props recebidas
        style={{ width: `${windowWidth}px`, ...props.style }} // Mescla com a largura ajustada
        />
    );
};

export default MyDataTable;
