import {BrowserRouter, Route, Routes} from "react-router-dom";
import Dashboard from "./pages/dashboard/dashboard.jsx";
import Clientes from "./pages/cadastros/clientes/cliente.jsx";
import ContasAPagar from "./pages/financeiro/contasapagar/consulta/lista.jsx";
import ListaEmpresa from "./pages/listas/empresa/listaempresa.jsx";
import ListaFornecedor from "./pages/listas/fornecedor/listafornecedor.jsx";
import ListaPlanoDeContas from "./pages/listas/planodecontas/listaplanodecontas.jsx";
import ListaDePortadores from "./pages/listas/portadores/listadeportadores.jsx";
import Home from "./pages/home/home.jsx";
import Login from "./components/login/login.jsx";

function Rotas(){
    return<BrowserRouter>
        <Routes>
            <Route path="/" element={<Home/>}  />
            <Route path="/login" element={<Login/>}  />
            <Route path="/dashboard" element={<Dashboard/>}  />
            <Route path="/cadastros/clientes" element={<Clientes/>}  />
            <Route path="/cadastros/empresas" element={<ListaEmpresa/>}  />
            <Route path="/cadastros/fornecedores" element={<ListaFornecedor/>}  />
            <Route path="/cadastros/planodecontas" element={<ListaPlanoDeContas/>}  />
            <Route path="/cadastros/portadores" element={<ListaDePortadores/>}  />
            <Route path="/financeiro/contasapagar/consulta" element={<ContasAPagar/>}  />
        </Routes>
    
    </BrowserRouter>
}

export default Rotas;