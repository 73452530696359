import React, { useEffect, useState, useCallback } from "react";
import api from "../../../services/api.js";
import './cadastroplanodecontas.css';


function CadastroPlanoDeContas({ operacao, codigo, onClose }) {
    const [codred, setCodred] = useState("");
    const [contas, setContas] = useState([]);
    const [ContasSelecionada, setContasSelecionada] = useState(false);
    const [debcreSelecionado, setdebcreSelecionado] = useState('D');

    const [dadosplanodeconta, setDadosPlanoDeConta] = useState({
        codred: 0,
        ctasoma: '',
        codcta: '',
        decta: '',
        classe: '',
        debcre: 'D',
        tipo: '',
        cdcontab: '',
        contabil: '',
        codhist: '',
    }); // Novo estado para armazenar os dados

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        // Se o tipo for um checkbox, use o valor "checked" em vez do "value"
        const inputValue = type === 'checkbox' ? (checked ? 'S' : 'N') : value;
        setDadosPlanoDeConta((prevState) => ({
            ...prevState,
            [name]: inputValue
        }));
    };

    const handleDebCreChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue === "D") {
            setdebcreSelecionado('D');
            handleInputChange({
                target: {
                    name: 'debcre',
                    value: "D"
                }
            });
        } else {
            setdebcreSelecionado('C');

            handleInputChange({
                target: {
                    name: 'debcre',
                    value: 'C'
                }
            });
        }
    };

    const handleContasChange = (e) => {
        const selectedValue = e.target.value;

        if (selectedValue === "") {
            setContasSelecionada(false);
            handleInputChange({
                target: {
                    name: 'ctasoma',
                    value: ""
                }
            });
        } else {
            setContasSelecionada(true);
            const formattedValue = selectedValue.substring(0, 4);
            handleInputChange({
                target: {
                    name: 'ctasoma',
                    value: formattedValue
                }
            });
        }
    };

    const handleRadioChange = (e) => {
        const selectedValue = e.target.value;
        // Atualize o estado com o valor selecionado
        // Supondo que você está usando um padrão similar ao handleInputChange
        handleInputChange({
            target: {
                name: 'tipo',
                value: selectedValue
            }
        });
    };


    const handleBlurContaSoma = () => {
        ListarContas(localStorage.getItem("user"), null, dadosplanodeconta.debcre);
    };

    const handleCancelar = () => {
        onClose();
    };

    const handleSalvar = async () => {
        if (!ContasSelecionada) {
            alert("Por favor, selecione um plano de contas.");
            return;
        }

        if (!debcreSelecionado) {
            handleInputChange({
                target: {
                    name: 'debcre',
                    value: 'D'
                }
            });
        }

        const params = new URLSearchParams({
            user: localStorage.getItem("user"),
            gmt: '-04:00'
        });

        if (dadosplanodeconta) {
            let planodecontasParaSalvar = {
                ...dadosplanodeconta,
                decta: dadosplanodeconta.decta.trim(),

            };

            // Verifica se dadosplanodeconta.debcre está em branco e atualiza se necessário
            if (!planodecontasParaSalvar.debcre.trim()) {
                planodecontasParaSalvar.debcre = 'D';
            }


            try {
                if (codred) {
                    // Usar a requisição PUT para atualizar os dados do plano de contas
                    try {
                        api.put(`/t_placon/${codred}?${params}`, planodecontasParaSalvar)
                        .then((response) => {
                            // Tratar a resposta, se necessário
                          });
                        
                        window.alert('Registro alterado com sucesso!');
                    } catch (error) {
                        window.alert('Falha ao alterar o registro!');
                    } finally {
                        onClose();
                    }
                } else {
                    let novoplanodecontasParaSalvar = {
                        ...planodecontasParaSalvar,
                        codred: 0,
                    };

                    // Verifica se dadosplanodeconta.debcre está em branco e atualiza se necessário
                    if (!novoplanodecontasParaSalvar.debcre.trim()) {
                        novoplanodecontasParaSalvar.debcre = 'D';
                    }
                    // Usar a requisição POST para criar um novo plano de contas
                    try {
                        const response = await api.post(`/t_placon?${params}`, novoplanodecontasParaSalvar);
                        novoplanodecontasParaSalvar.codred = response.data.codred;
                        // Tratar a resposta, se necessário
                        window.alert('Registro ' + response.data.codred + ' inserido com sucesso!');
                    } catch (error) {
                        console.error('Erro ao salvar a nova conta:', error);
                        window.alert('Falha ao inserir o registro');
                    } finally {
                        onClose();
                    }
                }
            } catch (error) {
                console.error('Erro ao salvar as informações do plano de contas:', error);
            }
        }
    };


    function ListarContas(user, tipo, debcre) {

        api.get("/t_placon", {
            params: {
                user: user,
                tipo: 'S',
                status: debcre
            }
        })
            .then((resp) => {
                const modifiedData = {
                    total_registros: resp.data.count,
                    dados: resp.data.rows
                };
                setContas(modifiedData.dados);

            })
            .catch((err) => {

                setContas([]);
                alert("Erro ao carregar contas");
            });

    }


    const handleNovo = () => {

        setDadosPlanoDeConta({
            codred: '0',
            ctasoma: '',
            codcta: '',
            decta: '',
            classe: '',
            debcre: 'D',
            tipo: 'A',
            cdcontab: '',
            contabil: '',
            codhist: '',
        }); // Novo estado para armazenar os dados


    };

    // Requisição GET para buscar os dados da fornecedor pelo ID
    const getdadosplanodeconta = useCallback(async (codred) => {

        api.get("/t_placon/" + codred, {
            params: {
                user: localStorage.getItem("user")
            }
        })
            .then((resp) => {
                const Dados = resp.data;
                setDadosPlanoDeConta(Dados);

                if (Dados.codred)
                    setContasSelecionada(true);
            })
            .catch((err) => {
                setDadosPlanoDeConta();
                alert('Erro ao buscar dados da conta: ' + err.message);
            });
    }, []);

    useEffect(() => {
        ListarContas(localStorage.getItem("user"), null, 'D');
    }, []);

    useEffect(() => {

        setCodred(codigo);
        if (codigo) {
            getdadosplanodeconta(codigo);
        }

        if (operacao === 'Incluir') {
            handleNovo();
        }
    }, [operacao, codigo, getdadosplanodeconta]);

    return <>

        <div className="container-fluid h-100 pt-4 sidebar p-4">
            <h4 className="d-inline">
                {operacao === "Editar" ? dadosplanodeconta.decta : "Nova Conta"}
            </h4>
            <div className="row tela-cad">
                <div className='row'>
                    <div className="col-xl-4 col-md-6 col-sm-12 mt-4">
                        <small className="text-secondary">Codred</small>
                        <input type="text"
                            style={{ textAlign: 'right' }}
                            name="codred"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosplanodeconta.codred}
                            disabled={true} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12 mt-2">
                        <small className="text-secondary">Descrição</small>
                        <input
                            type="text"
                            name="decta"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosplanodeconta.decta}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-4 col-md-6 col-sm-12 mt-2">
                        <small className="text-secondary">Débito/Crédito</small>
                        <div className="form-control mb-0">
                            <select
                                name="debcre"
                                onChange={handleDebCreChange}
                                value={dadosplanodeconta.debcre}
                                onBlur={handleBlurContaSoma}
                            >
                                <option value="D">Débito</option>
                                <option value="C">Crédito</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12 mt-2">
                        <small className="text-secondary">Conta Soma</small>
                        <select
                            name="ctasoma"
                            className="form-control"
                            onChange={handleContasChange}
                            value={dadosplanodeconta.ctasoma}
                            required
                        >
                            <option value="">Selecione a conta</option>
                            {contas.map((item) => (
                                <option key={item.codred} value={item.codred}>
                                    {`${item.codred.toString().padStart(4, '0')} - ${item.decta}`}
                                </option>

                            ))}
                        </select>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-xl-4 col-md-6 col-sm-12 mt-2">
                        <small className="text-secondary">Classe</small>
                        <input type="text"
                            style={{ textAlign: 'right' }}
                            name="codcta"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosplanodeconta.codcta}
                            disabled={true} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-4 col-md-6 col-sm-12 mt-2">
                        <small className="text-secondary">Contábil</small>
                        <input
                            type="text"
                            style={{ textAlign: 'right' }}
                            name="contabil"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosplanodeconta.contabil}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12 mt-2">
                        <small className="text-secondary">Tipo</small>
                        <div>
                            <input
                                type="radio"
                                name="tipo"
                                value="A"
                                onChange={handleRadioChange}
                                checked={dadosplanodeconta.tipo === 'A'}
                            />
                            <label style={{ marginLeft: '5px' }} htmlFor="A" className="me-4">Analítica</label>

                            <input
                                type="radio"
                                name="tipo"
                                value="S"
                                onChange={handleRadioChange}
                                checked={dadosplanodeconta.tipo === 'S'}
                            />
                            <label style={{ marginLeft: '5px' }} htmlFor="S">Sintética</label>
                        </div>
                    </div>
                </div>


            </div>
            <br />
            <div className="row align-items-end footer">
                <div className="text-center">
                    <button className="btn btn-outline-primary col-xl-4 col-md-4 col-sm-12 me-3" onClick={handleCancelar}>
                        Cancelar
                    </button>
                    <button className="btn btn-primary col-xl-4 col-md-4 col-sm-12" onClick={handleSalvar}>
                        Salvar Dados
                    </button>
                </div>
            </div>
        </div>
    </>
}

export default CadastroPlanoDeContas;