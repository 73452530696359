import Menu from "../../../components/menu/menu.jsx";
import Busca from "../../../components/busca/busca.jsx";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useEffect, useState } from "react";
import api from "../../../services/api.js";
import CadastroModal from "../../../components/modalcadastros/modalcadastros.jsx";
import CadastroPlanoDeContas from "../../cadastros/planodecontas/cadastroplanodecontas.jsx";

function ListaPlanoDeContas() {

    // eslint-disable-next-line
    const [status, setStatus] = useState("");
    const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
    const [dados, setDados] = useState([]);
    const [total_registros, setTotalRegistros] = useState(0);
    const [buscaTexto, setBuscaTexto] = useState("");

    const [codigo, setCodigo] = useState("");
    const [operacao, setOperacao] = useState("");

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const columns = [
        {
            name: 'CODRED',
            selector: row => row.codred,
            sortable: true,
            width: "100px",
        },
        {
            name: 'Descrição',
            selector: row => row.decta,
            sortable: true,
            compact: true,
            width: "300px",
        },
        {
            name: 'CODCTA',
            selector: row => row.codcta,
            sortable: true,
            width: "110px",
        },
        {
            name: 'D/C',
            selector: row => row.debcre,
            sortable: true,
            width: "130px",
        },
        {
            name: 'A/S',
            selector: row => row.tipo,
            sortable: true,
            width: "80px",
            compact: true,
        },
        {
            name: 'Contábil',
            selector: row => row.contabil,
            sortable: true,
            width: "120px",
            compact: true,
        },
        {
            cell: (row) => <>
                {/* <button onClick={() => WhatsApp(row.dddfor + row.fonefor)} className="btn btn-success"><i className="bi bi-whatsapp"></i></button>  */}
                <button onClick={() => Editar(row.codred)} className="btn btn-primary ms-1"><i className="bi bi-pencil-square"></i></button>
                <button onClick={() => Excluir(row.codred)} className="btn btn-danger ms-1"><i className="bi bi-trash3-fill"></i></button>
            </>,
            width: "200px",
            right: true
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    function Excluir(id) {
        confirmAlert({
            title: 'Exclusão',
            message: 'Confirma a exclusão do registro?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        api.delete("/t_placon/" + id, {
                            params: {
                                user: localStorage.getItem("user"),
                            }
                        })
                            .then((resp) => {
                                Listar(localStorage.getItem("user"),
                                    status === "" ? "Todos" : status,
                                    buscaTexto, 1, qtd_reg_pagina);
                                alert('Registro: ' + id + ' Excluído com sucesso!')
                            })
                            .catch((err) => {
                                alert("Erro ao excluir Registro.");
                            });



                    }


                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    function Editar(id) {
        
        setOperacao("Editar");
        setCodigo(id);
        openModal(); // Abre o modal
    }

    function Incluir() {
        setOperacao("Inserir");
        setCodigo(""); // Limpa o código, pois é uma inclusão
        openModal(); // Abre o modal
    }

    function Listar(user, status, decta, page, pageSize) {
        // get JSON do Servidor
        api.get("/t_placon", {
            params: {
                user,
                status,
                decta,
                page,
                pageSize
            }
        })
            .then((resp) => {
                const modifiedData = {
                    total_registros: resp.data.count,
                    dados: resp.data.rows
                };
                setDados(modifiedData.dados);
                setTotalRegistros(modifiedData.total_registros);
            })
            .catch((err) => {
                setTotalRegistros(0)
                setDados([]);
                alert("Erro ao carregar Registros...");
            });
    }

    function ChangePerRows(newQtd, page) {
        Listar(localStorage.getItem("user"),
            status === "" ? "Todos" : status,
            buscaTexto, page, newQtd);
        setQtdRegPagina(newQtd);
    }

    function ChangePage(page) {
        Listar(localStorage.getItem("user"),
            status === "" ? "Todos" : status,
            buscaTexto, page, qtd_reg_pagina);
    }

    function handleBusca(texto) {
        setBuscaTexto(texto);
        Listar(
            localStorage.getItem("user"),
            status === "" ? "Todos" : status,
            texto,
            1,
            qtd_reg_pagina
        );
    }

    function ChangeStatus(e) {
        setStatus(e.target.value);
        Listar(localStorage.getItem("user"), e.target.value, buscaTexto, 1, qtd_reg_pagina);

    }

    useEffect(() => {
        Listar(localStorage.getItem("user"),
            status === "" ? "Todos" : status,
            buscaTexto, 1, qtd_reg_pagina);
    }, [qtd_reg_pagina, status, buscaTexto]);


    return <>

        <div className="container-fluid">
            <div className="row flex-nowrap">
                <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
                    <Menu page="planodecontas" />
                </div>

                <div className="col py-3 me-3">
                    <div className="mb-5">
                        <Busca texto="Digite a descrição do plano de contas" onSearch={handleBusca} />
                    </div>

                    <div className="bg-white p-4 rounded-4 border">
                        <div className="d-flex justify-content-between mb-3" >
                            <div className="d-flex align-items-center" >
                                
                            <h2 className="mb-0" style={{ whiteSpace: 'nowrap' }}>Plano de Contas</h2>
                                    <div className="form-control ms-4">
                                        <select name="status" id="status" onChange={ChangeStatus}  >
                                            <option value="Todos">Todas</option>
                                            <option value="C">Crédito</option>
                                            <option value="D">Débito</option>
                                        </select>
                                    </div>
                                
                            </div>


                            <button onClick={Incluir} className="btn btn-primary ms-4"><i className="bi bi-plus-lg me-2"></i>Novo</button>

                        </div>

                        <DataTable columns={columns}
                            data={dados}
                            pagination={true}
                            paginationComponentOptions={paginationOptions}
                            noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}
                            paginationServer={true}
                            paginationTotalRows={total_registros}
                            onChangeRowsPerPage={ChangePerRows}
                            onChangePage={ChangePage}
                        />
                    </div>

                    <div className="row">

                    </div>

                </div>
            </div>
        </div>

        {/* Renderiza o modal de cadastro aqui, mas apenas quando modalIsOpen for true */}
        {modalIsOpen && (
            <CadastroModal isOpen={modalIsOpen} onClose={closeModal} title='' maxWidth="40%">
                <CadastroPlanoDeContas operacao={operacao} codigo={codigo} onClose={closeModal} />
            </CadastroModal>
        )}
    </>
}

export default ListaPlanoDeContas;