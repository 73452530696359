import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';
import "./style/global.css";
import Rotas from './rotas.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

//Defina o elemento raiz do seu aplicativo
Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<>
    <Rotas />
</>
);