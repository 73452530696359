import React, { useEffect, useState, useCallback } from "react";
import { Tab, Tabs } from 'react-bootstrap';
import api from "../../../services/api.js";
import s3 from "../../../services/awsconfig.js";
import './cadastroempresa.css';
import EmpresaConfiguracoes from "./empresaconfiguracoes.jsx";
import EmpresaOpcoes from "./empresaopcoes.jsx";


function CadastroEmpresa({ operacao, codigo, onClose }) {
    const [cdemp, setCdemp] = useState("");
    const [uploadMessage, setUploadMessage] = useState('');
    const [isUploadSuccess, setIsUploadSuccess] = useState(false);
    const [uploadMessage2, setUploadMessage2] = useState('');
    const [isUploadSuccess2, setIsUploadSuccess2] = useState(false);
    const [ufs, setUFs] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [bairros, setBairros] = useState([]);

    const [dadosEmpresa, setDadosEmpresa] = useState({

        // Informações Principais
        cdemp: '',
        deemp: '',
        fantemp: '',
        endemp: '',
        complemp: '',
        numemp: '',
        Baiemp: '',
        Cidemp: '',
        Estemp: '',
        CEPemp: '',
        dddemp: '',
        fonemp: '',
        cnpjemp: '',
        ieEmp: '',
        emailemp: '',
        wwwemp: '',


        //  Configurações
        ultNFemp: '',
        JurDia: '',
        MulApv: '',
        ativaemp: '',
        
        inscmun: '',
        MsnFixaNF: '',
        
        atacadista: '',
        AliqInterna: '',

        RegEspTr: '',
        RegFisc: '',
        CRT: '',

        serienfe: '',
        contabil: '',
        custoper: '',
        ImpstVen: '',
        impincetiv: '',
        industria: '',
        matriz: '',
        persubstrib: '',
        diasprotesto: '',
        certificado: '',
        
        CaminhoNFE: '',
        CaminhoNFC: '',
        ULTNFC: '',
        SerieNFc: '',
        ModeloNFc: '',
        NrToken: '',
        ConsultarFone: '',
        ImpRecCx: '',
        ImpMPnoPV: '',
        IDCSC: '',
        CaminhoNFS: '',
        UltNFSv: '',
        ModNFSv: '',
        SerNFSv: '',
        AliqISS: '',
        PetShop: '',

        AutVendAbxQteEmb: '',
        ControlaSaidaDaComanda: '',
        LerBarrasMod2: '',
        TIPO: '',
        BloqItemSemSaldoPDV: '',
        PedirTXDeEntrega: '',
        ImprimirTXDeEntNoNFC: '',
        AGENDAPORHORA: '',
        CaminhoDoCertificado: '',
        SenhaDoCertificado: '',
        CNPJPrefeitura: '',
        UserWeb: '',
        SenhaWeb: '',
        FraseSecWeb: '',
        ChaveAcessoWeb: '',
        ChaveAutorizWeb: '',
        logonfe: '',
        LogoNFS: '',
        LogoPrefeitura: '',
        Prefeitura: '',
        PDVModoFicha: '',
        PathDoProvedor: '',
        
        LogoExpandidaSN: '',
        ComissaoPorMarkup: '',
    }); // Novo estado para armazenar os dados

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        // Se o tipo for um checkbox, use o valor "checked" em vez do "value"
        const inputValue = type === 'checkbox' ? (checked ? 'S' : 'N') : value;

        setDadosEmpresa((prevState) => ({
            ...prevState,
            [name]: inputValue
        }));
    };

   
    const formatCNPJ = (cnpj) => {
        if (cnpj.length <= 11) {
            return cnpj.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else {
            return cnpj.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }

    };

    const handleCnpjChange = (e) => {
        const cnpjFormatted = e.target.value.replace(/[./-]/g, '');
        handleInputChange({
            target: {
                name: 'cnpjemp',
                value: cnpjFormatted
            }
        });
    };

    const handleBlurCep = () => {
        const formattedCep = formatCEP(dadosEmpresa.CEPemp);

        // Verifique se o CEP é válido antes de fazer a busca
        if (formattedCep.length === 9) {
            buscarEnderecoPorCep(dadosEmpresa.CEPemp);
        }
    };

    async function buscarEnderecoPorCep(cep) {
        try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            const data = await response.json();

            if (data.erro) {
                console.log('CEP não encontrado.');
                return;
            }

            const endereco = data.logradouro;
            const complemento = data.complemento;
            const uf = data.uf;
            const cidade = data.localidade;
            const bairro = data.bairro;

            // Atualizar os campos do formulário
            if (endereco) handleInputChange({ target: { name: 'endemp', value: endereco } });
            if (complemento) handleInputChange({ target: { name: 'complemp', value: complemento } });
            if (bairro) handleInputChange({ target: { name: 'Baiemp', value: bairro } });
            if (cidade) handleInputChange({ target: { name: 'Cidemp', value: cidade } });
            if (uf) handleInputChange({ target: { name: 'Estemp', value: uf } });

        } catch (error) {
            console.error('Erro ao buscar CEP:', error);
        }
    }

    const formatCEP = (cep) => {
        return cep.replace(/\D/g, '').replace(/(\d{5})(\d{3})/, '$1-$2');
    };

    const handleCepChange = (e) => {
        const formattedCep = e.target.value.replace(/[.\s-]/g, '');
        handleInputChange({
            target: {
                name: 'CEPemp',
                value: formattedCep
            }
        });

    };

    const formatTelefone = (telefone) => {
        return telefone.replace(/\D/g, '').replace(/(\d{1})(\d{4})(\d{4})/, '$1 $2-$3');
    };

    const handleTelefoneChange = (e) => {
        const fonecliFormated = e.target.value.replace(/[\s-]/g, '')
        handleInputChange({
            target: {
                name: 'fonemp',
                value: fonecliFormated
            }
        });
    };

    const handleUploadButtonClick = async () => {
        if (selectedFile) {
            try {
                const currentTimestamp = Date.now();
                const imageName = `images/${selectedFile.name}-${currentTimestamp}`;
                //const fileName = `images/cliente-${dadosEmpresa.decli}-${Date.now()}-${selectedFile.name}`;

                // Redimensionar a imagem antes de enviar
                const resizedImage = await resizeImage(selectedFile, 200, 200); // Escolha as dimensões desejadas

                const params = {
                    Bucket: 'sistemaraposaweb', // Nome do seu bucket S3
                    Key: imageName,
                    Body: resizedImage,
                    ACL: 'public-read', // Configura a permissão de leitura pública
                };

                const result = await s3.upload(params).promise();
                const imageUrl = result.Location; // Aqui está a URL do arquivo no S3

                // Atualize o estado do dadosEmpresa com a URL da imagem
                handleDataChange({ ...dadosEmpresa, logonfe: imageUrl });

                setIsUploadSuccess(true);
                setUploadMessage('Imagem enviada com sucesso para o S3.');
            } catch (error) {
                console.error('Erro ao enviar imagem para o S3:', error);
                setIsUploadSuccess(false);
                setUploadMessage('Erro ao enviar imagem para o S3.');
            }
        } else {
            console.error('Nenhum arquivo selecionado.');
        }
    };

    const handleUploadButton2Click = async () => {
        if (selectedFile) {
            try {
                const currentTimestamp = Date.now();
                const imageName = `images/${selectedFile2.name}-${currentTimestamp}`;
                //const fileName = `images/cliente-${dadosEmpresa.decli}-${Date.now()}-${selectedFile.name}`;

                // Redimensionar a imagem antes de enviar
                const resizedImage = await resizeImage(selectedFile2, 200, 200); // Escolha as dimensões desejadas

                const params = {
                    Bucket: 'sistemaraposaweb', // Nome do seu bucket S3
                    Key: imageName,
                    Body: resizedImage,
                    ACL: 'public-read', // Configura a permissão de leitura pública
                };

                const result = await s3.upload(params).promise();
                const imageUrl = result.Location; // Aqui está a URL do arquivo no S3
                // Atualize o estado do dadosEmpresa com a URL da imagem
                handleDataChange({ ...dadosEmpresa, LogoPrefeitura: imageUrl });


                setIsUploadSuccess2(true);
                setUploadMessage2('Imagem enviada com sucesso para o S3.');
            } catch (error) {
                console.error('Erro ao enviar imagem para o S3:', error);
                setIsUploadSuccess2(false);
                setUploadMessage2('Erro ao enviar imagem para o S3.');
            }
        } else {
            console.error('Nenhum arquivo selecionado.');
        }
    };

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value.trim();
        handleInputChange({
            target: {
                name: 'Estemp',
                value: selectedValue
            }
        });
    };

    const handleSelectCidChange = (e) => {
        const selectedValue = e.target.value.trim();
        handleInputChange({
            target: {
                name: 'Cidemp',
                value: selectedValue
            }
        });
    };

    const handleSelectBaiChange = (e) => {
        const selectedValue = e.target.value.trim();
        handleInputChange({
            target: {
                name: 'Baiemp',
                value: selectedValue
            }
        });
    };

    const handleCancelar = () => {
        onClose();
    };

    const handleSalvar = () => {

        const params = new URLSearchParams({
            user: localStorage.getItem("user"),
            gmt: '-04:00'
        });

        if (dadosEmpresa) {
            
            if (dadosEmpresa.PathDoProvedor !== null) {
                dadosEmpresa.PathDoProvedor = dadosEmpresa.PathDoProvedor.trim();
            } else {
                delete dadosEmpresa.PathDoProvedor;
            }

            if (dadosEmpresa.FraseSecWeb !== null) {
                dadosEmpresa.FraseSecWeb = dadosEmpresa.FraseSecWeb.trim();
            } else {
                delete dadosEmpresa.FraseSecWeb;
            }

            if (dadosEmpresa.CaminhoDoCertificado !== null) {
                dadosEmpresa.CaminhoDoCertificado = dadosEmpresa.CaminhoDoCertificado.trim();
            } else {
                delete dadosEmpresa.CaminhoDoCertificado;
            }

            if (dadosEmpresa.Prefeitura !== null) {
                dadosEmpresa.Prefeitura = dadosEmpresa.Prefeitura.trim();
            } else {
                delete dadosEmpresa.Prefeitura;
            }

            if (dadosEmpresa.LogoPrefeitura !== null) {
                dadosEmpresa.LogoPrefeitura = dadosEmpresa.LogoPrefeitura.trim();
            } else {
                delete dadosEmpresa.LogoPrefeitura;
            }

            let empresaParaSalvar = {
                ...dadosEmpresa,
                deemp: dadosEmpresa.deemp.trim(),
                fantemp: dadosEmpresa.fantemp.trim(),
                Baiemp: dadosEmpresa.Baiemp.trim(),
                complemp: dadosEmpresa.complemp.trim(),
                endemp: dadosEmpresa.endemp.trim(),
                CEPemp: dadosEmpresa.CEPemp.trim(),
                dddemp: dadosEmpresa.dddemp.trim(),
                fonemp: dadosEmpresa.fonemp.trim(),
                faxemp: dadosEmpresa.faxemp.trim(),
                cnpjemp: dadosEmpresa.cnpjemp.trim(),
                ieEmp: dadosEmpresa.ieEmp.trim(),
                wwwemp: dadosEmpresa.wwwemp.trim(),
                
                emailemp: dadosEmpresa.emailemp.trim(),
                logonfe: dadosEmpresa.logonfe,
                

                PetShop: dadosEmpresa.PetShop === ' ' ? 'N' : dadosEmpresa.PetShop,
                ImpRecCx: dadosEmpresa.ImpRecCx === ' ' ? 'N' : dadosEmpresa.ImpRecCx,
                impincetiv: dadosEmpresa.impincetiv === ' ' ? 'N' : dadosEmpresa.impincetiv,
                ImpMPnoPV: dadosEmpresa.ImpMPnoPV === ' ' ? 'N' : dadosEmpresa.ImpMPnoPV,
                PDVModoFicha: dadosEmpresa.PDVModoFicha === ' ' ? 'N' : dadosEmpresa.PDVModoFicha,
                ComissaoPorMarkup: dadosEmpresa.ComissaoPorMarkup === ' ' ? 'N' : dadosEmpresa.ComissaoPorMarkup,
                AutVendAbxQteEmb: dadosEmpresa.AutVendAbxQteEmb === ' ' ? 'N' : dadosEmpresa.AutVendAbxQteEmb,
                ControlaSaidaDaComanda: dadosEmpresa.ControlaSaidaDaComanda === ' ' ? 'N' : dadosEmpresa.ControlaSaidaDaComanda,
                PedirTXDeEntrega: dadosEmpresa.PedirTXDeEntrega === ' ' ? 'N' : dadosEmpresa.PedirTXDeEntrega,
                ImprimirTXDeEntNoNFC: dadosEmpresa.ImprimirTXDeEntNoNFC === ' ' ? 'N' : dadosEmpresa.ImprimirTXDeEntNoNFC,
                AGENDAPORHORA: dadosEmpresa.AGENDAPORHORA === ' ' ? 'N' : dadosEmpresa.AGENDAPORHORA,
                BloqItemSemSaldoPDV: dadosEmpresa.BloqItemSemSaldoPDV === ' ' ? 'N' : dadosEmpresa.BloqItemSemSaldoPDV,
                LerBarrasMod2: dadosEmpresa.LerBarrasMod2 === ' ' ? 'N' : dadosEmpresa.LerBarrasMod2,

            };

            // Fazer a chamada REST usando os dados
            try {
                if (cdemp) {
                    // Usar a requisição PUT para atualizar os dados do cliente
                    api.put(`/t_emp/${cdemp}?${params}`, empresaParaSalvar)
                        .then((response) => {
                            // Tratar a resposta, se necessário
                            window.alert('Registro alterado com sucesso!');
                        })
                        .catch((error) => {
                            console.error('Erro ao salvar dados da empresa:', error);
                            window.alert('Falha ao alterar o registro!');
                        })
                        .finally(() => {
                            onClose();
                        });
                } else {
                    delete empresaParaSalvar.cdcli;
                    // Usar a requisição POST para criar um novo cliente
                    api.post(`/t_emp?${params}`, empresaParaSalvar)
                        .then((response) => {
                            // Tratar a resposta, se necessário
                            empresaParaSalvar.cdemp = response.data.cdemp;
                            alert('Registro Inserido com sucesso!');
                        })
                        .catch((error) => {
                            console.error('Erro ao salvar a nova do empresa:', error);
                            alert('Falha ao Inserir o registro');
                        })
                        .finally(() => {
                            onClose();
                        });
                }
            } catch (error) {
                console.error('Erro ao salvar todas as informações da empresa');
            }
        }
    };

    function ListarBairros(user, cidade) {

        api.get("/t_bai", {
            params: {
                user,
                cidade
            }
        })
            .then((resp) => {
                const modifiedData = {
                    total_registros: resp.data.count,
                    dados: resp.data.rows
                };
                setBairros(modifiedData.dados);

            })
            .catch((err) => {

                setBairros([]);
                alert("Erro ao carregar bairros");
            });

    }

    function ListarUF() {

        api.get("/t_uf", {
            params: {
                user: localStorage.getItem("user")
            }
        })
            .then((resp) => {
                const modifiedData = {
                    total_registros: resp.data.count,
                    dados: resp.data.rows
                };
                setUFs(modifiedData.dados);
            })
            .catch((err) => {
                setUFs([]);
                alert("Erro ao carregar UF");
            });

    }

    function ListarCidades(user, uf) {

        api.get("/t_cid", {
            params: {
                user,
                uf
            }
        })
            .then((resp) => {
                const modifiedData = {
                    total_registros: resp.data.count,
                    dados: resp.data.rows
                };
                setCidades(modifiedData.dados);

            })
            .catch((err) => {
                setCidades([]);
                alert("Erro ao carregar cidades");
            });
    }

    function formatUF(uf) {
        return uf.toUpperCase();
    }

    // Função para redimensionar a imagem usando o canvas HTML5
    const resizeImage = (file, maxWidth, maxHeight) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    resolve(blob);
                }, file.type);
            };

            img.onerror = reject;
        });
    };

    useEffect(() => {
        ListarUF();
        ListarCidades(localStorage.getItem("user"), localStorage.getItem("estemp"));
        ListarBairros(localStorage.getItem("user"), localStorage.getItem("cidemp"));
    }, []);

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFile2, setSelectedFile2] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const updateddadosEmpresa = { ...dadosEmpresa, logonfe: URL.createObjectURL(file) };
            setDadosEmpresa((dadosEmpresa) => ({
                ...dadosEmpresa,
                ...updateddadosEmpresa,
            }));
        }
    };

    const handleFile2Change = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile2(file);
            const updateddadosEmpresa = { ...dadosEmpresa, LogoPrefeitura: URL.createObjectURL(file) };
            setDadosEmpresa((dadosEmpresa) => ({
                ...dadosEmpresa,
                ...updateddadosEmpresa,
            }));
        }
    };

    const handleNovo = () => {

        setDadosEmpresa({
            // Informações Principais
            cdemp: '',
            deemp: '',
            apelido: '',
            endemp: '',
            Baiemp: '',
            Cidemp: '',
            Estemp: '',
            CEPemp: '',
            dddemp: '',
            fonemp: '',
            cnpjemp: '',
            ieEmp: '',
            emailemp: '',
            baicli: '',
            wwwemp: '',


            //  Contatos
            ultNFemp: '',
            JurDia: '',
            MulApv: '',
            ativaemp: '',
            complemp: '',
            contemp: '',
            inscmun: '',
            MsnFixaNF: '',
            numemp: '',
            locallogo: '',
            atacadista: '',
            AliqInterna: '',

            serienfe: '',
            contabil: '',
            custoper: '',
            ImpstVen: '',
            impincetiv: '',
            industria: '',
            matriz: '',
            persubstrib: '',
            diasprotesto: '',
            certificado: '',
            fantemp: '',
            CaminhoNFE: '',
            CaminhoNFC: '',
            ULTNFC: '',
            SerieNFc: '',
            ModeloNFc: '',
            NrToken: '',
            ConsultarFone: '',
            ImpRecCx: '',
            ImpMPnoPV: '',
            IDCSC: '',
            CaminhoNFS: '',
            UltNFSv: '',
            ModNFSv: '',
            SerNFSv: '',
            AliqISS: '',
            PetShop: '',
            RegEspTr: '',
            AutVendAbxQteEmb: '',
            ControlaSaidaDaComanda: '',
            LerBarrasMod2: '',
            TIPO: '',
            BloqItemSemSaldoPDV: '',
            PedirTXDeEntrega: '',
            ImprimirTXDeEntNoNFC: '',
            AGENDAPORHORA: '',
            CaminhoDoCertificado: '',
            SenhaDoCertificado: '',
            CNPJPrefeitura: '',
            UserWeb: '',
            SenhaWeb: '',
            FraseSecWeb: '',
            ChaveAcessoWeb: '',
            ChaveAutorizWeb: '',
            LogoNFS: '',
            LogoPrefeitura: '',
            Prefeitura: '',
            PDVModoFicha: '',
            PathDoProvedor: '',
            ID_CIDADE: '',
            LogoExpandidaSN: '',
            ComissaoPorMarkup: '',


        }); // Novo estado para armazenar os dados


    };

    const handleDataChange = (updatedData) => {
        setDadosEmpresa((dadosEmpresa) => ({
            ...dadosEmpresa,
            ...updatedData,
        }));
    };


    // Requisição GET para buscar os dados da empresa pelo ID
    const getDadosEmpresa = useCallback(async (cdemp) => {

        api.get("/t_emp/" + cdemp, {
            params: {
                user: localStorage.getItem("user")
            }
        })
            .then((resp) => {
                const Dados = resp.data;
                setDadosEmpresa(Dados);
            })
            .catch((err) => {
                setDadosEmpresa();
                alert('Erro ao buscar dados da empresa: ' + err.message);
            });
    }, []);


    useEffect(() => {

        setCdemp(codigo);
        if (codigo) {
            getDadosEmpresa(codigo);
        }

        if (operacao === 'Incluir') {
            handleNovo();
        }
    }, [operacao, codigo, getDadosEmpresa]);

    return <>

        <div className="container-fluid h-100 pt-4 sidebar p-4">
            <h4 className="d-inline">
                {operacao === "Editar" ? dadosEmpresa.fantemp : "Novo Empresa"}
            </h4>
            <div className="row tela-cad">
                <Tabs defaultActiveKey="informacoesPessoais" id="clienteTabs"
                    className="my-4 md-2" >
                    {/* Aba 1 - Informações Principais */}
                    <Tab eventKey="informacoesPessoais" title="Informações Principais">
                        <div className='row'>
                            <div className="col-xl-3 col-md-5 col-sm-5 mt-1">
                                <small className="text-secondary">Código</small>
                                <input type="text"
                                    style={{ textAlign: 'right' }}
                                    name="cdemp"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    value={dadosEmpresa.cdemp}
                                    disabled={true} />
                            </div>

                            <div className='col-xl-6 col-md-2 col-sm-2 mt-1'>

                            </div>

                            <div className="col-xl-3 col-md-5 col-sm-5 mt-1">
                                <small className="text-secondary">Tipo</small>
                                <div className="form-control mb-2">
                                    <select
                                        name="TIPO"
                                        // onChange={handleTipoChange}
                                        // value={dadosEmpresa.Tipo === 'F' ? 'fisica' : 'juridica'}
                                        onChange={handleInputChange}
                                        value={dadosEmpresa.TIPO}
                                    >
                                        <option value="FISICA">Física</option>
                                        <option value="JURIDICA">Jurídica</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-5 col-md-12 col-sm-12 mt-1">
                                <small className="text-secondary">Razão Social</small>
                                <input
                                    type="text"
                                    name="deemp"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    value={dadosEmpresa.deemp}
                                />
                            </div>


                            <div className="col-xl-7 col-md-12 col-sm-12 mt-1">
                                <small className="text-secondary">Nome Fantasia</small>
                                <input type="text"
                                    name="fantemp"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    value={dadosEmpresa.fantemp}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className='col-xl-4 col-md-6 col-sm-12 mt-1'>
                                <small className="text-secondary">CNPJ</small>
                                <input
                                    type="text"
                                    name="cnpjemp"
                                    className="form-control"
                                    onChange={handleCnpjChange}
                                    value={formatCNPJ(dadosEmpresa.cnpjemp)}
                                />
                            </div>

                            <div className='col-xl-4 col-md-6 col-sm-12 mt-1'>
                                <small className="text-secondary">I.E</small>
                                <input type="text"
                                    name="ieEmp"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    value={dadosEmpresa.ieEmp}
                                />
                            </div>

                            <div className='col-xl-4 col-md-6 col-sm-12 mt-1'>
                                <small className="text-secondary">I.M</small>
                                <input type="text"
                                    name="inscmun"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    value={dadosEmpresa.inscmun}
                                />
                            </div>


                            <div className='col-xl-2 col-md-2 col-sm-4 mt-1 mt-1'>
                                <small className="text-secondary">DDD</small>
                                <input
                                    type="text"
                                    style={{ textAlign: 'right' }}
                                    name="dddemp"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    value={dadosEmpresa.dddemp}
                                />
                            </div>

                            <div className='col-xl-3 col-md-4 col-sm-8  mt-1'>
                                <small className="text-secondary">Fone</small>
                                <input type="text"
                                    style={{ textAlign: 'right' }}
                                    name="fonemp"
                                    className="form-control"
                                    onChange={handleTelefoneChange}
                                    value={formatTelefone(dadosEmpresa.fonemp)}
                                />
                            </div>

                            <div className='col-xl-7 col-md-12 col-sm-12 mt-1'>
                                <small className="text-secondary">Email</small>
                                <input type="text"
                                    name="emailemp"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    value={dadosEmpresa.emailemp}
                                />
                            </div>


                            <div className='col-xl-9 col-md-8 col-sm-12 mt-1'>
                                <small className="text-secondary">Site</small>
                                <input type="text"
                                    name="wwwemp"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    value={dadosEmpresa.wwwemp}
                                />
                            </div>

                            <div className="col-xl-3 col-md-4 col-sm-12 mt-1">
                                <small className="text-secondary">CEP</small>
                                <input
                                    className="form-control"
                                    type="text"
                                    style={{ textAlign: 'right' }}
                                    name="CEPemp"
                                    value={formatCEP(dadosEmpresa.CEPemp)}
                                    onChange={handleCepChange}
                                    onBlur={handleBlurCep}
                                />
                            </div>


                        </div>

                        <div className="row">

                            <div className='col-xl-4 col-md-8 col-sm-9 mt-1'>
                                <small className="text-secondary">Endereço</small>
                                <input
                                    type="text"
                                    name="endemp"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    value={dadosEmpresa.endemp}
                                />

                            </div>


                            <div className='col-xl-2 col-md-4 col-sm-3 mt-1'>
                                <small className="text-secondary">Número</small>
                                <input type="text"
                                    style={{ textAlign: 'right' }}
                                    name="numemp"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    value={dadosEmpresa.numemp}
                                />
                            </div>

                            <div className='col-xl-6 col-md-12 col-sm-12 mt-1'>
                                <small className="text-secondary">Complemento</small>
                                <input
                                    type="text"
                                    name="complemp"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    value={dadosEmpresa.complemp}
                                />
                            </div>

                        </div>

                        <div className="row">

                            <div className='col-xl-6 col-md-6 col-sm-12 mt-1'>
                                <small className="text-secondary">Bairro</small>
                                <select
                                    name="baicli"
                                    className="form-control"
                                    value={dadosEmpresa.Baiemp}
                                    onChange={handleSelectBaiChange} // Atualize o valor do bairro quando selecionado manualmente
                                >
                                    <option value={dadosEmpresa.Baiemp}>{dadosEmpresa.Baiemp}</option>
                                    {bairros.map((item) => (
                                        item.debai.trim() !== dadosEmpresa.Baiemp.trim() && (
                                            <option key={item.debai} value={item.debai}>
                                                {item.debai}
                                            </option>
                                        )
                                    ))}
                                </select>
                            </div>

                            <div className='col-xl-4 col-md-4 col-sm-8 mt-1'>
                                <small className="text-secondary">Cidade</small>
                                <select
                                    name="Cidemp"
                                    className="form-control"
                                    value={dadosEmpresa.Cidemp}
                                    onChange={handleSelectCidChange}
                                >
                                    <option value={dadosEmpresa.Cidemp}>{dadosEmpresa.Cidemp}</option>
                                    {cidades.map((item) => (
                                        item.decid.trim() !== dadosEmpresa.Cidemp.trim() && (
                                            <option key={item.decid} value={item.decid}>
                                                {item.decid}
                                            </option>
                                        )
                                    ))}
                                </select>
                            </div>


                            <div className='col-xl-2 col-md-2 col-sm-4 mt-1'>
                                <small className="text-secondary">UF</small>
                                <select
                                    name="Estemp"
                                    className="form-control"
                                    onChange={handleSelectChange}
                                    value={dadosEmpresa.Estemp}
                                >
                                    <option value={formatUF(dadosEmpresa.Estemp)}>{dadosEmpresa.Estemp}</option>
                                    {ufs.map((item) => (
                                        item.UF.trim() !== dadosEmpresa.Estemp.trim() && (
                                            <option key={item.UF}
                                                value={formatUF(item.UF)}>
                                                {formatUF(item.UF)}
                                            </option>)
                                    ))}
                                </select>

                            </div>
                        </div>
                        <div className="row">


                            <div className="col-xl-3 col-md-4 col-sm-6 mt-1">

                            </div>

                            <div className="col-xl-6 col-md-4 col-sm-1 mt-1">

                            </div>

                            <div className="col-xl-3 col-md-4 col-sm-5 mt-1">
                                <small className="text-secondary">Ativo</small>
                                <div className="form-control mb-2">
                                    <select
                                        name="ativaemp"
                                        onChange={handleInputChange}
                                        value={dadosEmpresa.ativaemp}
                                    >
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </select>
                                </div>
                            </div>

                        </div>


                    </Tab>
                    {/* Aba 2 - Contatos */}
                    <Tab eventKey="Configurações" title="Configurações">
                        < EmpresaConfiguracoes
                            dadosEmpresa={dadosEmpresa}
                            onEmpresaChange={handleDataChange}
                        />
                    </Tab>
                    {/* Aba 3 - Contatos */}
                    <Tab eventKey="opcoes" title="Opções">
                        < EmpresaOpcoes
                            dadosEmpresa={dadosEmpresa}
                            onEmpresaChange={handleDataChange}
                        />
                    </Tab>

                    {/* Aba 4 - logomarca */}
                    <Tab eventKey="Logomarca" title="Logomarca">
                        <div className="row mt-4">
                            <div className="col-6">
                                <h4>Logomarca da loja</h4>
                                <br />
                                <div className="col-4 mt-10">
                                    <input type="file" accept="image/*" onChange={handleFileChange} />
                                    <img
                                        src={selectedFile ? URL.createObjectURL(selectedFile) : (dadosEmpresa.logonfe ? dadosEmpresa.logonfe : 'https://cdn-icons-png.flaticon.com/512/3135/3135768.png')}
                                        className="img-fluid img-thumbnail"
                                        alt="Imagem"
                                        style={{ maxWidth: '200px', maxHeight: '200px' }}
                                    />
                                    <button onClick={handleUploadButtonClick}>Enviar Imagem</button>

                                    {/* Exibir mensagem de sucesso ou erro */}
                                    {uploadMessage && (
                                        <div className={`alert ${isUploadSuccess ? 'alert-success' : 'alert-danger'}`} role="alert">
                                            {uploadMessage}
                                        </div>
                                    )}
                                </div>
                                <br />
                                <h6>Para recibos, orçamento e NFe</h6>

                            </div>
                            <div className="col-6">
                                <h4>Logomarca da Prefeitura</h4>
                                <br />
                                <div className="col-4 mt-10">
                                    <input type="file" accept="image/*" onChange={handleFile2Change} />
                                    <img
                                        src={selectedFile2 ? URL.createObjectURL(selectedFile2) :
                                            (dadosEmpresa.LogoPrefeitura ?
                                                dadosEmpresa.LogoPrefeitura : 'https://cdn-icons-png.flaticon.com/512/3135/3135768.png')}
                                        className="img-fluid img-thumbnail"
                                        alt="Imagem"
                                        style={{ maxWidth: '200px', maxHeight: '200px' }}
                                    />
                                    <button onClick={handleUploadButton2Click}>Enviar Imagem</button>

                                    {/* Exibir mensagem de sucesso ou erro */}
                                    {uploadMessage2 && (
                                        <div className={`alert ${isUploadSuccess2 ? 'alert-success' : 'alert-danger'}`} role="alert">
                                            {uploadMessage2}
                                        </div>
                                    )}
                                </div>
                                <br />
                                <h6>Para Notas de Serviço</h6>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <br />
            <div className="row align-items-end footer">
                <div className="text-center">
                    <button className="btn btn-outline-primary col-xl-4 col-md-4 col-sm-12 me-3" onClick={handleCancelar}>
                        Cancelar
                    </button>
                    <button className="btn btn-primary col-xl-4 col-md-4 col-sm-12" onClick={handleSalvar}>
                        Salvar Dados
                    </button>
                </div>
            </div>
        </div>
    </>
}

export default CadastroEmpresa;