import React, { useState, useRef } from "react";

import api from "../../services/api.js";
import './formulariodeadesao.css';


function FormularioDeAdesao({ plano, onClose }) {

    const userrest = process.env.REACT_APP_API_USER;
    const passwordrest = process.env.REACT_APP_API_PASSWORD;
    const cnpjInputRef = useRef(null);
    const razaoRef = useRef(null);
    const fantasiaRef = useRef(null);
    const enderecoRef = useRef(null);
    const numeroRef = useRef(null);
    const bairroRef = useRef(null);
    const cidadeRef = useRef(null);
    const ufRef = useRef(null);
    const cepRef = useRef(null);
    const dddRef = useRef(null);
    const foneRef = useRef(null);
    const cnpjRef = useRef(null);
    const emailRef = useRef(null);
    const usuarioRef = useRef(null);
    const senhaRef = useRef(null);
    const confirmaemailRef = useRef(null);
    const confirmasenhaRef = useRef(null);
    const nomecomercialRef = useRef(null);

    const subtitulo = () => {
        return plano === 1 ? 'Plano Pupunha' : plano === 2 ? 'Plano Tucumã' : 'Plano Açaí';
    }

    const [dadosEmpresa, setDadosEmpresa] = useState({

        // Informações Principais
        razao: '',
        fantasia: '',
        endereco: '',
        complemento: '',
        numero: '',
        bairro: '',
        cidade: '',
        uf: '',
        cep: '',
        ddd: '',
        fone: '',
        cnpj: '',
        email: '',
        site: '',
        usuario: '',
        senha: '',
        confirmaemail: '',
        confirmasenha: '',
        nomecomercial: '',
        banco: '',
    }); // Novo estado para armazenar os dados


    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        // Se o tipo for um checkbox, use o valor "checked" em vez do "value"
        const inputValue = type === 'checkbox' ? (checked ? 'S' : 'N') : value;

        setDadosEmpresa((prevState) => ({
            ...prevState,
            [name]: inputValue
        }));
    };

    const formatCNPJ = (cnpj) => {
        if (cnpj.length <= 11) {
            return cnpj.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else {
            return cnpj.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }

    };

    async function buscarCNPJ(cnpj) {
        try {
            const response = await api.get('/cnpj/' + cnpj, {
                query: {
                    user: userrest,
                    senha: passwordrest
                }
            });


            const data = response.data;

            setDadosEmpresa(prevState => ({
                ...prevState,
                razao: data.razao,
                fantasia: data.fantasia,
                endereco: data.logradouro,
                complemento: data.complemento,
                numero: data.numero,
                bairro: data.bairro,
                cidade: data.municipio,
                uf: data.uf,
                cep: data.cep,
                email: data.email
            }));

        } catch (error) {
            alert("CNPJ Inválido");
        }
    }


    async function ChecarCNPJ(cnpj) {
        try {

            const response = await api.get('/checar/' + cnpj, {
                query: {
                    user: userrest,
                    senha: passwordrest
                }
            });

            const data = response.data;

            return data;

        } catch (error) {
            alert("CNPJ Inválido");
        }
    }

    async function ValidarCNPJ(cnpj) {
        try {

            const response = await api.get('/validar/' + cnpj, {
                query: {
                    user: userrest,
                    senha: passwordrest
                }
            });

            const data = response.data;

            return data;

        } catch (error) {
            alert("CNPJ Inválido");
        }
    }

    const handleBlurCNPJ = async () => {
        try {
            if (dadosEmpresa.cnpj !== '') {

                let resultado = await ValidarCNPJ(dadosEmpresa.cnpj);

                if (resultado === 'CNPJ inválido') {
                    alert(resultado);
                    handleInputChange({
                        target: {
                            name: 'cnpj',
                            value: ''
                        }
                    });
                    cnpjInputRef.current.focus();
                } else {
                    resultado = await ChecarCNPJ(dadosEmpresa.cnpj);

                    if (resultado === 'CNPJ já cadastrado!') {
                        alert(resultado);
                        handleInputChange({
                            target: {
                                name: 'cnpj',
                                value: ''
                            }
                        });
                        cnpjInputRef.current.focus();
                    } else {
                        buscarCNPJ(dadosEmpresa.cnpj);
                    }
                }
            }

        } catch (error) {
            // Caso você queira lidar com algum erro específico.
            console.error("Ocorreu um erro ao checar o CNPJ:", error);
        }
    };

    const handleBlurCep = () => {
        const formattedCep = formatCEP(dadosEmpresa.cep);

        // Verifique se o CEP é válido antes de fazer a busca
        if (formattedCep.length === 9) {
            buscarEnderecoPorCep(dadosEmpresa.cep);
        }
    };

    async function buscarEnderecoPorCep(cep) {
        try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            const data = await response.json();

            if (data.erro) {
                console.log('CEP não encontrado.');
                return;
            }

            setDadosEmpresa(prevState => ({
                ...prevState,
                endereco: data.logradouro,
                complemento: data.complemento,
                bairro: data.bairro,
                cidade: data.municipio,
                uf: data.uf,
                cep: data.cep,
            }));

        } catch (error) {
            console.error('Erro ao buscar CEP:', error);
        }
    }

    const formatCEP = (cep) => {
        return cep.replace(/\D/g, '').replace(/(\d{5})(\d{3})/, '$1-$2');
    };

    const handleCepChange = (e) => {
        const formattedCep = e.target.value.replace(/[.\s-]/g, '');
        handleInputChange({
            target: {
                name: 'cep',
                value: formattedCep
            }
        });

    };

    const formatfone = (fone) => {
        return fone.replace(/\D/g, '').replace(/(\d{1})(\d{4})(\d{4})/, '$1 $2-$3');
    };

    const handlefoneChange = (e) => {
        const foneFormated = e.target.value.replace(/[\s-]/g, '')
        handleInputChange({
            target: {
                name: 'fone',
                value: foneFormated
            }
        });
    };


    const handleCancelar = () => {
        onClose();
    };

    const handleSubmit = (e) => {

        if (!dadosEmpresa.razao) {
            alert('Informe a razão social!');
            razaoRef.current.focus();
            return; 
        }

        if (!dadosEmpresa.fantasia) {
            alert('Informe o nome fantasia!');
            fantasiaRef.current.focus();
            return; 
        }

        if (!dadosEmpresa.endereco) {
            alert('Informe o endereco!');
            enderecoRef.current.focus();
            return; 
        }

        if (!dadosEmpresa.numero) {
            alert('Informe o numero!');
            numeroRef.current.focus();
            return; 
        }

        if (!dadosEmpresa.bairro) {
            alert('Informe o bairro!');
            bairroRef.current.focus();
            return; 
        }
        
        if (!dadosEmpresa.cidade) {
            alert('Informe a cidade!');
            razaoRef.current.focus();
            return; 
        }
       
        if (!dadosEmpresa.uf) {
            alert('Informe a uf!');
            ufRef.current.focus();
            return; 
        }

        if (!dadosEmpresa.cep) {
            alert('Informe o cep!');
            cepRef.current.focus();
            return; 
        }
        
        if (!dadosEmpresa.ddd) {
            alert('Informe o ddd!');
            dddRef.current.focus();
            return; 
        }

        if (!dadosEmpresa.fone) {
            alert('Informe o telefone!');
            foneRef.current.focus();
            return; 
        }
        
        if (!dadosEmpresa.cnpj) {
            alert('Informe o cnpj!');
            cnpjRef.current.focus();
            return; 
        }

        if (!dadosEmpresa.email) {
            alert('Informe o email!');
            emailRef.current.focus();
            return; 
        }
        
        if (!dadosEmpresa.usuario) {
            alert('Informe o nome de contato!');
            usuarioRef.current.focus();
            return; 
        }
        
        if (!dadosEmpresa.senha) {
            alert('Informe a sua senha de acesso!');
            senhaRef.current.focus();
            return; 
        }
        
        if (!dadosEmpresa.confirmaemail) {
            alert('Confirme o seu email!');
            confirmaemailRef.current.focus();
            return; 
        }

        if (!dadosEmpresa.confirmasenha) {
            alert('Confirme a sua senha!');
            confirmasenhaRef.current.focus();
            return; 
        }

        if (!dadosEmpresa.nomecomercial) {
            alert('informe o nome comercial!');
            nomecomercialRef.current.focus();
            return; 
        }
        
        if (dadosEmpresa.email !== dadosEmpresa.confirmaemail) {
            alert("Os e-mails não correspondem!");
            e.preventDefault(); // Isso evita que o formulário seja enviado
            return;
        }

        if (dadosEmpresa.senha !== dadosEmpresa.confirmasenha) {
            alert("As senhas não correspondem!");
            e.preventDefault(); // Isso evita que o formulário seja enviado
            return;
        }

        try {

            let empresaParaSalvar = {
                ...dadosEmpresa,
                razao: dadosEmpresa.razao.trim(),
                fantasia: dadosEmpresa.fantasia.trim(),
                bairro: dadosEmpresa.bairro.trim(),
                complemento: dadosEmpresa.complemento.trim(),
                endereco: dadosEmpresa.endereco.trim(),
                cep: dadosEmpresa.cep.replace(/\D/g, ""),
                ddd: dadosEmpresa.ddd.trim(),
                fone: dadosEmpresa.fone.trim(),
                cnpj: dadosEmpresa.cnpj.trim(),
                site: dadosEmpresa.site.trim(),
                email: dadosEmpresa.email.trim(),
                banco: dadosEmpresa.nomecomercial.trim().replace(/\s+/g, '').substring(0, 30),
            };
            // Usar a requisição PUT para atualizar os dados do cliente
            api.post(`/cadastro`, empresaParaSalvar,{
                query: {
                    user: userrest,
                    senha: passwordrest
                }
            })
                .then((response) => {
                    // Tratar a resposta, se necessário
                    window.alert('Registro alterado com sucesso!');
                })
                .catch((error) => {
                    console.error('Erro ao salvar dados da empresa:', error);
                    window.alert('Falha ao alterar o registro!');
                })
                .finally(() => {
                    onClose();
                });

        } catch (error) {
            console.error('Erro ao salvar todas as informações da empresa', error);
        }
    };


    return <>

        <div className="container-fluid h-100 pt-4 sidebar p-4">

            <h4 className="main-sub-title">
                Nortesoft Tecnologia - Formulário de Adesão
            </h4>

            <br />

            <h5 className="sub-title-alt mt-2">
                {subtitulo()}
            </h5>


            <div className="row tela-cad">
                <div className="container-fluid">
                    <div className='row'>
                        <div className="col-xl-4 col-md-4 col-sm-12 mt-3">
                            <small className="text-secondary">CNPJ</small>
                            <input
                                type="text"
                                name="cnpj"
                                className="form-control"
                                onChange={handleInputChange}
                                value={formatCNPJ(dadosEmpresa.cnpj)}
                                placeholder="*digite o CNPJ"
                                onBlur={handleBlurCNPJ}
                                ref={cnpjInputRef}
                                required
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-6 col-md-6 col-sm-12 mt-2">
                            <small className="text-secondary">Razão Social</small>
                            <input
                                type="text"
                                name="razao"
                                className="form-control"
                                onChange={handleInputChange}
                                value={dadosEmpresa.razao}
                                required
                                ref={razaoRef} 
                            />
                        </div>


                        <div className="col-xl-6 col-md-6 col-sm-12 mt-2">
                            <small className="text-secondary">Nome Fantasia</small>
                            <input type="text"
                                name="fantasia"
                                className="form-control"
                                onChange={handleInputChange}
                                value={dadosEmpresa.fantasia}
                                required
                                ref={fantasiaRef} 
                            />
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-xl-3 col-md-3 col-sm-12 mt-2">
                            <small className="text-secondary">CEP</small>
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    type="text"
                                    style={{ textAlign: 'right' }}
                                    name="cep"
                                    value={formatCEP(dadosEmpresa.cep)}
                                    onChange={handleCepChange}
                                    required
                                    ref={cepRef} 
                                />
                                <div className="input-group-append">
                                    <button onClick={handleBlurCep} className="btn btn-outline-secondary" type="button">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>

                        </div>

                        <div className='col-xl-7 col-md-7 col-sm-12 mt-2'>
                            <small className="text-secondary">Endereço</small>
                            <input
                                type="text"
                                name="endereco"
                                className="form-control"
                                onChange={handleInputChange}
                                value={dadosEmpresa.endereco}
                                required
                                ref={enderecoRef} 
                            />

                        </div>


                        <div className='col-xl-2 col-md-2 col-sm-12 mt-2'>
                            <small className="text-secondary">Número</small>
                            <input type="text"
                                style={{ textAlign: 'right' }}
                                name="numero"
                                className="form-control"
                                onChange={handleInputChange}
                                value={dadosEmpresa.numero}
                                required
                                ref={numeroRef} 
                            />
                        </div>

                    </div>

                    <div className="row">

                        <div className='col-xl-5 col-md-5 col-sm-12 mt-2'>
                            <small className="text-secondary">Complemento</small>
                            <input
                                type="text"
                                name="complemento"
                                className="form-control"
                                onChange={handleInputChange}
                                value={dadosEmpresa.complemento}
                            />
                        </div>

                        <div className='col-xl-5 col-md-5 col-sm-12 mt-2'>
                            <small className="text-secondary">Cidade</small>
                            <input
                                type="text"
                                name="cidade"
                                className="form-control"
                                onChange={handleInputChange}
                                value={dadosEmpresa.cidade}
                                required
                                ref={cidadeRef} 
                            />
                        </div>

                        <div className='col-xl-2 col-md-2 col-sm-12 mt-2'>
                            <small className="text-secondary">UF</small>
                            <input
                                type="text"
                                name="uf"
                                className="form-control"
                                onChange={handleInputChange}
                                value={dadosEmpresa.uf}
                                required
                                ref={ufRef} 
                            />
                        </div>

                    </div>


                    <div className="row">

                        <div className='col-xl-2 col-md-2 col-sm-4 mt-1 mt-2'>
                            <small className="text-secondary">DDD</small>
                            <input
                                type="text"
                                style={{ textAlign: 'right' }}
                                name="ddd"
                                className="form-control"
                                onChange={handleInputChange}
                                value={dadosEmpresa.ddd}
                                required
                                ref={dddRef} 
                            />
                        </div>

                        <div className='col-xl-3 col-md-4 col-sm-8  mt-2'>
                            <small className="text-secondary">Fone</small>
                            <input type="text"
                                style={{ textAlign: 'right' }}
                                name="fone"
                                className="form-control"
                                onChange={handlefoneChange}
                                value={formatfone(dadosEmpresa.fone)}
                                required
                                ref={foneRef} 
                            />
                        </div>

                    </div>


                    <div className="row">

                        <div className='col-xl-6 col-md-12 col-sm-12 mt-2'>
                            <small className="text-secondary">Email</small>
                            <input type="text"
                                name="email"
                                className="form-control"
                                onChange={handleInputChange}
                                value={dadosEmpresa.email}
                                required
                                ref={emailRef} 
                            />
                        </div>

                        <div className='col-xl-6 col-md-12 col-sm-12 mt-2'>
                            <small className="text-secondary">Confirne o seu Email</small>
                            <input type="text"
                                name="confirmaemail"
                                className="form-control"
                                onChange={handleInputChange}
                                value={dadosEmpresa.confirmaemail}
                                required
                                ref={confirmaemailRef} 
                            />
                        </div>

                    </div>

                    <div className="row">

                        <div className='col-xl-6 col-md-6 col-sm-12 mt-2'>
                            <small className="text-secondary">Site</small>
                            <input type="text"
                                name="site"
                                className="form-control"
                                onChange={handleInputChange}
                                value={dadosEmpresa.site}
                            />
                        </div>

                        <div className='col-xl-6 col-md-6 col-sm-12 mt-2'>
                            <small className="text-secondary">Nome comercial</small>
                            <input type="text"
                                name="nomecomercial"
                                className="form-control custom-placeholder"
                                onChange={handleInputChange}
                                value={dadosEmpresa.nomecomercial}
                                placeholder="*Ex.: Casas Bahia, Magazine Luiza, etc..."
                                required
                                ref={nomecomercialRef} 
                            />
                        </div>

                    </div>

                    <div className="row">

                        <div className='col-xl-4 col-md-4 col-sm-12 mt-2'>
                            <small className="text-secondary">Contato</small>
                            <input type="text"
                                name="usuario"
                                className="form-control custom-placeholder"
                                onChange={handleInputChange}
                                value={dadosEmpresa.usuario}
                                placeholder="*Nome e sobrenome"
                                required
                                ref={usuarioRef} 
                            />
                        </div>

                        <div className='col-xl-4 col-md-4 col-sm-12 mt-2'>
                            <small className="text-secondary">Senha</small>
                            <input
                                name="senha"
                                type="password"
                                className="form-control custom-placeholder"
                                onChange={handleInputChange}
                                value={dadosEmpresa.senha}
                                placeholder="*Escolha uma senha forte"
                                required
                                ref={senhaRef} 
                            />
                        </div>

                        <div className='col-xl-4 col-md-4 col-sm-12 mt-2'>
                            <small className="text-secondary">Confirme a sua Senha</small>
                            <input
                                name="confirmasenha"
                                type="password"
                                className="form-control custom-placeholder"
                                placeholder="*Repita a sua senha"
                                onChange={handleInputChange}
                                value={dadosEmpresa.confirmasenha}
                                required
                                ref={confirmasenhaRef} 
                            />
                        </div>

                    </div>

                    <div className="button-container text-right">
                        <button onClick={handleCancelar} className="btn btn-secondary">Cancelar</button>
                        <button onClick={handleSubmit} className="btn btn-primary">Cadastrar</button>
                    </div>
                </div>
            </div >
        </div >
    </>
}

export default FormularioDeAdesao;