import axios from "axios";
import { navigate } from "./navigationHelper";
const URL = process.env.REACT_APP_API_URL; // Configure em seu arquivo .env
//const URL = 'https://192.168.0.253:3001'; 

// Instância para autenticação básica
const apiBasicAuth = axios.create({
    baseURL: URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json'
    },
    auth: {
        username: localStorage.getItem("user"),
        password: localStorage.getItem("password"),
    }
});

const api = axios.create({
    baseURL: URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }

});

// Interceptor para a instância de autenticação básica
apiBasicAuth.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        console.log("Erro 401 na instância com autenticação básica");
    }
    return Promise.reject(error);
});

// Interceptador de resposta para gerenciar erros globalmente
api.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
    }
    return Promise.reject(error);
});

api.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token');

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error)
  );
  

export default api;
