import React, { useEffect, useState, useCallback } from 'react';
import api from '../../../services/api.js';
import './cadastroportador.css';


function CadastroPortador({ operacao, codigo, onClose }) {
    const [cdban, setCdban] = useState('');
    const [dadosportador, setDadosPortador] = useState({
        cod: '',
        bco_lim: 0,
        TxEmisBol: 0,
        cbhost: '',
        lote: '',
        nrboleto: '',
        remessa: '',
        Agencia: '',
        Ambiente: '',
        ativo: 'S',
        bloqven: '',
        CaminhoDoCert: '',
        Carteira: '',
        cdban: '',
        Cedente: '',
        ChavePIX: '',
        ClientID: '',
        ClientSecret: '',
        CodTransmissao: '',
        Conta: '',
        conta2: '',
        conta3: '',
        conta4: '',
        contabil: '',
        contrato: '',
        deban: '',
        DigAgencia: '',
        DigCedente: '',
        DigConta: '',
        DigCta2: '',
        digCta3: '',
        digCta4: '  ',
        emitebol: '',
        LAYOUT: '',
        PSP: '',
        SenhaDoCert: '',
        TipoChavePIX: '',
        TIPOCOB: '',
        VARCARTEIRA: ''
    }); // Novo estado para armazenar os dados

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        // Se o tipo for um checkbox, use o valor 'checked' em vez do 'value'
        const inputValue = type === 'checkbox' ? (checked ? 'S' : 'N') : value;

        setDadosPortador((prevState) => ({
            ...prevState,
            [name]: inputValue
        }));
    };

    const handleCancelar = () => {
        onClose();
    };

    const handleSalvar = async () => {

        const params = new URLSearchParams({
            user: localStorage.getItem('user'),
            gmt: '-04:00'
        });

        if (dadosportador) {
            let dadosportadorParaSalvar = {
                ...dadosportador,
                deban: dadosportador.deban.trim(),

            };
            
            try {
                if (codigo) {
                    // Usar a requisição PUT para atualizar os dados do plano de contas
                    try {
                        api.put(`/t_bco/${codigo}?${params}`, dadosportadorParaSalvar)
                        .then((response) => {
                            // Tratar a resposta, se necessário
                          });
                        
                        window.alert('Registro alterado com sucesso!');
                    } catch (error) {
                        console.error('Erro ao salvar dados do plano de contas:', error);
                        window.alert('Falha ao alterar o registro!');
                    } finally {
                        onClose();
                    }
                } else {
                    delete dadosportadorParaSalvar.cod;
                    // Usar a requisição POST para criar um novo plano de contas
                    try {
                        const response = await api.post(`/t_bco?${params}`, dadosportadorParaSalvar);
                        dadosportadorParaSalvar.cod = response.data.cod;
                        // Tratar a resposta, se necessário
                        window.alert('Registro ' + response.data.cod + ' inserido com sucesso!');
                    } catch (error) {
                        console.error('Erro ao salvar a nova conta:', error);
                        window.alert('Falha ao inserir o registro');
                    } finally {
                        onClose();
                    }
                }
            } catch (error) {
                console.error('Erro ao salvar as informações do plano de contas:', error);
            }
        }
    };


    const handleNovo = () => {
        setDadosPortador({
            cod: '',
            bco_lim: 0,
            TxEmisBol: 0,
            cbhost: '',
            lote: '',
            nrboleto: '',
            remessa: '',
            Agencia: '',
            Ambiente: '',
            ativo: 'S',
            bloqven: '',
            CaminhoDoCert: '',
            Carteira: '',
            cdban: '',
            Cedente: '',
            ChavePIX: '',
            ClientID: '',
            ClientSecret: '',
            CodTransmissao: '',
            Conta: '',
            conta2: '',
            conta3: '',
            conta4: '',
            contabil: '',
            contrato: '',
            deban: '',
            DigAgencia: '',
            DigCedente: '',
            DigConta: '',
            DigCta2: '',
            digCta3: '',
            digCta4: '  ',
            emitebol: '',
            LAYOUT: '',
            PSP: '',
            SenhaDoCert: '',
            TipoChavePIX: '',
            TIPOCOB: '',
            VARCARTEIRA: ''
        }); // Novo estado para armazenar os dados


    };

    const handleBlurCodigo = (e) => {
        if (operacao !== 'Inserir') {
            getPortadorPorCdban(e.target.value);
        }
    };
    


    // Requisição GET para buscar os dados da fornecedor pelo ID
    const getDadosDoPortador = useCallback(async (codred) => {

        api.get('/t_bco/' + codred, {
            params: {
                user: localStorage.getItem('user')
            }
        })
            .then((resp) => {
                const Dados = resp.data;
                setDadosPortador(Dados);
            })
            .catch((err) => {
                setDadosPortador();
                alert('Erro ao buscar dados da conta: ' + err.message);
            });
    }, []);

    const getPortadorPorCdban = useCallback(async (cdban) => {

        api.get('/t_bco/', {
            params: {
                user: localStorage.getItem('user'),
                cdban: cdban
            }
        })
            .then((resp) => {
                const Dados = resp.data.rows[0];
                setDadosPortador(Dados);

            })
            .catch((err) => {
                setDadosPortador();
                alert('Erro ao buscar dados da conta: ' + err.message);
            });
    }, []);

    useEffect(() => {

        setCdban(codigo);
        if (codigo) {
            getDadosDoPortador(codigo);
        }

        if (operacao === 'Inserir') {
            handleNovo();
        }
    }, [operacao, codigo, getDadosDoPortador]);

    return <>

        <div className='container-fluid h-100 pt-4 sidebar p-4'>
            <h4 className='d-inline'>
                {operacao === 'Editar' ? dadosportador.deban : 'Novo Portador'}
            </h4>
            <div className='row tela-cad'>
                <div className='row'>
                    <div className='col-xl-2 col-md-6 col-sm-12 mt-4'>
                        <small className='text-secondary'>Código</small>
                        <input type='text'
                            style={{ textAlign: 'right' }}
                            name='cdban'
                            className='form-control'
                            onChange={handleInputChange}
                            value={dadosportador.cdban}
                            onBlur={handleBlurCodigo}
                        //disabled={true}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-12 col-md-12 col-sm-12 mt-2'>
                        <small className='text-secondary'>Descrição</small>
                        <input
                            type='text'
                            name='deban'
                            className='form-control'
                            onChange={handleInputChange}
                            value={dadosportador.deban}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-xl-4 col-md-6 col-sm-12 mt-2'>
                        <small className='text-secondary'>Agência</small>
                        <input type='text'
                            style={{ textAlign: 'right' }}
                            name='Agencia'
                            className='form-control'
                            onChange={handleInputChange}
                            value={dadosportador.Agencia}
                        />
                    </div>

                    <div className='col-xl-2 col-md-6 col-sm-12 mt-2'>
                        <small className='text-secondary'>Digito</small>
                        <input
                            type='text'
                            style={{ textAlign: 'right' }}
                            name='DigAgencia'
                            className='form-control'
                            onChange={handleInputChange}
                            value={dadosportador.DigAgencia}
                        />
                    </div>

                    <div className='col-xl-4 col-md-6 col-sm-12 mt-2'>
                        <small className='text-secondary'>Conta</small>
                        <input type='text'
                            style={{ textAlign: 'right' }}
                            name='Conta'
                            className='form-control'
                            onChange={handleInputChange}
                            value={dadosportador.Conta}
                        />
                    </div>

                    <div className='col-xl-2 col-md-6 col-sm-12 mt-2'>
                        <small className='text-secondary'>Digito</small>
                        <input
                            type='text'
                            style={{ textAlign: 'right' }}
                            name='DigConta'
                            className='form-control'
                            onChange={handleInputChange}
                            value={dadosportador.DigConta}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-4 col-md-6 col-sm-12 mt-2'>
                        <small className='text-secondary'>Contábil</small>
                        <input
                            type='text'
                            name='contabil'
                            className='form-control'
                            onChange={handleInputChange}
                            value={dadosportador.contabil}
                        />
                    </div>
                </div>

            </div>


            <br />
            <div className='row align-items-end footer'>
                <div className='text-center'>
                    <button className='btn btn-outline-primary col-xl-4 col-md-4 col-sm-12 me-3' onClick={handleCancelar}>
                        Cancelar
                    </button>
                    <button className='btn btn-primary col-xl-4 col-md-4 col-sm-12' onClick={handleSalvar}>
                        Salvar Dados
                    </button>
                </div>
            </div>
        </div>
    </>
}

export default CadastroPortador;