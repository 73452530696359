import React from 'react';

function EmpresaOpcoes({ dadosEmpresa, onEmpresaChange }) {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onEmpresaChange({ [name]: value });
    };


    return <>
        <div className='row '>
            <div className="col-xl-6 col-md-12 col-sm-6 mt-1">
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="ConsultarFone"
                            checked={dadosEmpresa.ConsultarFone === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.ConsultarFone === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>Consultar cliente p/ Telefone</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="ImpMPnoPV"
                            checked={dadosEmpresa.ImpMPnoPV === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.ImpMPnoPV === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>Imprimir MP no pedido</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="PetShop"
                            checked={dadosEmpresa.PetShop === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.PetShop === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>É PetShop</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="AutVendAbxQteEmb"
                            checked={dadosEmpresa.AutVendAbxQteEmb === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.AutVendAbxQteEmb === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>Controlar embalagem</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="ControlaSaidaDaComanda"
                            checked={dadosEmpresa.ControlaSaidaDaComanda === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.ControlaSaidaDaComanda === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>Controlar comanda</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="LerBarrasMod2"
                            checked={dadosEmpresa.LerBarrasMod2 === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.LerBarrasMod2 === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>Barcode Mod. 2</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="BloqItemSemSaldoPDV"
                            checked={dadosEmpresa.BloqItemSemSaldoPDV === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.BloqItemSemSaldoPDV === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>Bloqueia venda sem saldo</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="PedirTXDeEntrega"
                            checked={dadosEmpresa.PedirTXDeEntrega === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.PedirTXDeEntrega === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>Pedir taxa de entrega</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="ImprimirTXDeEntNoNFC"
                            checked={dadosEmpresa.ImprimirTXDeEntNoNFC === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.ImprimirTXDeEntNoNFC === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>Imprimir taxa de entrega</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="AGENDAPORHORA"
                            checked={dadosEmpresa.AGENDAPORHORA === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.AGENDAPORHORA === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>Controlar agenda por hora</span>
                    </div>
                </div>
            </div>

            <div className="col-xl-6 col-md-12 col-sm-6 mt-1">

                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="PDVModoFicha"
                            checked={dadosEmpresa.PDVModoFicha === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.PDVModoFicha === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>PDV no modo ficha</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="LogoExpandidaSN"
                            checked={dadosEmpresa.LogoExpandidaSN === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.LogoExpandidaSN === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>Logo expandida</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="ComissaoPorMarkup"
                            checked={dadosEmpresa.ComissaoPorMarkup === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.ComissaoPorMarkup === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>Comissão por markup</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="atacadista"
                            checked={dadosEmpresa.atacadista === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.atacadista === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>Vender no atacado</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="impincetiv"
                            checked={dadosEmpresa.impincetiv === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.impincetiv === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>NFe com incentivo</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="industria"
                            checked={dadosEmpresa.industria === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.industria === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>É industria</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="matriz"
                            checked={dadosEmpresa.matriz === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.matriz === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>É a matriz`</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            name="ImpRecCx"
                            checked={dadosEmpresa.ImpRecCx === 'S'}
                            onChange={handleInputChange}
                            value={dadosEmpresa.ImpRecCx === 'S' ? 'N' : 'S'}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        <span style={{ marginLeft: '8px' }}>Imprimir recibo no caixa</span>
                    </div>
                </div>
            </div>


        </div>
    </>
}

export default EmpresaOpcoes;