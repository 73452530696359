import React from 'react';
import Modal from 'react-modal';
import './modalcadastros.css';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90%',
    overflow: 'auto',
    backgroundColor: '#f7f7f7',
    width: '90%',
    maxWidth: '100%',
  },
};


const ModalCadastros = ({ isOpen, onClose, title, children, maxWidth }) => {
  const dynamicStyles = {
    ...customStyles.content,
    maxWidth: maxWidth, // Usando a propriedade configurável
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{ content: dynamicStyles }}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      contentLabel={title}
    >
      <h5>{title}</h5>
      {children}
    </Modal>
  );
};

export default ModalCadastros;
