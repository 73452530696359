import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import InFoClienteAssocicao from '../informacaoclienteassociacao/informacaoclienteassociacao';
import api from '../../services/api';
import s3 from '../../services/awsconfig';


function ClienteInfoTabs({ formData, operacao, onDataChange, Socio, onSocioChange }) {
  const [activeKey, setActiveKey] = useState('informacoesPessoais');
  const handleTabSelect = (key) => {
    setActiveKey(key);
  };

  //  Função para atualizar os dados e chamar a função de callback
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onDataChange({ [name]: value });
  };

  const handleTipocliChange = (e) => {
    handleInputChange({
      target: {
        name: 'tipocli',
        value: (e.target.value === 'fisica' ? 'F' : 'J')
      }
    });
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value.trim();
    onDataChange({ estcli: selectedValue }); // Chamando onDataChange aqui
  };

  const handleSelectCidChange = (e) => {
    const selectedValue = e.target.value.trim();
    onDataChange({ cidcli: selectedValue }); // Chamando onDataChange aqui
  };

  const handleSelectBaiChange = (e) => {
    const selectedValue = e.target.value.trim();
    onDataChange({ baicli: selectedValue }); // Chamando onDataChange aqui
  };



  const [ufs, setUFs] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [bairros, setBairros] = useState([]);

  const [tipoemp, setTipoemp] = useState();
  const [titulo, setTitulo] = useState();
  const [razao, setRazao] = useState();
  const [cnpj, setCnpj] = useState();
  const [apelido, setApelido] = useState();
  const [ie, setIe] = useState();
  const [fundacao, setFundacao] = useState();
  const [entrega, setEntrega] = useState();
  const [responsavel, setResponsavel] = useState();

  const [uploadMessage, setUploadMessage] = useState('');
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);

  const formatDateForDatabase = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  // Função utilitária para formatar a data
  const formatDate = (data) => {

    if (data === null) {
      data = '1900-01-01T00:00:00.000Z'; // Retorna uma string vazia se a data for nula
    }

    if (data === '') {
      data = '1900-01-01T00:00:00.000Z'; // Retorna uma string vazia se a data for nula
    }

    if (!data) {
      data = '1900-01-01T00:00:00.000Z'; // Retorna uma string vazia se a data for nula
    }

    data = new Date(data).toISOString().split('T')[0];
    return data;

  };
  
  

  const formatCNPJ = (cnpj) => {
    if (cnpj) {
      if (cnpj.length <= 11) {
        return cnpj.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else {
        return cnpj.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }
    } else {
      return cnpj;
    }
  };

  const handleCnpjChange = (e) => {
    const cnpjFormatted = e.target.value.replace(/[./\-]/g, '');
    handleInputChange({
      target: {
        name: 'cnpj_cpfcli',
        value: cnpjFormatted

      }
    });
  };

  const formatCEP = (cep) => {
    if (cep) {
      return cep.replace(/\D/g, '').replace(/(\d{5})(\d{3})/, '$1-$2');
    } else {
      return cep;
    }

  };

  const handleCepChange = (e) => {
    const formattedCep = e.target.value.replace(/[.\s-]/g, '');
    handleInputChange({
      target: {
        name: 'cepcli',
        value: formattedCep
      }
    });

  };

  const formatTelefone = (telefone) => {
    if (telefone) {
      return telefone.replace(/\D/g, '').replace(/(\d{1})(\d{4})(\d{4})/, '$1 $2-$3');
    } else {
      return telefone;
    }
  };

  const handleTelefoneChange = (e) => {
    const fonecliFormated = e.target.value.replace(/[\s-]/g, '')
    handleInputChange({
      target: {
        name: 'fonecli',
        value: fonecliFormated
      }
    });
  };


  const handlefoneContatoChange = (e) => {
    const fonecontatoFormated = e.target.value.replace(/[\s-]/g, '');
    handleInputChange({
      target: {
        name: 'fonerespcli',
        value: fonecontatoFormated
      }
    });
  };

  const handleBlurCep = () => {
    const formattedCep = formatCEP(formData.cepcli);

    // Verifique se o CEP é válido antes de fazer a busca
    if (formattedCep.length === 9) {
      buscarEnderecoPorCep(formData.cepcli);
    }
  };

  async function buscarEnderecoPorCep(cep) {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();

      if (data.erro) {
        console.log('CEP não encontrado.');
        return;
      }

      const endereco = data.logradouro;
      const complemento = data.complemento;
      const uf = data.uf;
      const cidade = data.localidade;
      const bairro = data.bairro;

      // Atualizar os campos do formulário
      if (endereco) handleInputChange({ target: { name: 'endcli', value: endereco } });
      if (complemento) handleInputChange({ target: { name: 'compcli', value: complemento } });
      if (bairro) handleInputChange({ target: { name: 'baicli', value: bairro } });
      if (cidade) handleInputChange({ target: { name: 'cidcli', value: cidade } });
      if (uf) handleInputChange({ target: { name: 'estcli', value: uf } });

      onDataChange({ baicli: bairro, estcli: uf, cidcli: cidade });

    } catch (error) {
      console.error('Erro ao buscar CEP:', error);
    }
  }

  function ListarBairros(user, cidade) {

    api.get("/t_bai", {
      params: {
        user,
        cidade
      }
    })
      .then((resp) => {
        const modifiedData = {
          total_registros: resp.data.count,
          dados: resp.data.rows
        };
        setBairros(modifiedData.dados);

      })
      .catch((err) => {

        setBairros([]);
        alert("Erro ao carregar bairros");
      });

  }

  function ListarUF() {

    api.get("/t_uf", {
      params: {
        user: localStorage.getItem("user")
      }
    })
      .then((resp) => {
        const modifiedData = {
          total_registros: resp.data.count,
          dados: resp.data.rows
        };
        setUFs(modifiedData.dados);
      })
      .catch((err) => {
        setUFs([]);
        alert("Erro ao carregar UF");
      });

  }

  function ListarCidades(user, uf) {

    api.get("/t_cid", {
      params: {
        user,
        uf
      }
    })
      .then((resp) => {
        const modifiedData = {
          total_registros: resp.data.count,
          dados: resp.data.rows
        };
        setCidades(modifiedData.dados);

      })
      .catch((err) => {
        setCidades([]);
        alert("Erro ao carregar cidades");
      });
  }

  function formatUF(uf) {
    if (uf) {
      return uf.toUpperCase();
    }

  }

  // Função para redimensionar a imagem usando o canvas HTML5
  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let width = img.width;
        let height = img.height;

        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }

        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          resolve(blob);
        }, file.type);
      };

      img.onerror = reject;
    });
  };

  const handleUploadButtonClick = async () => {
    if (selectedFile) {
      try {
        const currentTimestamp = Date.now();
        const imageName = `images/${formData.decli}-${currentTimestamp}-${selectedFile.name}`;
        //const fileName = `images/cliente-${formData.decli}-${Date.now()}-${selectedFile.name}`;

        // Redimensionar a imagem antes de enviar
        const resizedImage = await resizeImage(selectedFile, 200, 200); // Escolha as dimensões desejadas

        const params = {
          Bucket: 'sistemaraposaweb', // Nome do seu bucket S3
          Key: imageName,
          Body: resizedImage,
          ACL: 'public-read', // Configura a permissão de leitura pública
        };

        const result = await s3.upload(params).promise();
        const imageUrl = result.Location; // Aqui está a URL do arquivo no S3

        // Atualize o estado do formData com a URL da imagem
        onDataChange({ ...formData, fotocli: imageUrl });
        setIsUploadSuccess(true);
        setUploadMessage('Imagem enviada com sucesso para o S3.');
      } catch (error) {
        console.error('Erro ao enviar imagem para o S3:', error);
        setIsUploadSuccess(false);
        setUploadMessage('Erro ao enviar imagem para o S3.');
      }
    } else {
      console.error('Nenhum arquivo selecionado.');
    }
  };

  useEffect(() => {
    ListarUF();
    ListarCidades(localStorage.getItem("user"), localStorage.getItem("estemp"));
    ListarBairros(localStorage.getItem("user"), localStorage.getItem("cidemp"));

  }, []);

  useEffect(() => {
    handleTabSelect('informacoesPessoais');
  }, []);

  const [selectedFile, setSelectedFile] = useState(null);
  // const [imageUrl, setImageUrl] = useState(
  //   formData.fotocli ? formData.fotocli : 'https://cdn-icons-png.flaticon.com/512/3135/3135768.png'
  // );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const updatedFormData = { ...formData, fotocli: URL.createObjectURL(file) };
      onDataChange(updatedFormData);
      //setImageUrl(URL.createObjectURL(file));
    }
  };


  useEffect(() => {
    setTipoemp(localStorage.getItem("tipoemp"));
    
  }, []);


  useEffect(() => {
    if (tipoemp && typeof tipoemp === "string") {
      const firstTwoDigits = tipoemp.slice(0, 2);

      if (firstTwoDigits === '10') {
        setTitulo("Sindicalizado");
        setRazao("Nome");
        setCnpj("CPF");
        setIe("RG");
        setFundacao("Nascimento");
        setEntrega("Endereco");
        setResponsavel("Contato");
        setApelido("Apelido");

      } else {
        setTitulo("Cliente");
        setRazao("Nome/Razão Social");
        setCnpj("CPF/CNPJ");
        setIe("RG/Insc. Estadual");
        setFundacao("Nascimento/Fundação");
        setEntrega("Endereco");
        setResponsavel("Contato");
        setApelido("Apelido");
      }
    }
  }, [tipoemp]);

  return (
    <Tabs activeKey={activeKey} onSelect={handleTabSelect} id="clienteTabs"
      className="my-4 md-2" >
      {/* Aba 1 - Informações Principais */}
      <Tab eventKey="informacoesPessoais" title="Informações Principais">
        <div className='row'>
          <div className="col-4 mt-0">
            <small className="text-secondary">Código</small>
            <input type="text"
              name="cdcli"
              className="form-control"
              onChange={handleInputChange}
              value={formData.cdcli}
              //readOnly
              disabled={true}
               />
          </div>

          <div className='col-4'>

          </div>

          <div className="col-4 mt-0">
            <small className="text-secondary">Tipo</small>
            <div className="form-control mb-2">
              <select
                name="tipocli"
                onChange={handleTipocliChange}
                //value={formData.tipocli === 'F' ? 'fisica' : 'juridica'}
                value={formData.tipocli}
              >
                {/* <option value="fisica">Física</option>
                <option value="juridica">Jurídica</option> */}

                <option value="F">Física</option>
                <option value="J">Jurídica</option>
              </select>
            </div>
          </div>
        </div>

        <div className="col-12 mt-0">
          <small className="text-secondary">{razao}</small>
          <input
            type="text"
            name="decli"
            className="form-control"
            onChange={handleInputChange}
            value={formData.decli}
          />
        </div>


        <div className='row'>

        </div>

        <div className="col-12 mt-2">
          <small className="text-secondary">{apelido} </small>
          <input type="text"
            name="fantcli"
            className="form-control"
            onChange={handleInputChange}
            value={formData.fantcli}
          />
        </div>

        <div className="row">

          <div className='col-6 mt-2'>
            <small className="text-secondary">{cnpj}</small>
            <input
              type="text"
              name="cnpj_cpfcli"
              className="form-control"
              onChange={handleCnpjChange}
              value={formatCNPJ(formData.cnpj_cpfcli)}
            />
          </div>

          <div className='col-6 mt-2'>
            <small className="text-secondary">{ie}</small>
            <input type="text"
              name="insc_rgcli"
              className="form-control"
              onChange={handleInputChange}
              value={formData.insc_rgcli}
            />
          </div>

        </div>

        <div className="row">
          <div className='col-2 mt-2'>
            <small className="text-secondary">DDD</small>
            <input
              type="text"
              name="dddcli"
              className="form-control"
              onChange={handleInputChange}
              value={formData.dddcli}
            />
          </div>

          <div className='col-4 mt-2'>
            <small className="text-secondary">Fone</small>
            <input type="text"
              name="fonecli"
              className="form-control"
              onChange={handleTelefoneChange}
              value={formatTelefone(formData.fonecli)}
            />
          </div>

          <div className='col-6 mt-2'>
            <small className="text-secondary">{fundacao}</small>
            <input type="date"
              name="nasctocli"
              className="form-control"
              onChange={handleInputChange}
              value={formatDate(formData.nasctocli)}
            />
          </div>

        </div>

        <div className="row">

          <div className='col-9 mt-2'>
            <small className="text-secondary">Endereço</small>
            <input
              type="text"
              name="endcli"
              className="form-control"
              onChange={handleInputChange}
              value={formData.endcli}
            />

          </div>


          <div className='col-3 mt-2'>
            <small className="text-secondary">Número</small>
            <input type="text"
              name="numcli"
              className="form-control"
              onChange={handleInputChange}
              value={formData.numcli}
            />
          </div>

        </div>

        <div className='row'>
          <div className='col-12 mt-2'>
            <small className="text-secondary">Complemento</small>
            <input
              type="text"
              name="compcli"
              className="form-control"
              onChange={handleInputChange}
              value={formData.compcli}
            />
          </div>
        </div>

        <div className="row">

          <div className="col-3 mt-2">
            <small className="text-secondary">CEP</small>
            <input
              className="form-control"
              type="text"
              name="cepcli"
              value={formatCEP(formData.cepcli)}
              onChange={handleCepChange}
              onBlur={handleBlurCep}
            />
          </div>

          <div className='col-2 mt-2'>
            <small className="text-secondary">UF</small>
            <select
              name="estcli"
              className="form-control"
              onChange={handleSelectChange}
              value={formData.estcli}
            >
              <option value={formatUF(formData.estcli)}>{formData.estcli}</option>
              {ufs.map((item) => (
                item.UF.trim() !== formData.estcli.trim() && (
                  <option key={item.UF}
                    value={formatUF(item.UF)}>
                    {formatUF(item.UF)}
                  </option>)
              ))}
            </select>

          </div>

          <div className='col-7 mt-2'>
            <small className="text-secondary">Cidade</small>
            <select
              name="cidcli"
              className="form-control"
              value={formData.cidcli}
              onChange={handleSelectCidChange}
            >
              <option value={formData.cidcli}>{formData.cidcli}</option>
              {cidades.map((item) => (
                item.decid.trim() !== formData.cidcli.trim() && (
                  <option key={item.decid} value={item.decid}>
                    {item.decid}
                  </option>
                )
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className='col-7 mt-2'>
            <small className="text-secondary">Bairro</small>
            <select
              name="baicli"
              className="form-control"
              value={formData.baicli}
              onChange={handleSelectBaiChange} // Atualize o valor do bairro quando selecionado manualmente
            >
              <option value={formData.baicli}>{formData.baicli}</option>
              {bairros.map((item) => (
                item.debai.trim() !== formData.baicli.trim() && (
                  <option key={item.debai} value={item.debai}>
                    {item.debai}
                  </option>
                )
              ))}
            </select>
          </div>

          <div className='col-5 mt-2'>
            <small className="text-secondary">Contábil</small>
            <input type="text"
              name="contabil"
              className="form-control"
              onChange={handleInputChange}
              value={formData.contabil}
            />
          </div>

        </div>

        <div className='row'>
          <div className='col-9'>
          </div>

          <div className="col-3 mt-2">
            <small className="text-secondary">{titulo}</small>
            <div className="form-control mb-2">
              <select
                name="ativocli"
                onChange={handleInputChange}
                value={formData.ativocli}
              >
                <option value="s">Sim</option>
                <option value="n">Não</option>
              </select>
            </div>
          </div>

        </div>


      </Tab>

      {/* Aba 2 - Contatos */}
      <Tab eventKey="Contatos" title="Contatos">
        {
          titulo !== 'Sindicalizado' &&

          <h6 className="text">Contato</h6>
        }
        {
          titulo !== 'Sindicalizado' &&

          <div className='row'>
            <div className="col-7 mt-0">
              <small className="text-secondary">Nome</small>
              <input type="text"
                name="contato"
                className="form-control"
                onChange={handleInputChange}
                value={formData.contato}
              />
            </div>

            <div className="col-5 mt-0">
              <small className="text-secondary">Cargo</small>
              <div className="form-control mb-2">
                <select
                  name="cargo"
                  onChange={handleInputChange}
                  value={formData.cargo}
                >
                  <option value="Proprietário">Proprietário</option>
                  <option value="Comprador">Comprador</option>
                  <option value="Outro">Outro</option>
                </select>
              </div>
            </div>
          </div>

        }

        {
          titulo !== 'Sindicalizado' &&

          <div className="row">
            <div className='col-2 mt-0'>
              <small className="text-secondary">DDD</small>
              <input type="text"
                name="dddcont"
                className="form-control"
                onChange={handleInputChange}
                value={formData.dddcont}
              />
            </div>
            <div className='col-4 mt-0'>
              <small className="text-secondary">Fone</small>
              <input type="text"
                name="fonerespcli"
                className="form-control"
                onChange={handlefoneContatoChange}
                value={formatTelefone(formData.fonerespcli)}

              />
            </div>

            <div className='col-6 mt-0'>
              <small className="text-secondary">Nascimento</small>
              <input type="date"
                name="nascimento"
                className="form-control"
                onChange={handleInputChange}
                value={formatDate(formData.nasctocli)}
              />
            </div>

          </div>

        }

        <div className="row">
          <h6 className="text mt-4">internet</h6>
          <div className='col-6 mt-0'>
            <small className="text-secondary">email</small>
            <input type="text"
              name="emailcli"
              className="form-control"
              onChange={handleInputChange}
              value={formData.emailcli}
            />
          </div>


          <div className='col-6 mt-0'>
            <small className="text-secondary">Site</small>
            <input type="text"
              name="sitecli"
              className="form-control"
              onChange={handleInputChange}
              value={formData.sitecli}
            />
          </div>

        </div>

        <div className="row">
          <h6 className="text mt-4">Outro Endereço</h6>

          <div className="row">
            <div className='col-9 mt-0'>
              <small className="text-secondary">Endereço</small>
              <input type="text"
                name="endent"
                className="form-control"
                onChange={handleInputChange}
                value={formData.endent}
              />
            </div>

          </div>

          <div className="row">
            <div className='col-6 mt-2'>
              <small className="text-secondary">Bairro</small>
              <input type="text"
                name="baient"
                className="form-control"
                onChange={handleInputChange}
                value={formData.baient}
              />
            </div>


            <div className='col-6 mt-2'>
              <small className="text-secondary">Cidade</small>
              <input type="text"
                name="cident"
                className="form-control"
                onChange={handleInputChange}
                value={formData.cident}
              />
            </div>

          </div>

          <div className="row">
            <div className='col-2 mt-2'>
              <small className="text-secondary">UF</small>
              <input type="text"
                name="estent"
                className="form-control"
                onChange={handleInputChange}
                value={formData.estent}
              />
            </div>


            <div className='col-4 mt-2'>
              <small className="text-secondary">CEP</small>
              <input type="text"
                name="cepent"
                className="form-control"
                onChange={handleInputChange}
                value={formData.cepent}
              />
            </div>

          </div>

          <div className='col-12 mt-2'>
            <small className="text-secondary">Complemento</small>
            <input type="text"
              name="coment"
              className="form-control"
              onChange={handleInputChange}
              value={formData.coment}
            />
          </div>

        </div>


        <br />


      </Tab>

      {/* Aba 3 - Outras */}
      <Tab eventKey="contato" title="Outras">
        < InFoClienteAssocicao
          Socio={Socio}
          onSocioChange={onSocioChange}
        />
        <br />
      </Tab>

      {/* Aba 4 - Fotografia */}
      <Tab eventKey="Fotografia" title="Fotografia">
        <div className="row">
          <div className="col-4 mt-10"></div>
          <div className="col-4 mt-10">
            <input type="file" accept="image/*" onChange={handleFileChange} />
            <img
              src={formData.fotocli ? formData.fotocli : 'https://cdn-icons-png.flaticon.com/512/3135/3135768.png'}
              onImageChange={(file) => {
                setSelectedFile(file);
                const updatedFormData = { ...formData, fotocli: URL.createObjectURL(file) };
                onDataChange(updatedFormData);
              }}
              className="img-fluid img-thumbnail"
              alt="Imagem"
              style={{ maxWidth: '200px', maxHeight: '200px' }}
            />
            <button onClick={handleUploadButtonClick}>Enviar Imagem</button>

            {/* Exibir mensagem de sucesso ou erro */}
            {uploadMessage && (
              <div className={`alert ${isUploadSuccess ? 'alert-success' : 'alert-danger'}`} role="alert">
                {uploadMessage}
              </div>
            )}
          </div>
        </div>
      </Tab>


    </Tabs>

  );
}

export default ClienteInfoTabs;
