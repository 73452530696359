import React, { useEffect, useState } from "react";
import api from "../../services/api";
import InputMask from "react-input-mask";

function Filtrossindicalizados({ filtros, onDataChange, iniciarRequisicao, initialState, qtd_reg_pagina }) {

    // Use os valores dos filtros como estados iniciais para cada campo
    const [unidade, setUnidade] = useState(filtros.unidade);
    const [curso, setCurso] = useState(filtros.curso);
    const [situacao, setSituacao] = useState(filtros.situacao);
    const [aniversariantes, setAniversariantes] = useState(filtros.aniversariantes);
    const [inifiliacao, setInicioFiliacao] = useState(filtros.iniFiliacao);
    const [fimfiliacao, setFimFiliacao] = useState(filtros.fimfiliacao);
    const [tipocontribuicao, setTipoContribuicao] = useState(filtros.tipocontribuicao);
    const [cpf, setCpf] = useState(filtros.cpf);
    const [departamento, setDepartamento] = useState(filtros.departamento);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onDataChange({ [name]: value });
    };

    const handlePesquisarClick = () => {
        // Aqui você pode criar um objeto com os filtros a serem usados na requisição
        // const filtros = {
        //     unidade,
        //     curso,
        //     situacao,
        //     aniversariantes,
        //     iniciofiliacao,
        //     fimfiliacao,
        //     tipocontribuicao,
        // };

        // Chame a função de iniciar a requisição e passe os filtros
        iniciarRequisicao(
            localStorage.getItem("user"),
            "Todos",
            '', 1, qtd_reg_pagina, filtros);
    };

    const handleCursoChange = (event) => {
        const newCurso = event.target.value;
        setCurso(newCurso);
        onDataChange({ curso: newCurso });
    };

    const handleDepartamentoChange = (event) => {
        const newDepartamento = event.target.value;
        setDepartamento(newDepartamento);
        onDataChange({ departamento: newDepartamento });
    };

    const handleCpfChange = (event) => {
        const newCpf = event.target.value;
        setCpf(newCpf);
        onDataChange({ cpf: newCpf });
    };

    const handleSituacaoChange = (event) => {
        const newSituacao = event.target.value;
        setSituacao(newSituacao);
        onDataChange({ situacao: newSituacao });
        //updateFiltros({ ...filtros, situacao: newSituacao });
    };

    const handleUnidadeChange = (event) => {
        const newUnidade = event.target.value;
        setUnidade(newUnidade);
        onDataChange({ unidade: newUnidade });
        //updateFiltros({ ...filtros, unidade: newUnidade });
    };

    const handleAniversariantesChange = (event) => {
        const newAniversariantes = event.target.value;
        setAniversariantes(newAniversariantes);
        onDataChange({ aniversariantes: newAniversariantes });

        //updateFiltros({ ...filtros, aniversariantes: newAniversariantes });
    };

    const handleTipoContribuicaoChange = (event) => {
        const newTipoContribuicao = event.target.value;
        setTipoContribuicao(newTipoContribuicao);
        onDataChange({ tipocontribuicao: newTipoContribuicao });
        //updateFiltros({ ...filtros, tipoContribuicao: newTipoContribuicao });
    };


    // Defina a função handleInicioFiliacaoChange corretamente
    const handleInicioFiliacaoChange = (event) => {
        const newIniFiliacao = event.target.value;
        setInicioFiliacao(newIniFiliacao);
        onDataChange({ inifiliacao: newIniFiliacao });
        //updateFiltros({ ...filtros, iniciofiliacao: newInicioFiliacao });
    };

    // Defina a função handleInicioFiliacaoChange corretamente
    const handleFimFiliacaoChange = (event) => {
        const newFimFiliacao = event.target.value;
        setFimFiliacao(newFimFiliacao);
        onDataChange({ fimfiliacao: newFimFiliacao });
        //updateFiltros({ ...filtros, fimfiliacao: newFimFiliacao });
    };


    const [unidades, setUnidades] = useState([]);
    const [cursos, setCursos] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);

    function ListarUnidades(user, outrosFiltros) {
        const params = new URLSearchParams({
            user,
            ...outrosFiltros,
        });

        return api.get(`/unidades/?${params}`)
            .then((response) => response.data || [])
            .catch((error) => {
                console.error("Erro ao carregar unidades:", error);
                return [];
            });
    };


    function ListarCursos(user, outrosFiltros) {
        const params = new URLSearchParams({
            user,
            ...outrosFiltros,
        });

        return api.get(`/cursos/?${params}`)
            .then((response) => response.data || [])
            .catch((error) => {
                console.error("Erro ao carregar cursos:", error);
                return [];
            });
    }


    function ListarDepartamentos(user, outrosFiltros) {
        const params = new URLSearchParams({
            user,
            ...outrosFiltros,
        });

        return api.get(`/departamentos/?${params}`)
            .then((response) => response.data || [])
            .catch((error) => {
                console.error("Erro ao carregar departamentos:", error);
                return [];
            });
    }


    useEffect(() => {
        async function fetchUnidades() {
            try {
                const unidadesData = await ListarUnidades(localStorage.getItem("user"), {
                    gmt: '-04:00',
                    // Outros filtros aqui, se necessário
                });
                setUnidades(unidadesData);
            } catch (error) {
                console.error("Erro ao carregar unidades:", error);
                setUnidades([]);
            };
        }

        fetchUnidades();
    }, []);

    useEffect(() => {
        async function fetchCursos() {
            try {
                const cursosData = await ListarCursos(localStorage.getItem("user"), {
                    gmt: '-04:00',
                });
                setCursos(cursosData);
                //  console.log("Cursos:",cursosData);
            } catch (error) {
                console.error("Error ao carregar cursos:", error);
                setCursos([]);
            }

        }

        fetchCursos();
    }, []);

    useEffect(() => {
        async function fetchDepartamentos() {
            try {
                const departamentosData = await ListarDepartamentos(localStorage.getItem("user"), {
                    gmt: '-04:00',
                });
                setDepartamentos(departamentosData);
                //  console.log("Cursos:",cursosData);
            } catch (error) {
                console.error("Error ao carregar departamentos:", error);
                setDepartamentos([]);
            }

        }

        fetchDepartamentos();
    }, []);


    const formatCNPJ = (cnpj) => {
        if (cnpj) {
          if (cnpj.length <= 11) {
            return cnpj.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
          } else {
            return cnpj.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
          }
        }
      };

    return (<>
        <div className="row">
            <div className="col-xl-2 col-md-12 col-sm-12">
                <label>Unidade</label>
                <select name="unidade"
                    className="form-control"
                    value={unidade}
                    onChange={handleUnidadeChange}
                >
                    <option value=""></option>
                    {unidades.map((item) => (
                        item.unidade && (
                            <option key={item.unidade} value={item.unidade}>
                                {item.unidade}
                            </option>
                        )
                    ))}
                </select>
            </div>

            <div className="col-xl-3 col-md-4 col-sm-12">
                <label>Curso</label>
                <select name="curso"
                    className="form-control"
                    value={curso}
                    onChange={handleCursoChange}
                >
                    <option value=""></option>
                    {cursos.map((item) => (
                        item.curso && (
                            <option key={item.curso} value={item.curso}>
                                {item.curso}
                            </option>
                        )
                    ))}
                </select>
            </div>
            <div className="col-xl-3 col-md-4 col-sm-12">
                <label>Departamento</label>
                <select name="departamento"
                    className="form-control"
                    value={departamento}
                    onChange={handleDepartamentoChange}
                >
                    <option value=""></option>
                    {departamentos.map((item) => (
                        item.departamento && (
                            <option key={item.departamento} value={item.departamento}>
                                {item.departamento}
                            </option>
                        )
                    ))}
                </select>
            </div>
            <div className="col-2">
                <small className="text-secondary">CPF</small>
                <input
                    type="text"
                    name="cpf"
                    className="form-control"
                    onChange={handleCpfChange}
                    value={formatCNPJ(cpf)}
                />
            </div>
            <div className="col-xl-2 col-md-12 col-sm-12">
                <label>Situação</label>
                <select
                    name="situacao"
                    id="situacaoSelect"
                    className="form-control"
                    value={situacao}
                    onChange={handleSituacaoChange}
                >
                    <option value=""></option> {/* Opção padrão vazia */}
                    <option value="Ativo">Ativo</option>
                    <option value="Aposentado">Aposentado</option>
                </select>
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-xl-2 col-md-12 col-sm-12 mt-2">
                <label>Aniversários</label>
                <select
                    name="aniversariantes"
                    id="aniversariantesSelect"
                    className="form-control"
                    value={aniversariantes}
                    onChange={handleAniversariantesChange}
                >
                    <option value=""></option> {/* Opção padrão vazia */}
                    <option value="dodia">Do dia</option>
                    <option value="dasemana">Da semana</option>
                    <option value="domes">Do mês</option>
                </select>
            </div>

            <div className="col-xl-2 col-md-12 col-sm-12 mt-2">
                <small className="text-secondary">Filiação entre</small>
                <InputMask
                    mask="99/99/9999"
                    maskPlaceholder="dd/mm/yyyy"
                    name="inifiliacao"
                    className="form-control"
                    value={inifiliacao}
                    onChange={handleInicioFiliacaoChange}
                />
            </div>


            <div className="col-xl-2 col-md-12 col-sm-12 mt-2">
                <small className="text-secondary">e</small>
                <InputMask
                    mask="99/99/9999"
                    maskPlaceholder="dd/mm/yyyy"
                    name="fimfiliacao"
                    className="form-control"
                    value={fimfiliacao}
                    onChange={handleFimFiliacaoChange}
                />
            </div>

            <div className="col-xl-4 col-md-12 col-sm-12 mt-2">
                <small className="text-secondary">Contribuição por</small>
                <div className="form-control mb-0">
                    <select name="tipocontribuicao"
                        id="tipocli"
                        value={tipocontribuicao}
                        onChange={handleTipoContribuicaoChange}

                    >
                        <option value=""></option> {/* Opção padrão vazia */}
                        <option >Desconto em folha</option>
                        <option >Boleto bancário</option>
                        <option >Débito em conta</option>
                        <option >PIX</option>
                    </select>
                </div>
            </div>

            <div className="col-xl-2 col-md-12 col-sm-12 mt-2 text-end">
                <br />
                <button className="btn btn-success" onClick={handlePesquisarClick}><i className="bi bi-search me-2"></i>Pesquisar</button>
            </div>

        </div>

    </>
    );
}

export default Filtrossindicalizados;
