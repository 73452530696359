import React from 'react';

function EmpresaConfiguracoes({ dadosEmpresa, onEmpresaChange }) {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onEmpresaChange({ [name]: value });
    };


    return <>
        <div className='row'>
            <h6 className="text">Dados Fiscais</h6>
            <div className='row'>
                <div className="col-xl-2 col-md-6 col-sm-6 mt-1">
                    <small className="text-secondary">Última NFe</small>
                    <input type="text"
                        name="ultNFemp"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.ultNFemp}
                    />
                </div>
                <div className="col-xl-2 col-md-3 col-sm-3 mt-1">
                    <small className="text-secondary">Série</small>
                    <input type="text"
                        name="serienfe"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.serienfe}
                    />
                </div>

                <div className="col-xl-2 col-md-3 col-sm-3 mt-1">
                    <small className="text-secondary">Modelo</small>
                    <input type="text"
                        name="modelo"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.modelo}
                    />
                </div>

                <div className="col-xl-2 col-md-6 col-sm-6 mt-1">
                    <small className="text-secondary">Última NFCe</small>
                    <input type="text"
                        name="ULTNFC"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.ULTNFC}
                    />
                </div>
                <div className="col-xl-2 col-md-3 col-sm-3 mt-1">
                    <small className="text-secondary">Série</small>
                    <input type="text"
                        name="SerieNFc"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.SerieNFc}
                    />
                </div>

                <div className="col-xl-2 col-md-3 col-sm-3 mt-1">
                    <small className="text-secondary">Modelo</small>
                    <input type="text"
                        name="ModeloNFc"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.ModeloNFc}
                    />
                </div>

            </div>

            <div className="row">

                <div className="col-xl-3 col-md-8 col-sm-9 mt-1">
                    <small className="text-secondary">CSC</small>
                    <input type="text"
                        name="NrToken"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.NrToken}
                    />
                </div>

                <div className="col-xl-2 col-md-4 col-sm-3 mt-1">
                    <small className="text-secondary">ID</small>
                    <input type="text"
                        name="IDCSC"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.IDCSC}
                    />
                </div>

                <div className="col-xl-7 col-md-12 col-sm-12 mt-1">
                    <small className="text-secondary">Mensagem na NFe</small>
                    <input type="text"
                        name="MsnFixaNF"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.MsnFixaNF}
                    />
                </div>

            </div>

            <div className="row">
                <div className="col-xl-2 col-md-6 col-sm-6 mt-1">
                    <small className="text-secondary">Última NFSe</small>
                    <input type="text"
                        name="UltNFSv"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.UltNFSv}
                    />
                </div>
                <div className="col-xl-2 col-md-3 col-sm-3 mt-1">
                    <small className="text-secondary">Série</small>
                    <input type="text"
                        name="SerNFSv"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.SerNFSv}
                    />
                </div>

                <div className="col-xl-2 col-md-3 col-sm-3 mt-1">
                    <small className="text-secondary">Modelo</small>
                    <input type="text"
                        name="ModNFSv"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.ModNFSv}
                    />
                </div>

                <div className="col-xl-6 col-md-12 col-sm-12 mt-1">
                    <small className="text-secondary">Provedor NFSe</small>
                    <input type="text"
                        name="PathDoProvedor"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.PathDoProvedor}
                    />
                </div>
            </div>

            <div className="row">

                <div className="col-xl-3 col-md-6 col-sm-12 mt-1">
                    <small className="text-secondary">User web</small>
                    <input type="text"
                        name="UserWeb"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.UserWeb}
                    />
                </div>

                <div className="col-xl-2 col-md-6 col-sm-12 mt-1">
                    <small className="text-secondary">Senha web</small>
                    <input type="password"
                        name="SenhaWeb"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.SenhaWeb}
                    />
                </div>

                <div className="col-xl-7 col-md-12 col-sm-12 mt-1">
                    <small className="text-secondary">Frase secreta</small>
                    <input type="text"
                        name="FraseSecWeb"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.FraseSecWeb}
                    />
                </div>

            </div>

            <div className="row">
                <h6 className="text mt-4">Certificado</h6>
                <div className="col-xl-3 col-md-4 col-sm-12 mt-1">
                    <small className="text-secondary">Série</small>
                    <input type="text"
                        name="certificado"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.certificado}
                    />
                </div>

                <div className="col-xl-6 col-md-6 col-sm-12 mt-1">
                    <small className="text-secondary">Caminho</small>
                    <input type="text"
                        name="CaminhoDoCertificado"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.CaminhoDoCertificado}
                    />
                </div>

                <div className="col-xl-3 col-md-2 col-sm-12 mt-1">
                    <small className="text-secondary">Senha</small>
                    <input type="password"
                        name="SenhaDoCertificado"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.SenhaDoCertificado}
                    />
                </div>

            </div>

            <div className="row">
                <h6 className="text mt-4">Outras</h6>
                <div className="col-xl-5 col-md-6 col-sm-12 mt-1">
                    <small className="text-secondary">Prefeitura</small>
                    <input type="text"
                        name="Prefeitura"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.Prefeitura}
                    />
                </div>

                <div className="col-xl-3 col-md-6 col-sm-12 mt-1">
                    <small className="text-secondary">CNPJ</small>
                    <input type="text"
                        name="CNPJPrefeitura"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.CNPJPrefeitura}
                    />
                </div>

                <div className="col-xl-4 col-md-6 col-sm-12 mt-1">
                    <small className="text-secondary">Chave de acesso</small>
                    <input type="text"
                        name="ChaveAcessoWeb"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.ChaveAcessoWeb}
                    />
                </div>

                <div className="col-xl-8 col-md-6 col-sm-12 mt-1">
                    <small className="text-secondary">Chave de autorização</small>
                    <input type="text"
                        name="ChaveAutorizWeb"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.ChaveAutorizWeb}
                    />
                </div>

                <div className="col-xl-2 col-md-4 col-sm-4 mt-1">
                    <small className="text-secondary">ISS</small>
                    <input type="text"
                        name="AliqISS"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.AliqISS}
                    />
                </div>
                <div className="col-xl-2 col-md-4 col-sm-4 mt-1">
                    <small className="text-secondary">MVA ST</small>
                    <input type="text"
                        name="persubstrib"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.persubstrib}
                    />
                </div>

            </div>
            <div className="row mt-4" >
                <h6> Custos variáveis</h6>
                <div className="col-xl-2 col-md-4 col-sm-4 mt-1">
                    <small className="text-secondary">C.O.</small>
                    <input type="text"
                        name="custoper"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.custoper}
                    />
                </div>
                <div className="col-xl-2 col-md-4 col-sm-4 mt-1">
                    <small className="text-secondary">ICMS</small>
                    <input type="text"
                        name="AliqInterna"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.AliqInterna}
                    />
                </div>

                <div className="col-xl-2 col-md-4 col-sm-4 mt-1">
                    <small className="text-secondary">Trib.Fed.</small>
                    <input type="text"
                        name="ImpstVen"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.ImpstVen}
                    />
                </div>


                <div className="col-xl-6 col-md-12 col-sm-12 mt-1">
                    <small className="text-secondary">Contábil</small>
                    <input type="text"
                        name="contabil"
                        className="form-control"
                        onChange={handleInputChange}
                        value={dadosEmpresa.contabil}
                    />
                </div>
            </div>

            <div className="row mt-4" >
                <div className="col-xl-6 col-md-6 col-sm-12">
                    <h6> Cobrança</h6>
                    <div className="row">
                        <div className="col-xl-4 col-md-4 col-sm-4 mt-1">
                            <small className="text-secondary">Juros</small>
                            <input type="text"
                                name="JurDia"
                                className="form-control"
                                onChange={handleInputChange}
                                value={dadosEmpresa.JurDia}
                            />
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-4 mt-1">
                            <small className="text-secondary">Multa</small>
                            <input type="text"
                                name="MulApv"
                                className="form-control"
                                onChange={handleInputChange}
                                value={dadosEmpresa.MulApv}
                            />
                        </div>

                        <div className="col-xl-4 col-md-4 col-sm-4 mt-1">
                            <small className="text-secondary">D.Prot</small>
                            <input type="text"
                                name="diasprotesto"
                                className="form-control"
                                onChange={handleInputChange}
                                value={dadosEmpresa.diasprotesto}
                            />
                        </div>
                    </div>

                </div>
            </div>

            <div className="row mt-4">
                <div className="col-xl-12 col-md-12 col-sm-12">
                    <h6> Regime Fiscal</h6>
                    <div className="row">
                        <div className="col-xl-6 col-md-6 col-sm-12 mt-1">
                            <small className="text-secondary">Tipo</small>
                            <div className="form-control mb-2">
                                <select
                                    name="RegFisc"
                                    onChange={handleInputChange}
                                    value={
                                        dadosEmpresa.RegFisc === 'SN' ? 'SN' :
                                            dadosEmpresa.RegFisc === 'LP' ? 'LP' : 'LR'
                                    }
                                >
                                    <option value="SN">Simples Nacional</option>
                                    <option value="LP">Lucro Presumido</option>
                                    <option value="LR">Lucro Real</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-12 mt-1">
                            <small className="text-secondary">CRT</small>
                            <div className="form-control mb-2">
                                <select
                                    name="CRT"
                                    onChange={handleInputChange}
                                    value={
                                        dadosEmpresa.CRT === 'crtSimplesNacional' ? 'crtSimplesNacional' :
                                            dadosEmpresa.CRT === 'crtSimplesExcessoReceita' ? 'crtSimplesExcessoReceita' :
                                                'crtRegimeNormal'
                                    }
                                >
                                    <option value="crtSimplesNacional">Simples Nacional</option>
                                    <option value="crtSimplesExcessoReceita">Simples Excesso Receita</option>
                                    <option value="crtRegimeNormal">Regime Normal</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-12 mt-1">
                            <small className="text-secondary">Regime ISS</small>
                            <div className="form-control mb-2">
                                <select
                                    name="RegEspTr"
                                    onChange={handleInputChange}
                                    value={dadosEmpresa.RegEspTr === '01' ? '01' :
                                        dadosEmpresa.RegEspTr === '02' ? '02' :
                                            dadosEmpresa.RegEspTr === '03' ? '03' :
                                                dadosEmpresa.RegEspTr === '04' ? '04' :
                                                    dadosEmpresa.RegEspTr === '05' ? '05' :
                                                        dadosEmpresa.RegEspTr === '06' ? '06' :
                                                            dadosEmpresa.RegEspTr === '07' ? '07' :
                                                                dadosEmpresa.RegEspTr === '08' ? '08' :
                                                                    dadosEmpresa.RegEspTr === '09' ? '09' :
                                                                        dadosEmpresa.RegEspTr === '10' ? '10' :
                                                                            dadosEmpresa.RegEspTr === '11' ? '11' : '12'
                                    }
                                >
                                    <option value="01">Microempresa Municipal</option>
                                    <option value="02">Estimativa</option>
                                    <option value="03">Sociedade de Profissionais</option>
                                    <option value="04">Cooperativa</option>
                                    <option value="05">Microempresário Individual (MEI)</option>
                                    <option value="06">MicroEmp. e Emp. Pequeno Porte (ME EPP)</option>
                                    <option value="07">Tributação por Faturamento (Variavel)</option>
                                    <option value="08">Fixo</option>
                                    <option value="09">Isenção</option>
                                    <option value="10">Imune</option>
                                    <option value="11">Suspensa por Decisão Judicial</option>
                                    <option value="12">Suspensa por Proced. Administratico</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default EmpresaConfiguracoes;