import React, { useState } from "react"; // Importe useState
import './busca.css';

function Busca(props) {
    const [buscaTexto, setBuscaTexto] = useState(""); // Defina o estado do buscaTexto

    const handleInputChange = (event) => {
        setBuscaTexto(event.target.value);
    };

    const handleSearch = () => {
        props.onSearch(buscaTexto); // Chame a função onSearch passando o texto de busca
    };

    return (
        <div className="input-group mb-3">
            <div className="search-group">
                <input
                    type="text"
                    className="form-control busca"
                    placeholder={props.texto}
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    value={buscaTexto}
                    onChange={handleInputChange}

                />
                <button
                    className="btn btn-outline-primary"
                    type="button"
                    id="button-addon2"
                    onClick={handleSearch}
                >
                    Buscar
                </button>
            </div>

            <button className="btn btn-ligth mr-5" onClick={props.onToggleMenu}>
                <i className="fa-solid fa-bars"></i>
            </button>
        </div>
    );
}

export default Busca;
