import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../logos/adua.png";

function Menu(props) {
    const linkBlue = "nav-link align-middle px-0";
    const linkBlack = "nav-link align-middle px-0 link-secondary";
    const [tipoemp, setTipoemp] = useState(); 
    const [titulo, setTitulo] = useState();

    // Estado para controlar a visibilidade do sub-menu
    const [subMenuCadastrosVisible, setSubMenuCadastrosVisible] = useState(false);

    // Função para alternar a visibilidade do sub-menu ao clicar no item "Cadastros"
    const toggleSubMenuCadastros = () => {
        setSubMenuCadastrosVisible(!subMenuCadastrosVisible);
    };

   
    // Estado para controlar a visibilidade do sub-menu
    const [subMenuFinanceiroVisible, setSubMenuFinanceiroVisible] = useState(false);

    // Função para alternar a visibilidade do sub-menu ao clicar no item "Cadastros"
    const toggleSubMenuFinanceiro = () => {
        setSubMenuFinanceiroVisible(!subMenuFinanceiroVisible);
    };

    // Estado para controlar a visibilidade do sub-menu
    const [subMenuContasPagarVisible, setSubMenuContasPagarVisible] = useState(false);

    // Função para alternar a visibilidade do sub-menu ao clicar no item "Cadastros"
    const toggleSubMenuContasPagar = () => {
        setSubMenuContasPagarVisible(!subMenuContasPagarVisible);
    };

    // Estado para controlar a visibilidade do sub-menu
    const [subMenuContasReceberVisible, setSubMenuContasReceberVisible] = useState(false);

    // Função para alternar a visibilidade do sub-menu ao clicar no item "Cadastros"
    const toggleSubMenuContasReceber = () => {
        setSubMenuContasReceberVisible(!subMenuContasReceberVisible);
    };

    // Estado para controlar a visibilidade do sub-menu
    const [subMenuLivroCaixaVisible, setSubMenuLivroCaixaVisible] = useState(false);

    // Função para alternar a visibilidade do sub-menu ao clicar no item "Cadastros"
    const toggleSubMenuLivroCaixa = () => {
        setSubMenuLivroCaixaVisible(!subMenuLivroCaixaVisible);
    };

    const handleLogout = () => {
        // Remove o token do localStorage
        localStorage.removeItem('token');
    
        // Redireciona para a página de login
        window.location.href = "/";
    };
    
    useEffect(() => {
        setTipoemp(localStorage.getItem("tipoemp"));
    }, []);
    

    useEffect(() => {
        if (tipoemp && typeof tipoemp === "string") {
            const firstTwoDigits = tipoemp.slice(0, 2);
            
            if (firstTwoDigits === '10') {
                setTitulo("Sindicalizados");
            } else {
                setTitulo("Clientes");
            }
        }
    }, [tipoemp]);
    

    return (
        <>
            <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100"
            >
                <a href="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span className="fs-5 d-none d-sm-inline">
                        <img src={logo} className="img-logo" alt="Logo" style={{ maxWidth: '120px', maxHeight: '80px' }} />
                    </span>
                </a>
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">

                    <li className="nav-item">
                        <Link to='/dashboard'
                            className={props.page === "dashboard" ? linkBlue : linkBlack}
                        >
                            <i className="fs-4 bi bi-bar-chart"></i> <span className="ms-1 d-none d-sm-inline">Dashboard</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        {/* Adiciona um evento de clique para alternar a visibilidade do sub-menu */}
                        <Link to='#'
                            className={props.page === "cadastros" ? linkBlue : linkBlack}
                            onClick={toggleSubMenuCadastros}>
                            <i className="fs-4 bi bi-person-plus"></i> <span className="ms-1 d-none d-sm-inline">Cadastros</span>
                        </Link>

                        {/* Renderiza o sub-menu apenas se o estado subMenuVisible for true */}
                        {subMenuCadastrosVisible && (
                            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start">

                                <li className="nav-item">
                                    <Link to='/cadastros/empresas'
                                        className={props.page === "empresas" ? linkBlue : linkBlack}
                                    >
                                        <span className="ms-4 d-none d-sm-inline">Empresas</span>
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to='/cadastros/clientes'
                                        className={props.page === "clientes" ? linkBlue : linkBlack}
                                    >
                                        <span className="ms-4 d-none d-sm-inline">{titulo}</span>
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to='/cadastros/fornecedores' className={props.page === "fornecedores" ? linkBlue : linkBlack}>
                                        <span className="ms-4 d-none d-sm-inline">Fornecedores</span>
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to='/cadastros/planodecontas' className={props.page === "planodecontas" ? linkBlue : linkBlack}>
                                        <span className="ms-4 d-none d-sm-inline">Plano de Contas</span>
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to='/cadastros/portadores' className={props.page === "portadores" ? linkBlue : linkBlack}>
                                        <span className="ms-4 d-none d-sm-inline">Portadores</span>
                                    </Link>
                                </li>

                            </ul>
                        )}
                    </li>

                    <li className="nav-item">
                        {/* Adiciona um evento de clique para alternar a visibilidade do sub-menu */}
                        <Link to='#' className={props.page === "financeiro" ? linkBlue : linkBlack} onClick={toggleSubMenuFinanceiro}>
                            <i className="fs-4 bi bi-cash-coin"></i> <span className="ms-1 d-none d-sm-inline">Financeiro</span>
                        </Link>

                        {/* Renderiza o sub-menu apenas se o estado subMenuVisible for true */}
                        {subMenuFinanceiroVisible && (
                            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start">

                                <li className="nav-item">

                                    <Link to='#' className={props.page === "contasapagar" ? linkBlue : linkBlack} onClick={toggleSubMenuContasPagar}>
                                        <i className="ms-4 d-none d-sm-inline"></i> <span className="ms-0 d-none d-sm-inline">Contas a Pagar</span>
                                    </Link>

                                    {subMenuContasPagarVisible && (
                                        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start">
                                            <li className="nav-item">
                                                <Link to='/financeiro/contasapagar/consulta' className={props.page === "contasapagar" ? linkBlue : linkBlack}>
                                                    <span className="ms-5 d-none d-sm-inline">Consultar</span>
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to='/financeiro/contasapagar/lancamento' className={props.page === "contasapagarlancamento" ? linkBlue : linkBlack}>
                                                    <span className="ms-5 d-none d-sm-inline">Lançamento</span>
                                                </Link>
                                            </li>

                                        </ul>
                                    )}

                                </li>

                                <li className="nav-item">

                                    <Link to='#' className={props.page === "contasareceber" ? linkBlue : linkBlack} onClick={toggleSubMenuContasReceber}>
                                        <i className="ms-4 d-none d-sm-inline"></i> <span className="ms-0 d-none d-sm-inline">Contas a Receber</span>
                                    </Link>

                                    {subMenuContasReceberVisible && (
                                        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start">
                                            <li className="nav-item">
                                                <Link to='/cadastros/contasareceber/consulta' className={props.page === "contasareceberconsulta" ? linkBlue : linkBlack}>
                                                    <span className="ms-5 d-none d-sm-inline">Consultar</span>
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to='/cadastros/contasareceber/lancamento' className={props.page === "contasareceberlancamento" ? linkBlue : linkBlack}>
                                                    <span className="ms-5 d-none d-sm-inline">Lançamento</span>
                                                </Link>
                                            </li>

                                        </ul>
                                    )}

                                </li>

                                <li className="nav-item">

                                    <Link to='#' className={props.page === "livrocaixa" ? linkBlue : linkBlack} onClick={toggleSubMenuLivroCaixa}>
                                        <i className="ms-4 d-none d-sm-inline"></i> <span className="ms-0 d-none d-sm-inline">Livro Caixa</span>
                                    </Link>

                                    {subMenuLivroCaixaVisible && (
                                        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start">
                                            <li className="nav-item">
                                                <Link to='/cadastros/livrocaixa/consulta' className={props.page === "livrocaixaconsulta" ? linkBlue : linkBlack}>
                                                    <span className="ms-5 d-none d-sm-inline">Consultar</span>
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to='/cadastros/livrocaixa/lancamento' className={props.page === "livrocaixalancamento" ? linkBlue : linkBlack}>
                                                    <span className="ms-5 d-none d-sm-inline">Lançamento</span>
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to='/cadastros/livrocaixa/balancete' className={props.page === "livrocaixabalancete" ? linkBlue : linkBlack}>
                                                    <span className="ms-5 d-none d-sm-inline">Balancete</span>
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to='/cadastros/livrocaixa/extrato' className={props.page === "livrocaixaextrato" ? linkBlue : linkBlack}>
                                                    <span className="ms-5 d-none d-sm-inline">Extrato</span>
                                                </Link>
                                            </li>

                                        </ul>
                                    )}

                                </li>


                            </ul>
                        )}
                    </li>

                    <li className="nav-item">
                        <a href="#" className={props.page === "config" ? linkBlue : linkBlack}>
                            <i className="fs-4 bi-gear"></i><span className="ms-1 d-none d-sm-inline">Configurações</span>

                        </a>
                    </li>

                    {/* <li className="nav-item">
                        <a href="/" className={props.page === "logout" ? linkBlue : linkBlack}>
                            <i className="fs-4 bi-box-arrow-left"></i><span className="ms-1 d-none d-sm-inline">Desconectar</span>
                        </a>
                    </li> */}

                    <li className="nav-item">
                        <button
                            onClick={handleLogout}
                            className={props.page === "logout" ? linkBlue : linkBlack}
                            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                        >
                            <i className="fs-4 bi-box-arrow-left"></i>
                            <span className="ms-1 d-none d-sm-inline">Desconectar</span>
                        </button>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default Menu;
