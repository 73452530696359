import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";

function Grafico(props) {
  const [chartType, setChartType] = useState(props.chartType); // Define o tipo de gráfico inicial

  const options = {
    legend: props.legenda ? {} : { position: "none" },
  };

  useEffect(() => {
    const tiposDeGrafico = ["Bar", "Line"]; // Array de tipos de gráfico

    const intervalId = setInterval(() => {
      const currentIndex = tiposDeGrafico.indexOf(chartType);
      const nextIndex = (currentIndex + 1) % tiposDeGrafico.length;
      setChartType(tiposDeGrafico[nextIndex]);
    }, 10000);

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente for desmontado
  }, [chartType]); // Somente chartType é necessário aqui

  return (
    <div className="card">
      <div className="card-header">
        {props.titulo}
      </div>
      <div className="card-body text-center">
        <Chart
          chartType={chartType}
          data={props.dados}
          width="100%"
          height="350px"
          options={options}
          legendToggle={true}
        />
      </div>
    </div>
  );
}

export default Grafico;
