// awsConfig.js

const AWS = require('aws-sdk');

AWS.config.update({
  accessKeyId: 'AKIAQKTON3YD5IFLCHG2',
  secretAccessKey: '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',
  region: 'us-west-2' 
});

const s3 = new AWS.S3();

export default s3;
