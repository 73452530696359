import { useState } from "react";
import CadastroModal from "../../components/modalcadastros/modalcadastros.jsx";
import FormularioDeAdesao from "../../pages/adesao/Formulariodeadesao.jsx";
import './adesao.css';

function AdesaoOpcoes() {

    const [opcaoescolhida, setOpcaoEscolhida] = useState("");

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };


    function Opcao(id) {
        setOpcaoEscolhida(id);
        openModal();
    }

    return <>
        <div className="container-fluid mx-auto w-90">
            <br />
            <br />

            <div className="row">
                <h2 className="title-card-conteiners">Planos de adesão</h2>
                <div className="col-xl-4 col-md-12 col-sm-12">
                    <div className="card mt-2">
                        <div className="card-body">
                            <div>
                                <h4 className="card-title-alt">Plano Pupunha</h4>
                                <h6 className="card-sub-title-alt">R$ 329,00 /mês</h6>
                                <ul>
                                    <li>Nota de Consumidor (NFC-e)</li>
                                    <li>Frente de Caixa (PDV)</li>
                                    <li>Relatorios de Fechamento de Caixa</li>
                                    <li>Relatorios de Ranking de Produtos</li>
                                    <li>Suporte técnico remoto</li>
                                    <li>Apenas 1 login de acesso</li>
                                </ul>
                            </div>
                            <button onClick={() => Opcao(1)} className="btn btn-primary">Confirmar</button>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-12 col-sm-12">
                    <div className="card mt-2">
                        <div className="card-body">
                            <div>
                                <h4 className="card-title-alt">Plano Tucumã</h4>
                                <h6 className="card-sub-title-alt">R$ 690,00 /mês</h6>
                                <ul>
                                    <li>Tudo do plano Pupunha</li>
                                    <li>Nota Fiscal Eletrônica (NF-e)</li>
                                    <li>Cadastro de Clientes</li>
                                    <li>Controle de Contas a Pagar/Receber</li>
                                    <li>Controle de Estoque</li>
                                    <li>Recebimento de Notas de Fornecedores</li>
                                    <li>Até 3 logins de acesso</li>
                                </ul>
                            </div>
                            <button onClick={() => Opcao(2)} className="btn btn-primary">Confirmar</button>
                        </div>
                    </div>

                </div>
                <div className="col-xl-4 col-md-12 col-sm-12">
                    <div className="card mt-2">
                        <div className="card-body">
                            <div>
                                <h4 className="card-title-alt">Plano Açaí</h4>
                                <h6 className="card-sub-title-alt">R$ 1290,00 /mês</h6>
                                <ul>
                                    <li>Tudo do plano Tucumã</li>
                                    <li>Formação de Preços</li>
                                    <li>Frente de Caixas Independentes (PDV)</li>
                                    <li>Emissão de boletos de cobranças</li>
                                    <li>Cobranças CNAB</li>
                                    <li>Livro Caixa Eletrônico</li>
                                    <li>Balance de Receitas e Despesas</li>
                                    <li>Integração contábil</li>
                                    <li>Relatório de DRE</li>
                                    <li>Integração com Marketplaces</li>
                                    <li>Página de Pedidos (Delivery)</li>
                                    <li>Até 5 logins de acesso</li>
                                </ul>
                            </div>

                            <button onClick={() => Opcao(3)} className="btn btn-primary">Confirmar</button>
                        </div>
                    </div>
                </div>
                <div>
                    <a>*valores reajustados a cada reajuste do salário mínimo</a>
                </div>

            </div>
        </div>


        {/* Renderiza o modal de cadastro aqui, mas apenas quando modalIsOpen for true */}
        {modalIsOpen && (
            <CadastroModal isOpen={modalIsOpen} onClose={closeModal} title='' maxWidth="100%">
                <FormularioDeAdesao plano={opcaoescolhida} onClose={closeModal} />
            </CadastroModal>
        )}
    </>
}

export default AdesaoOpcoes;