import React, { useEffect, useState, useCallback } from "react";
import api from "../../../services/api.js";
import './cadastrofornecedor.css';


function CadastroFornecedor({ operacao, codigo, onClose }) {
    const [cdfor, setcdfor] = useState("");
    const [ufs, setUFs] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [bairros, setBairros] = useState([]);
    const [planodecontas, setPlanoDeContas] = useState([]);
    const [planoContasSelecionado, setPlanoContasSelecionado] = useState(false);
    const [dadosfornecedor, setDadosfornecedor] = useState({
        cdfor: '',
        cdemp: 1,
        datacadfor: '',
        DtAltFor: '',
        codred: '',
        ativofor: '',
        baifor: '',
        cepfor: '',
        cidfor: '',
        cnpj_cpffor: '',
        compend: '',
        dddfor: '',
        defor: '',
        emailfor: '',
        endfor: '',
        estfor: '',
        fant: '',
        fonefor: '',
        insc_rgfor: '',
        inscmun: '',
        sitefor: '',
        contabil: '',
    }); // Novo estado para armazenar os dados

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        // Se o tipo for um checkbox, use o valor "checked" em vez do "value"
        const inputValue = type === 'checkbox' ? (checked ? 'S' : 'N') : value;

        setDadosfornecedor((prevState) => ({
            ...prevState,
            [name]: inputValue
        }));
    };


    const formatCNPJ = (cnpj) => {
        if (cnpj.length <= 11) {
            return cnpj.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else {
            return cnpj.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }

    };

    const handleCnpjChange = (e) => {
        const cnpjFormatted = e.target.value.replace(/[./-]/g, '');
        handleInputChange({
            target: {
                name: 'cnpj_cpffor',
                value: cnpjFormatted
            }
        });
    };

    const handlePlanoDeContasChange = (e) => {
        const selectedValue = e.target.value;

        if (selectedValue === "") {
            setPlanoContasSelecionado(false);
            handleInputChange({
                target: {
                    name: 'codred',
                    value: ""
                }
            });
        } else {
            setPlanoContasSelecionado(true);
            const formattedValue = selectedValue.substring(0, 3);
            handleInputChange({
                target: {
                    name: 'codred',
                    value: formattedValue
                }
            });
        }
    };



    const handleBlurCep = () => {
        const formattedCep = formatCEP(dadosfornecedor.cepfor);

        // Verifique se o CEP é válido antes de fazer a busca
        if (formattedCep.length === 9) {
            buscarEnderecoPorCep(dadosfornecedor.cepfor);
        }
    };

    async function buscarEnderecoPorCep(cep) {
        try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            const data = await response.json();

            if (data.erro) {
                console.log('CEP não encontrado.');
                return;
            }

            const endereco = data.logradouro;
            const complemento = data.complemento;
            const uf = data.uf;
            const cidade = data.localidade;
            const bairro = data.bairro;

            // Atualizar os campos do formulário
            if (endereco) handleInputChange({ target: { name: 'endfor', value: endereco } });
            if (complemento) handleInputChange({ target: { name: 'compend', value: complemento } });
            if (bairro) handleInputChange({ target: { name: 'baifor', value: bairro } });
            if (cidade) handleInputChange({ target: { name: 'cidfor', value: cidade } });
            if (uf) handleInputChange({ target: { name: 'estfor', value: uf } });

        } catch (error) {
            console.error('Erro ao buscar CEP:', error);
        }
    }

    const formatCEP = (cep) => {
        return cep.replace(/\D/g, '').replace(/(\d{5})(\d{3})/, '$1-$2');
    };

    const handleCepChange = (e) => {
        const formattedCep = e.target.value.replace(/[.\s-]/g, '');
        handleInputChange({
            target: {
                name: 'cepfor',
                value: formattedCep
            }
        });

    };

    const formatTelefone = (telefone) => {
        return telefone.replace(/\D/g, '').replace(/(\d{1})(\d{4})(\d{4})/, '$1 $2-$3');
    };

    const handleTelefoneChange = (e) => {
        const fonecliFormated = e.target.value.replace(/[\s-]/g, '')
        handleInputChange({
            target: {
                name: 'fonefor',
                value: fonecliFormated
            }
        });
    };

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value.trim();
        handleInputChange({
            target: {
                name: 'estfor',
                value: selectedValue
            }
        });
    };

    const handleSelectCidChange = (e) => {
        const selectedValue = e.target.value.trim();
        handleInputChange({
            target: {
                name: 'cidfor',
                value: selectedValue
            }
        });
    };

    const handleSelectBaiChange = (e) => {
        const selectedValue = e.target.value.trim();
        handleInputChange({
            target: {
                name: 'baifor',
                value: selectedValue
            }
        });
    };

    const handleCancelar = () => {
        onClose();
    };

    const handleSalvar = () => {
        
        if (!planoContasSelecionado) {
            alert("Por favor, selecione um plano de contas.");
            return;
        }

        const params = new URLSearchParams({
            user: localStorage.getItem("user"),
            gmt: '-04:00'
        });

        if (!dadosfornecedor.DTNascFOR) {
            delete dadosfornecedor.DTNascFOR
        }

        if (dadosfornecedor.fant !== null) {
            dadosfornecedor.fant = dadosfornecedor.fant.trim();
        } else {
            delete dadosfornecedor.fant;
        }

        if (dadosfornecedor.compend !== null) {
            dadosfornecedor.compend = dadosfornecedor.compend.trim();
        } else {
            delete dadosfornecedor.compend;
        }

        if (!dadosfornecedor.compend) {
            delete dadosfornecedor.compend
        }

        if (dadosfornecedor.insc_rgfor !== null) {
            dadosfornecedor.insc_rgfor = dadosfornecedor.insc_rgfor.trim();
        } else {
            delete dadosfornecedor.insc_rgfor;
        }

        if (!dadosfornecedor.insc_rgfor) {
            delete dadosfornecedor.insc_rgfor
        }

        if (dadosfornecedor.sitefor !== null) {
            dadosfornecedor.sitefor = dadosfornecedor.sitefor.trim();
        } else {
            delete dadosfornecedor.sitefor;
        }

        if (!dadosfornecedor.sitefor) {
            delete dadosfornecedor.sitefor
        }

        if (dadosfornecedor.emailfor !== null) {
            dadosfornecedor.emailfor = dadosfornecedor.emailfor.trim();
        } else {
            delete dadosfornecedor.emailfor;
        }

        if (!dadosfornecedor.emailfor) {
            delete dadosfornecedor.emailfor
        }
      
        if (dadosfornecedor) {
            let fornecedorParaSalvar = {
                ...dadosfornecedor,
                defor: dadosfornecedor.defor.trim(),
                baifor: dadosfornecedor.baifor.trim(),
                endfor: dadosfornecedor.endfor.trim(),
                cepfor: dadosfornecedor.cepfor.trim(),
                dddfor: dadosfornecedor.dddfor.trim(),
                fonefor: dadosfornecedor.fonefor.trim(),

                cnpj_cpffor: dadosfornecedor.cnpj_cpffor.trim(),
                
            };

            // Fazer a chamada REST usando os dados
            try {
                if (cdfor) {

                    
                    // Usar a requisição PUT para atualizar os dados do cliente
                    api.put(`/t_for/${cdfor}?${params}`, fornecedorParaSalvar)
                        .then((response) => {
                            // Tratar a resposta, se necessário
                            
                            window.alert('Registro alterado com sucesso!');
                        })
                        .catch((error) => {
                            
                            window.alert('Falha ao alterar o registro!');
                        })
                        .finally(() => {
                            onClose();
                        });
                } else {
                    delete fornecedorParaSalvar.cdfor;
                    // Usar a requisição POST para criar um novo cliente
                    api.post(`/t_for?${params}`, fornecedorParaSalvar)
                        .then((response) => {
                            // Tratar a resposta, se necessário
                            fornecedorParaSalvar.cdfor = response.data.cdfor;
                            window.alert('Registro Inserido com sucesso!');
                        })
                        .catch((error) => {
                            console.error('Erro ao salvar a nova do fornecedor:', error);
                            window.alert('Falha ao Inserir o registro');
                        })
                        .finally(() => {
                            onClose();
                        });
                }
            } catch (error) {
                console.error('Erro ao salvar todas as informações da fornecedor');
            }
        }
    };

    function ListarPlanoDeContas(user) {
        api.get("/t_placon", {
            params: {
                user: user,
                debcre: 'D',
                tipo: 'A'
            }
        })
            .then((resp) => {
                const modifiedData = {
                    total_registros: resp.data.count,
                    dados: resp.data.rows
                };
                setPlanoDeContas(modifiedData.dados);
            })
            .catch((err) => {
                setPlanoDeContas([]);
                alert('Falha ao carregar o plano de contas');
            });
    }



    function ListarBairros(user, cidade) {

        api.get("/t_bai", {
            params: {
                user,
                cidade
            }
        })
            .then((resp) => {
                const modifiedData = {
                    total_registros: resp.data.count,
                    dados: resp.data.rows
                };
                setBairros(modifiedData.dados);

            })
            .catch((err) => {

                setBairros([]);
                alert("Erro ao carregar bairros");
            });

    }

    function ListarUF() {

        api.get("/t_uf", {
            params: {
                user: localStorage.getItem("user")
            }
        })
            .then((resp) => {
                const modifiedData = {
                    total_registros: resp.data.count,
                    dados: resp.data.rows
                };
                setUFs(modifiedData.dados);
            })
            .catch((err) => {
                setUFs([]);
                alert("Erro ao carregar UF");
            });

    }

    function ListarCidades(user, uf) {

        api.get("/t_cid", {
            params: {
                user,
                uf
            }
        })
            .then((resp) => {
                const modifiedData = {
                    total_registros: resp.data.count,
                    dados: resp.data.rows
                };
                setCidades(modifiedData.dados);

            })
            .catch((err) => {
                setCidades([]);
                alert("Erro ao carregar cidades");
            });
    }

    function formatUF(uf) {
        return uf.toUpperCase();
    }

    useEffect(() => {
        ListarUF();
        ListarCidades(localStorage.getItem("user"), localStorage.getItem("estemp"));
        ListarBairros(localStorage.getItem("user"), localStorage.getItem("cidemp"));
        ListarPlanoDeContas(localStorage.getItem("user"));
    }, []);

    const handleNovo = () => {

        setDadosfornecedor({
            cdfor: '',
            cdemp: 1,
            datacadfor: '',
            DtAltFor: '',
            codred: '',
            ativofor: '',
            baifor: '',
            cepfor: '',
            cidfor: '',
            cnpj_cpffor: '',
            compend: '',
            dddfor: '',
            defor: '',
            emailfor: '',
            endfor: '',
            estfor: '',
            fant: '',
            fonefor: '',
            insc_rgfor: '',
            inscmun: '',
            sitefor: '',
            contabil: '',
        }); // Novo estado para armazenar os dados


    };

    // Requisição GET para buscar os dados da fornecedor pelo ID
    const getDadosfornecedor = useCallback(async (cdfor) => {

        api.get("/t_for/" + cdfor, {
            params: {
                user: localStorage.getItem("user")
            }
        })
            .then((resp) => {
                const Dados = resp.data;
                setDadosfornecedor(Dados);

                if (Dados.codred)
                    setPlanoContasSelecionado(true);
            })
            .catch((err) => {
                setDadosfornecedor();
                alert('Erro ao buscar dados da fornecedor: ' + err.message);
            });
    }, []);


    useEffect(() => {

        setcdfor(codigo);
        if (codigo) {
            getDadosfornecedor(codigo);
        }

        if (operacao === 'Incluir') {
            handleNovo();
        }
    }, [operacao, codigo, getDadosfornecedor]);

    return <>

        <div className="container-fluid h-100 pt-4 sidebar p-4">
            <h4 className="d-inline">
                {operacao === "Editar" ? dadosfornecedor.fant : "Novo fornecedor"}
            </h4>
            <div className="row tela-cad">
                <div className='row'>
                    <div className="col-xl-3 col-md-5 col-sm-5 mt-1">
                        <small className="text-secondary">Código</small>
                        <input type="text"
                            style={{ textAlign: 'right' }}
                            name="cdfor"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosfornecedor.cdfor}
                            disabled={true} />
                    </div>

                    <div className='col-xl-6 col-md-2 col-sm-2 mt-1'>

                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-5 col-md-12 col-sm-12 mt-1">
                        <small className="text-secondary">Razão Social</small>
                        <input
                            type="text"
                            name="defor"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosfornecedor.defor}
                        />
                    </div>


                    <div className="col-xl-7 col-md-12 col-sm-12 mt-1">
                        <small className="text-secondary">Nome Fantasia</small>
                        <input type="text"
                            name="fant"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosfornecedor.fant}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className='col-xl-4 col-md-6 col-sm-12 mt-1'>
                        <small className="text-secondary">CNPJ</small>
                        <input
                            type="text"
                            name="cnpj_cpffor"
                            className="form-control"
                            onChange={handleCnpjChange}
                            value={formatCNPJ(dadosfornecedor.cnpj_cpffor)}
                        />
                    </div>

                    <div className='col-xl-4 col-md-6 col-sm-12 mt-1'>
                        <small className="text-secondary">I.E</small>
                        <input type="text"
                            name="insc_rgfor"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosfornecedor.insc_rgfor}
                        />
                    </div>

                    <div className='col-xl-4 col-md-6 col-sm-12 mt-1'>
                        <small className="text-secondary">I.M</small>
                        <input type="text"
                            name="inscmun"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosfornecedor.inscmun}
                        />
                    </div>


                    <div className='col-xl-2 col-md-2 col-sm-4 mt-1 mt-1'>
                        <small className="text-secondary">DDD</small>
                        <input
                            type="text"
                            style={{ textAlign: 'right' }}
                            name="dddfor"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosfornecedor.dddfor}
                            maxLength={2}
                        />
                    </div>

                    <div className='col-xl-3 col-md-4 col-sm-8  mt-1'>
                        <small className="text-secondary">Fone</small>
                        <input type="text"
                            style={{ textAlign: 'right' }}
                            name="fonefor"
                            className="form-control"
                            onChange={handleTelefoneChange}
                            value={formatTelefone(dadosfornecedor.fonefor)}
                        />
                    </div>

                    <div className='col-xl-7 col-md-12 col-sm-12 mt-1'>
                        <small className="text-secondary">Email</small>
                        <input type="text"
                            name="emailfor"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosfornecedor.emailfor}
                        />
                    </div>


                    <div className='col-xl-9 col-md-8 col-sm-12 mt-1'>
                        <small className="text-secondary">Site</small>
                        <input type="text"
                            name="sitefor"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosfornecedor.sitefor}
                        />
                    </div>

                    <div className="col-xl-3 col-md-4 col-sm-12 mt-1">
                        <small className="text-secondary">CEP</small>
                        <input
                            className="form-control"
                            type="text"
                            style={{ textAlign: 'right' }}
                            name="cepfor"
                            value={formatCEP(dadosfornecedor.cepfor)}
                            onChange={handleCepChange}
                            onBlur={handleBlurCep}
                        />
                    </div>


                </div>

                <div className="row">

                    <div className='col-xl-6 col-md-12 col-sm-12 mt-1'>
                        <small className="text-secondary">Endereço</small>
                        <input
                            type="text"
                            name="endfor"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosfornecedor.endfor}
                        />

                    </div>


                    <div className='col-xl-6 col-md-12 col-sm-12 mt-1'>
                        <small className="text-secondary">Complemento</small>
                        <input
                            type="text"
                            name="compend"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosfornecedor.compend}
                        />
                    </div>

                </div>

                <div className="row">

                    <div className='col-xl-6 col-md-6 col-sm-12 mt-1'>
                        <small className="text-secondary">Bairro</small>
                        <select
                            name="baifor"
                            className="form-control"
                            value={dadosfornecedor.baifor}
                            onChange={handleSelectBaiChange} // Atualize o valor do bairro quando selecionado manualmente
                        >
                            <option value={dadosfornecedor.baifor}>{dadosfornecedor.baifor}</option>
                            {bairros.map((item) => (
                                item.debai.trim() !== dadosfornecedor.baifor.trim() && (
                                    <option key={item.debai} value={item.debai}>
                                        {item.debai}
                                    </option>
                                )
                            ))}
                        </select>
                    </div>

                    <div className='col-xl-4 col-md-4 col-sm-8 mt-1'>
                        <small className="text-secondary">Cidade</small>
                        <select
                            name="cidfor"
                            className="form-control"
                            value={dadosfornecedor.cidfor}
                            onChange={handleSelectCidChange}
                        >
                            <option value={dadosfornecedor.cidfor}>{dadosfornecedor.cidfor}</option>
                            {cidades.map((item) => (
                                item.decid.trim() !== dadosfornecedor.cidfor.trim() && (
                                    <option key={item.decid} value={item.decid}>
                                        {item.decid}
                                    </option>
                                )
                            ))}
                        </select>
                    </div>


                    <div className='col-xl-2 col-md-2 col-sm-4 mt-1'>
                        <small className="text-secondary">UF</small>
                        <select
                            name="Estemp"
                            className="form-control"
                            onChange={handleSelectChange}
                            value={dadosfornecedor.estfor}
                        >
                            <option value={formatUF(dadosfornecedor.estfor)}>{dadosfornecedor.estfor}</option>
                            {ufs.map((item) => (
                                item.UF.trim() !== dadosfornecedor.estfor.trim() && (
                                    <option key={item.UF}
                                        value={formatUF(item.UF)}>
                                        {formatUF(item.UF)}
                                    </option>)
                            ))}
                        </select>

                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-md-12 col-sm-12 mt-1">
                        <small className="text-secondary">Conta</small>
                        <select
                            name="codred"
                            className="form-control"
                            onChange={handlePlanoDeContasChange}
                            value={dadosfornecedor.codred}
                            required
                        >
                            <option value="">Selecione a conta</option>
                            {planodecontas.map((item) => (
                                item.debcre === 'D' && item.tipo === 'A' && (
                                    <option key={item.codred} value={item.codred}>
                                        {`${item.codred.toString().padStart(3, '0')} - ${item.decta}`}
                                    </option>
                                )
                            ))}
                        </select>
                    </div>

                    <div className='col-4 mt-2'>
                        <small className="text-secondary">Contábil</small>
                        <input type="text"
                            name="contabil"
                            className="form-control"
                            onChange={handleInputChange}
                            value={dadosfornecedor.contabil}
                        />
                    </div>

                    <div className="col-xl-2 col-md-6 col-sm-6 mt-1">
                        <small className="text-secondary">Ativo</small>
                        <div className="form-control mb-2">
                            <select
                                name="ativofor"
                                onChange={handleInputChange}
                                value={dadosfornecedor.ativofor}
                            >
                                <option value="S">Sim</option>
                                <option value="N">Não</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
            <br />
            <div className="row align-items-end footer">
                <div className="text-center">
                    <button className="btn btn-outline-primary col-xl-4 col-md-4 col-sm-12 me-3" onClick={handleCancelar}>
                        Cancelar
                    </button>
                    <button className="btn btn-primary col-xl-4 col-md-4 col-sm-12" onClick={handleSalvar}>
                        Salvar Dados
                    </button>
                </div>
            </div>
        </div>
    </>
}

export default CadastroFornecedor;