import React, { useState, useEffect } from 'react';
import api from "../../services/api.js";
import { useNavigate } from 'react-router-dom';
import logobody from '../../images/Nortesoft.png'; // Substitua pelo caminho correto para o seu logo
import logo from '../../images/NortesoftPretaSemFundo.png'; // Substitua pelo caminho correto para o seu logo


import './login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [empresas, setEmpresas] = useState([]);
    const navigate = useNavigate();
    const [buttonText, setButtonText] = useState('Login');
    const [empresaescohida, setEmpresaEscolhida] = useState({
        cdemp: '',
        deemp: '',
        ativoemp: '',
        apelido: '',
        Cidemp: '',
        Estemp: '',
        Baiemp: '',
        TipoEmp: '',
    });

    const [dadosEmpresa, setDadosEmpresa] = useState({
        cdemp: '',
        deemp: '',
        ativoemp: '',
        apelido: '',
        Cidemp: '',
        Estemp: '',
        Baiemp: '',
        TipoEmp: '',
    });

    const handleLogin = () => {

        if (buttonText === 'Login') {
            api.post(`/login`,
                {
                    login: email,
                    senha: password
                },
                {
                    params: { user: email }
                }
            )
                .then((resp) => {
                    const YOUR_JWT_TOKEN = resp.data.token;
                    // Verifique se o token está presente antes de salvá-lo.
                    if (YOUR_JWT_TOKEN) {
                        localStorage.setItem('token', YOUR_JWT_TOKEN);

                        api.get(`/t_emp/user/${email}`, {
                            params: {
                                user: email,
                            }
                        })
                            .then((resp) => {
                                const modifiedData = {
                                    total_registros: resp.data.count,
                                    dados: resp.data.rows
                                };
                                if (modifiedData.total_registros > 0) {
                                    setEmpresas(modifiedData.dados);
                                    setEmpresaEscolhida(modifiedData.dados[0]);
                                    setButtonText('Acessar');
                                } else {
                                    setEmpresas([]);
                                    alert("Login inválido!");
                                }
                            })
                            .catch((err) => {
                                setEmpresas([]);
                                alert("Erro ao buscar empresas!");
                            });

                    } else {
                        // Você pode querer lidar com a ausência do token de uma maneira específica, talvez alertando o usuário ou escrevendo em um log.
                        alert("Token não recebido!");
                    }
                })
                .catch((err) => {
                    alert("Login inválido!");
                });

        } else {

            localStorage.setItem("cdemp", empresaescohida.cdemp);
            localStorage.setItem("cidemp", empresaescohida.Cidemp.trim());
            localStorage.setItem("estemp", empresaescohida.Estemp.trim());
            localStorage.setItem("baiemp", empresaescohida.Baiemp.trim());
            localStorage.setItem("tipoemp", empresaescohida.TipoEmp.trim());
            localStorage.setItem("user", email.trim());

            navigate("/dashboard");
        }
    };

    const containerStyle = {
        backgroundColor: '#f7f7f7'
    };

    const handleCancel = () => {
        setEmail('');
        setPassword('');
        navigate("/");
    };

    const handleSelectEmpChange = (e) => {
        const selectedCdemp = Number(e.target.value);

        const selectedEmpresa = empresas.find(emp => emp.cdemp === selectedCdemp);

        if (selectedEmpresa) {
            setEmpresaEscolhida(selectedEmpresa);
        } else {
            setEmpresaEscolhida({
                cdemp: '',
                deemp: '',
                ativoemp: '',
                apelido: '',
                Cidemp: '',
                Estemp: '',
                Baiemp: '',
            });
        }
    };

    useEffect(() => {
        localStorage.setItem("cdemp", empresaescohida.cdemp);
        localStorage.setItem("cidemp", empresaescohida.Cidemp.trim());
        localStorage.setItem("estemp", empresaescohida.Estemp.trim());
        localStorage.setItem("baiemp", empresaescohida.Baiemp.trim());
        localStorage.setItem("tipoemp", empresaescohida.TipoEmp.trim());
        localStorage.setItem("user", email.trim());
    }, [empresaescohida]);
    

    // useEffect(() => {
       
    // }, [empresaescohida]); // esta dependência garante que o efeito seja executado apenas quando empresaescohida mudar

    return (
        <div className='container'>
            <div className="login-container" style={containerStyle}>

                <div className='container'>
                    <img src={logobody} alt="logobody" className="logobody" />
                </div>

                <div className="login-form">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <div className='col-xl-12 col-md-12 col-sm-12 mt-0'>
                        <small className="text-secondary">Unidade</small>
                        <select
                            name="unidade"
                            className="form-control mt-1 mb-3"
                            value={empresaescohida.cdemp}
                            onChange={handleSelectEmpChange}
                        >
                            {empresas.map((item) => (
                                <option key={item.cdemp} value={item.cdemp}>
                                    {item.apelido}
                                </option>
                            ))}
                        </select>


                    </div>

                    <div className="buttons">
                        <button className="btn btn-secondary" onClick={handleCancel}>Voltar</button>
                        <button className="btn btn-primary" onClick={handleLogin}> {buttonText} </button>
                    </div>


                </div>
            </div>
        </div>



    );
};

export default Login;
