import React from 'react';
import './footer.css';

const Footer = () => {

    const handleWhatsAppClick = (event) => {
        event.preventDefault();  // Previne o comportamento padrão do link

        if (/Mobi|Android/i.test(navigator.userAgent)) {
            // É um dispositivo móvel
            window.location.href = "https://wa.me/559281630344";
        } else {
            // É um desktop
            window.location.href = "https://web.whatsapp.com/send?phone=559281630344";
        }
    };

    return (
        <div className="footer-container">
            <div className="social-links">
                <div className='company-details p 
                                col-xl-4 col-md-4 col-sm-12'>
                    <p className='mt-1'>Nortesoft - Tecnologia e Automação Comercial</p>
                    <p>Email: karlouchoa@gmail.com</p>
                </div>
                <div className='col-xl-4 col-md-4 col-sm-12 d-flex justify-content-center align-items-center'>
                    <p className='mt-2'>Karlo Uchôa - Whatsapp: (92) 98163-0344 </p>
                </div>
                <div className='col-xl-4 col-md-4 col-sm-12 text-right'>

                    <a href="https://facebook.com/sistemaraposa" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-facebook"></i>
                    </a>
                    <a href="https://instagram.com/sistemaraposa" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-instagram"></i>
                    </a>
                    <a href="https://twitter.com/@sistemaraposa" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-twitter"></i>
                    </a>
                    <a href="https://wa.me/559281630344" onClick={handleWhatsAppClick} target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-whatsapp"></i>
                    </a>
                  
                </div>
            </div>

        </div>
    );
}

export default Footer;
