import React, { useEffect, useState, useCallback } from "react";
import Menu from "../../components/menu/menu.jsx";
import Busca from "../../components/busca/busca.jsx";
import Indicador from "../../components/indicador/indicador.jsx";
import Grafico from "../../components/grafico/grafico.jsx";
import api from "../../services/api.js";
import "../../style/global.css";

function Dashboard() {
  const [dadosIndicadores, setDadosIndicadores] = useState({
    "receitas_mes": 0,
    "qtd_rec_mes": 0,
    "receitas_dia": 0,
    "qtd_rec_dia": 0
  });
  const [dadosAnual, setDadosAnual] = useState([["Mês", "Valor"], [0, 0]]);
  
  const carregarEmpresa = useCallback(() => {
    api.get("/t_emp/1?user=" + localStorage.getItem("user"))
      .then((resp) => {
        
        
        localStorage.setItem("cdemp",resp.data.cdemp);
        localStorage.setItem("cidemp",resp.data.Cidemp.trim());
        localStorage.setItem("estemp",resp.data.Estemp.trim());
        localStorage.setItem("baiemp",resp.data.Baiemp.trim());
        
      })
      .catch((err) => {
         alert("Erro ao carregar empresa");
      });

  }, []);

  const montarIndicadores = useCallback(() => {
    // Get JSON do Servidor
    api.get("/dashboard?user=" + localStorage.getItem("user"))
      .then((resp) => {
        setDadosIndicadores(resp.data);
      })
      .catch((err) => {
        setDadosIndicadores({
          "receitas_mes": 0,
          "qtd_rec_mes": 0,
          "receitas_dia": 0,
          "qtd_rec_dia": 0
        });
        alert("Erro ao carregar dashboard...");
      });
  }, []);

  const montarGraficoAnual = useCallback(() => {
    // get JSON do Servidor
    api.get("/grafico?user=" + localStorage.getItem("user"))
      .then((resp) => {
        setDadosAnual(resp.data);
      })
      .catch((err) => {
        setDadosAnual([["Mês", "Valor"], [0, 0]]);
        alert("Erro ao carregar grafico...");
      });
  }, []);

  const recarregar = useCallback(() => {
    montarIndicadores();
    montarGraficoAnual();
  }, [montarIndicadores, montarGraficoAnual]); // Passando as dependências

  useEffect(() => {
    montarIndicadores();
    montarGraficoAnual();
    carregarEmpresa();
  }, [montarIndicadores, montarGraficoAnual, carregarEmpresa]); // Passando as dependências

  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
            <Menu page="dashboard" />
          </div>

          <div className="col py-3 me-3">
            <div className="mb-5">
              <Busca texto="Digite o nome do associado" />
            </div>

            <div className="d-flex justify-content-between">
              <h2>Dashboard</h2>
              <button onClick={recarregar} className="btn btn-primary ms-4">
                Atualizar
              </button>
            </div>

            <div className="row">
              <div className="col-md-3 mt-4">
                <Indicador
                  titulo="Recebidos no mês"
                  valor={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dadosIndicadores.receitas_mes)}
                  rodape={`${dadosIndicadores.qtd_rec_mes} títulos`}
                />
              </div>

              <div className="col-md-3 mt-4">
                <Indicador
                  titulo="A Receber do mês"
                  // eslint-disable-next-line
                  valor={`${dadosIndicadores.qtd_rec_dia} títulos`}
                  rodape={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dadosIndicadores.receitas_dia)}
                />
              </div>

              <div className="col-12 mt-5">
                <Grafico
                  titulo="Gráfico de Contas a Receber"
                  chartType="Line"
                  dados={dadosAnual}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
