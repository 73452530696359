import { useEffect, useState, useCallback } from "react";
import { Dock } from "react-dock";
import ClienteInfoTabs from '../../../components/clienteinfotabs/clienteinfotabs.jsx';
import "./clientescad.css";
import api from "../../../services/api.js";
import moment from 'moment';

function ClienteCad() {

  const [show, setShow] = useState(false);
  const [operacao, setOperacao] = useState("");
  const [codcli, setCodcli] = useState("");
  const [tipoemp, setTipoemp] = useState();
  const [titulo, setTitulo] = useState();
  const [dadosCliente, setDadosCliente] = useState({

    // Informações Principais
    cdcli: '',
    cdemp: 1,
    tipocli: '',
    decli: '',
    fantcli: '',
    cnpj_cpfcli: '',
    insc_rgcli: '',
    dddcli: '',
    fonecli: '',
    nasctocli: '',
    endcli: '',
    numcli: '',
    compcli: '',
    cepcli: '',
    estcli: localStorage.getItem('estemp'),
    cidcli: localStorage.getItem('cidemp'),
    baicli: localStorage.getItem('baiemp'),
    ativocli: '',


    //  Contatos
    contato: '',
    cargo: '',
    dddcont: '',
    fonerespcli: '',
    nascimento: '',
    emailcli: '',
    sitecli: '',
    endent: '',
    baient: '',
    cident: '',
    estent: '',
    cepent: '',
    comEnt: '',

    // Fotografia
    fotocli: '',
  }); // Novo estado para armazenar os dados

  const [dadosSocio, setDadosSocio] = useState({
    AUTOCOD: '',
    CDEMP: localStorage.getItem('cdemp'),
    DTADMISSAO: '',
    DTAUTORIZACAO: '',
    DTFILICAO: '',
    CDCLI: '',
    CHAVEPIX: '',
    CLASSE: '',
    CONTA: '',
    DEPARTAMENTO: 'Solteiro',
    CURSO: '',
    DIGAGENCIA: '',
    DIGCONTA: '',
    ESTADOCIVIL: '',
    NATURALIDADE: '',
    NIVEL: '',
    OPCONTRIB: 'Desconto em folha',
    REGTRAB: '',
    SITUACAO: 'Ativo',
    TIPOCONTA: '',
    TITULACAO: '',
    TPCHAVEPIX: '',
    UNIDADE: '',
  });

  const clearFormData = () => {

    setDadosCliente({
      // Defina todos os campos de dados do cliente como vazios ou seus valores padrão
      cdcli: '',
      cdemp: localStorage.getItem('cdemp'),
      tipocli: 'F',
      decli: '',
      fantcli: '',
      cnpj_cpfcli: '',
      insc_rgcli: '',
      dddcli: '',
      fonecli: '',
      nasctocli: '',
      endcli: '',
      numcli: '',
      compcli: '',
      cepcli: '',
      estcli: localStorage.getItem('estemp'),
      cidcli: localStorage.getItem('cidemp'),
      baicli: localStorage.getItem('baiemp'),
      ativocli: 'S',
      contato: '',
      cargo: '',
      dddcont: '',
      fonerespcli: '',
      nascimento: '',
      emailcli: '',
      sitecli: '',
      endent: '',
      baient: '',
      cident: '',
      estent: '',
      cepent: '',
      comEnt: '',
      fotocli: '',
      contabil: '', // Certifique-se de adicionar todos os campos aqui
    });

    setDadosSocio({
      AUTOCOD: '',
      CDEMP: localStorage.getItem('cdemp'),
      DTADMISSAO: '',
      DTAUTORIZACAO: '',
      DTFILICAO: '',
      CDCLI: '',
      CHAVEPIX: '',
      CLASSE: '',
      CONTA: '',
      DEPARTAMENTO: '',
      DIGAGENCIA: '',
      DIGCONTA: '',
      ESTADOCIVIL: 'Solteiro',
      NATURALIDADE: '',
      NIVEL: '',
      OPCONTRIB: 'Desconto em folha',
      REGTRAB: '',
      SITUACAO: 'Ativo',
      TIPOCONTA: '',
      TITULACAO: '',
      TPCHAVEPIX: '',
      UNIDADE: '',
      MATRICULA: '',
      CURSO: '',
    });
  };

  // Requisição GET para buscar os dados do cliente pelo ID
  const fetchClientData = useCallback(async (codcli) => {
    api.get("/t_cli/" + codcli, {
      params: {
        user: localStorage.getItem("user")
      }
    })
      .then((resp) => {
        const clientData = resp.data;
        setDadosCliente(clientData);
        //setDadosCliente(resp.data);
      })
      .catch((err) => {
        setDadosCliente();
        alert('Erro ao buscar dados do cliente: ' + err.message);
      });
  }, []);

  // Requisição GET para buscar os dados do sócio pelo ID do cliente
  const fetchDadosSocio = useCallback(async (codcli) => {
    api.get("/t_socio/", {
      params: {
        user: localStorage.getItem("user"),
        cliente: codcli
      }
    })
      .then((resp) => {
        const DadosSocio = {
          total_registros: resp.data.count,
          dados: resp.data.rows
        };

        if (DadosSocio.dados.length > 0) {
          setDadosSocio(DadosSocio.dados[0]);
        }

      })
      .catch((err) => {
        setDadosSocio();
        alert('Erro ao buscar dados do sócio: ' + err.message);
      });
  }, []);

  useEffect(() => {
    window.addEventListener('openSidebar', (param) => {
      setShow(true);
      setOperacao(param.detail.operacao);
      setCodcli(param.detail.codcli);
      if (param.detail && param.detail.codcli) {
        fetchClientData(param.detail.codcli);
        fetchDadosSocio(param.detail.codcli);
      }

      if (param.detail.operacao === 'Incluir') {
        handleNovo();
      }

    });
  }, [fetchClientData, fetchDadosSocio]);

  useEffect(() => {
    setTipoemp(localStorage.getItem("tipoemp"));
  }, []);

  useEffect(() => {
    if (tipoemp && typeof tipoemp === "string") {
      const firstTwoDigits = tipoemp.slice(0, 2);

      if (firstTwoDigits === '10') {
        setTitulo("Sindicalizado");
      } else {
        setTitulo("Cliente");
      }
    }
  }, [tipoemp]);


  const handleNovo = () => {

    clearFormData();

  };

  const handleDataChange = (updatedData) => {
    setDadosCliente((dadosCliente) => ({
      ...dadosCliente,
      ...updatedData,
    }));
  };

  const handleSocioChange = (updatedData) => {
    setDadosSocio({ ...dadosSocio, ...updatedData });
  };

  const handleCancelar = () => {
    setShow(false);
  };

  const formatDateForDatabase = (data) => {
    if (!data) {
      return null; // Retorna nulo se a data for falsa ou indefinida
    }

    // Criar um objeto Date com a data fornecida
    const date = new Date(data);

    // Obter o deslocamento de fuso horário em minutos
    const timezoneOffset = date.getTimezoneOffset();

    // Aplicar o deslocamento de fuso horário para ajustar a data
    date.setMinutes(date.getMinutes() + timezoneOffset);

    // Formatar a data para o formato desejado (YYYY-MM-DD)
    const formattedDate = date.toISOString().split('T')[0];

    return formattedDate;
  };


  const handleSalvar = () => {

    const params = new URLSearchParams({
      user: localStorage.getItem("user"),
      cliente: codcli,
      gmt: '-04:00'
    });

    if (dadosCliente) {

      let clienteParaSalvar = { ...dadosCliente };

      if (clienteParaSalvar.nascimento) {
        const dataNascimento = formatDateForDatabase(clienteParaSalvar.nascimento);
        clienteParaSalvar.nascimento = dataNascimento;
      }


      if (clienteParaSalvar.nasctocli) {
        const dataNasctoCli = formatDateForDatabase(clienteParaSalvar.nasctocli);
        clienteParaSalvar.nasctocli = dataNasctoCli;
      }

      if (!clienteParaSalvar.nascimento) {
        delete clienteParaSalvar.nascimento
      }

      if (!clienteParaSalvar.nasctocli) {
        delete clienteParaSalvar.nasctocli
      }

      // Fazer a chamada REST usando os dados
      try {

        if (codcli) {
          // Usar a requisição PUT para atualizar os dados do cliente
          api.put(`/t_cli/${codcli}?${params}`, clienteParaSalvar)
            .then((response) => {
              // Tratar a resposta, se necessário

              if (dadosSocio) {

                const socioParaSalvar = { ...dadosSocio };
                const codoutras = socioParaSalvar.AUTOCOD;
      
                socioParaSalvar.CDCLI = response.data.cdcli;
      
                if (socioParaSalvar.DTADMISSAO) {
                  const dataDTADMISSAO = formatDateForDatabase(socioParaSalvar.DTADMISSAO);
                  socioParaSalvar.DTADMISSAO = dataDTADMISSAO;
                }
      
                if (socioParaSalvar.DTAUTORIZACAO) {
                  const dataDTAUTORIZACAO = formatDateForDatabase(socioParaSalvar.DTAUTORIZACAO);
                  socioParaSalvar.DTAUTORIZACAO = dataDTAUTORIZACAO;
                }
      
                if (socioParaSalvar.DTFILICAO) {
                  const dataDTFILICAO = formatDateForDatabase(socioParaSalvar.DTFILICAO);
                  socioParaSalvar.DTFILICAO = dataDTFILICAO;
                }
      
                if (!socioParaSalvar.DTFILICAO) {
                  delete socioParaSalvar.DTFILICAO
                }
      
                if (!socioParaSalvar.DTAUTORIZACAO) {
                  delete socioParaSalvar.DTAUTORIZACAO
                }
      
                if (!socioParaSalvar.DTADMISSAO) {
                  delete socioParaSalvar.DTADMISSAO
                }
      
                if (codoutras) {
                  api.put(`t_socio/${codoutras}?${params}`, socioParaSalvar)
                    .then((response) => {
      
                    })
                    .catch(() => {
                      window.alert('Erro ao salvar observações do cliente');
                    })
      
                } else {
      
                  delete socioParaSalvar.AUTOCOD;
      
                  api.post(`t_socio?${params}`, socioParaSalvar)
                    .then((response) => {
                      socioParaSalvar.AUTOCOD = response.data.AUTOCOD;
                    })
                    .catch((error) => {
                      window.alert('Erro ao salvar observações do novo cliente:', error);
                    });
                }
              };


            })
            .catch((error) => {
              console.error('Erro ao salvar dados do cliente:', error);
            });

        } else {

          delete clienteParaSalvar.cdcli;
          // Usar a requisição POST para criar um novo cliente
          api.post(`/t_cli?${params}`, clienteParaSalvar)
            .then((response) => {
              // Atribuir valor a clienteParaSalvar.cdcli
              clienteParaSalvar.cdcli = response.data.cdcli;

              // Atribuir valor a setCodcli
              setCodcli(response.data.cdcli);

              if (dadosSocio) {

                const socioParaSalvar = { ...dadosSocio };
                const codoutras = socioParaSalvar.AUTOCOD;
      
                console.log('Codcli', response.data.cdcli);
                socioParaSalvar.CDCLI = response.data.cdcli;
      
                if (socioParaSalvar.DTADMISSAO) {
                  const dataDTADMISSAO = formatDateForDatabase(socioParaSalvar.DTADMISSAO);
                  socioParaSalvar.DTADMISSAO = dataDTADMISSAO;
                }
      
                if (socioParaSalvar.DTAUTORIZACAO) {
                  const dataDTAUTORIZACAO = formatDateForDatabase(socioParaSalvar.DTAUTORIZACAO);
                  socioParaSalvar.DTAUTORIZACAO = dataDTAUTORIZACAO;
                }
      
                if (socioParaSalvar.DTFILICAO) {
                  const dataDTFILICAO = formatDateForDatabase(socioParaSalvar.DTFILICAO);
                  socioParaSalvar.DTFILICAO = dataDTFILICAO;
                }
      
                if (!socioParaSalvar.DTFILICAO) {
                  delete socioParaSalvar.DTFILICAO
                }
      
                if (!socioParaSalvar.DTAUTORIZACAO) {
                  delete socioParaSalvar.DTAUTORIZACAO
                }
      
                if (!socioParaSalvar.DTADMISSAO) {
                  delete socioParaSalvar.DTADMISSAO
                }
      
                if (codoutras) {
                  api.put(`t_socio/${codoutras}?${params}`, socioParaSalvar)
                    .then((response) => {
      
                    })
                    .catch(() => {
                      window.alert('Erro ao salvar observações do cliente');
                    })
      
                } else {
      
                  delete socioParaSalvar.AUTOCOD;
      
                  api.post(`t_socio?${params}`, socioParaSalvar)
                    .then((response) => {
                      socioParaSalvar.AUTOCOD = response.data.AUTOCOD;
                      console.log('socioParaSalvar.AUTOCOD', socioParaSalvar.AUTOCOD);
                    })
                    .catch((error) => {
                      window.alert('Erro ao salvar observações do novo cliente:', error);
                    });
                }
              };


            })
            .catch((error) => {
              console.error('Erro ao salvar novo cliente:', error);
            });

        };

      } catch (error) {
        window.alert('Erro ao salvar todas as informações do cliente');
      } finally {
        window.alert('Dados salvos com sucesso!');
        setShow(false);
      }

    }
  };




  return (
    <Dock
      position="right"
      isVisible={show}
      fluid={false}
      size={620}
    >
      <div className="container-fluid h-100 pt-4 sidebar p-4">
        <h4 className="d-inline">
          {operacao === "edit" ? `Editar ${titulo}` : `Novo ${titulo}`}
        </h4>
        <div className="row tela-cad">
          <ClienteInfoTabs
            formData={dadosCliente}
            operacao={operacao}
            onDataChange={handleDataChange}
            Socio={dadosSocio}
            onSocioChange={handleSocioChange}
          />
        </div>
        <div className="row align-items-end footer">
          <div className="text-center">
            <button className="btn btn-outline-primary me-3" onClick={handleCancelar}>
              Cancelar
            </button>
            <button className="btn btn-primary" onClick={handleSalvar}>
              Salvar Dados
            </button>
          </div>
        </div>
      </div>
    </Dock>
  );
}

export default ClienteCad;
