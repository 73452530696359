import React from 'react';

function InFoClienteAssocicao({ Socio, onSocioChange }) {

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onSocioChange({ [name]: value });
    };


    // Função utilitária para formatar a data
    const formattedData = (data) => {

        if (data === null) {
            data = '1900-01-01T00:00:00.000Z'; // Retorna uma string vazia se a data for nula
        }

        if (data === '') {
            data = '1900-01-01T00:00:00.000Z'; // Retorna uma string vazia se a data for nula
        }

        if (!data) {
            data = '1900-01-01T00:00:00.000Z'; // Retorna uma string vazia se a data for nula
        }

        data = new Date(data).toISOString().split('T')[0];

        return data;


    };

    return <>

        <div className='row'>
            <div className="col-4 mt-0">
                <small className="text-secondary">Matrícula</small>
                <input
                    type="text"
                    name="MATRICULA"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.MATRICULA}
                />
            </div>

            <div className="col-3 mt-0">

            </div>

            <div className="col-5 mt-0">
                <small className="text-secondary">Estado civil</small>
                <div className="form-control mb-2">
                    <select
                        name="ESTADOCIVIL"
                        onChange={handleInputChange}
                        value={Socio.ESTADOCIVIL}
                    >
                        <option >Solteiro</option>
                        <option >Casado</option>
                        <option >Divorciado</option>
                        <option >Outro</option>
                    </select>
                </div>
            </div>
        </div>

        <div className="row">
            <div className='col-7 mt-2'>
                <small className="text-secondary">Naturalidade</small>
                <input
                    type="text"
                    name="NATURALIDADE"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.NATURALIDADE}
                />
            </div>


            <div className='col-5 mt-2 mb-1'>
                <small className="text-secondary">Admissão</small>
                <input type="date"
                    name="DTADMISSAO"
                    className="form-control"
                    onChange={handleInputChange}
                    value={formattedData(Socio.DTADMISSAO)}
                />
            </div>

        </div>

        <div className="row">
            <div className='col-6 mt-2'>
                <small className="text-secondary">Titulação</small>
                <input type="text"
                    name="TITULACAO"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.TITULACAO}
                />
            </div>


            <div className='col-6 mt-2'>
                <small className="text-secondary">Regime de Trabalho</small>
                <input type="text"
                    name="REGTRAB"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.REGTRAB}
                />
            </div>

        </div>


        <div className="row">
            <div className='col-8 mt-2'>
                <small className="text-secondary">Classe</small>
                <input type="text"
                    name="CLASSE"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.CLASSE}
                />
            </div>


            <div className='col-4 mt-2'>
                <small className="text-secondary">Nível</small>
                <input type="text"
                    name="NIVEL"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.NIVEL}
                />
            </div>

        </div>

        <div className="row">
            <div className='col-6 mt-2'>
                <small className="text-secondary">Unidade</small>
                <input type="text"
                    name="UNIDADE"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.UNIDADE}
                />
            </div>


            <div className='col-6 mt-2'>
                <small className="text-secondary">Departamento</small>
                <input type="text"
                    name="DEPARTAMENTO"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.DEPARTAMENTO}
                />
            </div>

        </div>

        <div className="row">
            <div className='col-6 mt-2'>
                <small className="text-secondary">Curso</small>
                <input type="text"
                    name="CURSO"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.CURSO}
                />
            </div>


            <div className='col-6 mt-2 mb-1'>
                <small className="text-secondary">Filiação</small>
                <input type="date"
                    name="DTFILICAO"
                    className="form-control"
                    onChange={handleInputChange}
                    value={formattedData(Socio.DTFILICAO)}
                />
            </div>




        </div>

        <div className="row">
            <div className="col-5 mt-2 mb-1
            ">
                <small className="text-secondary">Situação</small>
                <div className="form-control mb-0">
                    <select name="SITUACAO" id="Situacao"
                        onChange={handleInputChange}
                        value={Socio.SITUACAO}
                    >
                        <option value="Ativo">Ativo</option>
                        <option value="Aposentado">Aposentado</option>
                    </select>
                </div>
            </div>

            <div className="col-7 mt-2">
                <small className="text-secondary">Contribuição por</small>
                <div className="form-control mb-0">
                    <select name="OPCONTRIB" id="tipocli"
                        onChange={handleInputChange}
                        value={Socio.OPCONTRIB}
                    >
                        <option >Desconto em folha</option>
                        <option >Boleto bancário</option>
                        <option >Débito em conta</option>
                        <option >PIX</option>
                    </select>
                </div>
            </div>

        </div>

        <div className="row">
            <h6 className="text mt-4">Dados bancários</h6>
            <div className='col-4 mt-2'>
                <small className="text-secondary">Conta</small>
                <input type="text"
                    name="CONTA"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.CONTA}
                />
            </div>

            <div className='col-2 mt-2'>
                <small className="text-secondary">Dig</small>
                <input type="text"
                    name="DIGCONTA"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.DIGCONTA}
                />
            </div>


            <div className='col-4 mt-2'>
                <small className="text-secondary">Agência</small>
                <input type="text"
                    name="AGENCIA"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.AGENCIA}
                />
            </div>

            <div className='col-2 mt-2'>
                <small className="text-secondary">Dig</small>
                <input type="text"
                    name="DIGAGENCIA"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.DIGAGENCIA}
                />
            </div>

        </div>

        <div className="row">
            <div className='col-4 mt-2'>
                <small className="text-secondary">Banco</small>
                <input type="text"
                    name="BANCO"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.BANCO}
                />
            </div>

            <div className='col-8 mt-2'>
                <small className="text-secondary">Chave PIX</small>
                <input type="text"
                    name="CHAVEPIX"
                    className="form-control"
                    onChange={handleInputChange}
                    value={Socio.CHAVEPIX}
                />
            </div>
        </div>

    </>
}

export default InFoClienteAssocicao;