import React from 'react';
import { Link } from 'react-router-dom';
import './home.css';
import "../../style/global.css";
import logo from '../../images/NortesoftPretaSemFundo.png'; // Substitua pelo caminho correto para o seu logo
import logobody from '../../images/Nortesoft.png'; // Substitua pelo caminho correto para o seu logo
import homem from '../../images/Karlo.png';

import mulher from '../../images/mulher.png';
import Footer from '../../components/footer/footer';
import AdesaoOpcoes from '../../components/adesao/adesao';

const Home = () => {
    return (
        <>

            <div className="home-container">
                <header className="fixed-header">
                    <div></div>
                    <img src={logo} alt="Logo" className="logo" />
                    <nav className="navbar">
                        <Link to="/home">Home</Link>
                        <Link to="/contatos">Contatos</Link>
                        <Link to="/planos">Planos</Link>
                        <Link to="/login">Login</Link>
                    </nav>
                </header>
                <div className="body-content">
                    <>
                        <div className="container-fluid">
                            <div className='row'>
                                <div className='col-xl-3 col-md-4 col-sm-12'>

                                </div>
                                <div className='col-xl-6  col-md-4 col-sm-12 text-center'>
                                    <img src={logobody} alt="Logobody" className="logobody" />
                                    <br />
                                    <h1 className='main-title-alt text-center'>Gestão Inteligente de Negócios.</h1>
                                </div>
                                <div className='col-xl-3 col-md-4 col-sm-12'>
                                </div>
                            </div>

                            <div className='row col-12'>
                                {/* <div className="video-column col-xl-6 col-md-6 col-sm-12">
                            <video className="content-video" autoPlay loop muted>
                                <source src={videoback} type="video/mp4" />
                                Seu navegador não suporta o elemento de vídeo.
                            </video>
                           </div> */}


                                <div className="text-column col-xl-6 
                                                    col-md-6 
                                                    col-sm-12 
                                                    text-right">

                                    <div className="text-box">
                                        <h1 className="main-title">Experiência e Inovação</h1>
                                        <h2 className="sub-title">Desde 2003 contribuindo com a realização de sonhos!</h2>
                                        <p className="description">Nortesoft Tecnologia é uma empresa especializada em soluções de TI, oferecendo produtos e serviços inovadores para ajudar empresas de todos os tamanhos a atingir seus objetivos. Com quase duas décadas de experiência no setor, temos a expertise necessária para fornecer soluções eficazes e personalizadas para nossos clientes.</p>
                                    </div>

                                </div>

                                <div className="background-image 
                                        col-xl-6 col-md-6 col-sm-12"
                                    style={{ backgroundImage: `url(${mulher})` }
                                    }>
                                </div>
                            </div>

                            <br />

                            <div className='row col-12'>
                                {/* <div className="video-column col-xl-6 col-md-6 col-sm-12">
                            <video className="content-video" autoPlay loop muted>
                                <source src={videoback} type="video/mp4" />
                                Seu navegador não suporta o elemento de vídeo.
                            </video>
                        </div> */}

                                <div className="background-image 
                                        col-xl-6 col-md-6 
                                        col-sm-12"
                                    style={{ backgroundImage: `url(${homem})` }
                                    }>
                                </div>
                                <div className="text-column-left col-xl-6 
                                                    col-md-6 
                                                    col-sm-12 
                                                    text-left">

                                    <div className="text-box-left">
                                        <h1 className="main-title">Praticidade e Segurança</h1>
                                        <h2 className="sub-title">Um refinamento de processos que somente o tempo pode dar!</h2>
                                        <p className="description">Na era digital, a busca por soluções que otimizem o tempo e protejam nossos dados tornou-se imperativa. Com anos de dedicação e experiência, aprimoramos continuamente nossos sistemas e processos para oferecer a você a combinação perfeita de praticidade e segurança. Nossa trajetória tem sido marcada por adaptações, aprendizado e evolução, sempre com o objetivo de garantir que sua experiência seja eficiente e, acima de tudo, segura. Conosco, você tem a tranquilidade de saber que está em mãos confiáveis, enquanto aproveita as facilidades que a tecnologia moderna pode oferecer.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    <br />
                </div>

                <div className='row%'>
                    <AdesaoOpcoes />
                    <br />
                    <br />
                </div>

                <div className='row width=100%'>

                    <Footer />

                </div>
            </div>


        </>

    );
}

export default Home;
