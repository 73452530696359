
import Menu from "../../../components/menu/menu.jsx";
import Busca from "../../../components/busca/busca.jsx";
import MyDataTable from "../../../components/mydatatable/mydatatable.jsx";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import ClienteCad from "../clientescad/clientescad.jsx";
import { useEffect, useState } from "react";
import api from "../../../services/api.js";
import Filtrossindicalizados from "../../../components/filtrossindicalizados/filtrossindicalizados.jsx";
import { utils as XLSXUtils, writeFile as XLSXWriteFile } from 'xlsx';
import "./cliente.css";
import ClientesPDF from "../../../components/relatorios/clientes/lista/rel_listacliente.jsx";
import moment from "moment";

function Clientes() {

    // eslint-disable-next-line
    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const [status, setStatus] = useState("");
    const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
    const [dados, setDados] = useState([]);
    const [total_registros, setTotalRegistros] = useState(0);
    const [buscaTexto, setBuscaTexto] = useState("");
    const [tipoemp, setTipoemp] = useState();
    const [titulo, setTitulo] = useState();
    const [filtros, setFiltros] = useState({
        unidade: '',
        curso: '',
        situacao: '',
        aniversariantes: '',
        inifiliacao: '',
        fimfiliacao: '',
        tipocontribuicao: '',
        cpf: '',
        departamento: '',
    });

    const initialState = {
        unidade: '',
        curso: '',
        situacao: '',
        aniversariantes: '',
        inifiliacao: '',
        fimfiliacao: '',
        tipocontribuicao: '',
        cpf: '',
        departamento: '',
    };


    const handleDataChange = (updatedfiltros) => {
        setFiltros((filtros) => ({
            ...filtros,
            ...updatedfiltros,
        }));
    };

    const formatCNPJ = (cnpj) => {
        if (cnpj.length <= 11) {
            return cnpj.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else {
            return cnpj.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
    };

    const commonColumns = [
        {
            name: 'Código',
            selector: row => row.cdcli,
            sortable: true,
            width: "90px",
        },
        {
            name: 'Descrição',
            selector: row => row.decli,
            sortable: true,
            compact: true,
            width: "200px",
        },
        {
            name: 'Telefone',
            selector: row => (
                <p className="mb-1">({formatDdd(row.dddcli)}) {formatTelefone(row.fonecli)}</p>
            ),
            sortable: true,
            width: "160px",
        },
        {
            name: 'Cidade',
            selector: row => row.cidcli,
            sortable: true,
            width: "120px",
        },
        {
            name: 'Bairro',
            selector: row => row.baicli,
            sortable: true,
            width: "170px",
            compact: true,
        },
        {
            cell: (row) => (
                <div className="right-aligned-cell">
                    <button onClick={() => WhatsApp(row.dddcli + row.fonecli)} className={row.fonecli ? "btn btn-success" : "btn btn-success disabled"}>
                        <i className="bi bi-whatsapp"></i>
                    </button>
                    <button onClick={() => Editar(row.cdcli)} className="btn btn-primary ms-1">
                        <i className="bi bi-pencil-square"></i>
                    </button>
                    <button onClick={() => Excluir(row.cdcli)} className="btn btn-danger ms-1">
                        <i className="bi bi-trash3-fill"></i>
                    </button>
                </div>
            ),
            width: "200px",
            right: true
        }
    ];

    const commonColumns2 = [
        {
            name: 'CÓDIGO',
            selector: row => row.cdcli,
            sortable: true,
            width: '9%',
            style: {
                fontSize: "11px"
            },
        },
        {
            name: 'NOME',
            selector: row => row.decli,
            sortable: true,
            compact: true,
            width: '17%',
            style: {
                fontSize: "11px",
            },
        },
        {
            name: 'CPF',
            selector: row => formatCNPJ(row.cnpj_cpfcli),
            sortable: true,
            compact: true,
            width: '8%',
            style: {
                fontSize: "11px",
            },
        },
        {
            name: 'SIAP',
            selector: row => row.matricula,
            sortable: true,
            compact: true,
            width: '7%',
            style: {
                fontSize: "11px",
                margin: "-1 1px"
            },
        },
        {
            name: 'TELEFONE',
            selector: row => (
                <p className="mb-1">({formatDdd(row.dddcli)}) {formatTelefone(row.fonecli)}</p>
            ),
            sortable: true,
            width: '11%',
            style: {
                fontSize: "11px",
            },
        },

        {
            name: 'UNIDADE',
            selector: row => row.unidade,
            sortable: true,
            compact: true,
            width: '7%',
            style: {
                fontSize: "11px",
            },
        },

        {
            name: 'CURSO',
            selector: row => row.curso,
            sortable: true,
            compact: true,
            width: '15%',
            style: {
                fontSize: "11px",
            },
        },
        filtros.aniversariantes !== '' ? {
            name: 'NASCIMENTO',
            selector: row => formatarData(row.nasctocli),
            sortable: true,
            compact: true,
            width: '10%',
            style: {
                fontSize: "11px",
                textAlign: 'center',
            },
        } : {
            name: 'SITUAÇÃO',
            selector: row => row.situacao.toUpperCase(),
            sortable: true,
            compact: true,
            width: '10%',
            style: {
                fontSize: "11px",
                textAlign: 'center',
            }
        },

        {
            name: 'Filiado',
            selector: row => row.ativocli.toUpperCase(),
            sortable: true,
            compact: true,
            width: '5%',
            style: {
                fontSize: "11px",
            },
        },

        {
            cell: (row) => (
                <div className="right-aligned-cell">
                    <button onClick={() => WhatsApp(row.dddcli + row.fonecli)} className={row.fonecli ? "btn btn-success" : "btn btn-success disabled"}>
                        <i className="bi bi-whatsapp"></i>
                    </button>
                    <button onClick={() => Editar(row.cdcli)} className="btn btn-primary ms-1">
                        <i className="bi bi-pencil-square"></i>
                    </button>
                    <button onClick={() => Excluir(row.cdcli)} className="btn btn-danger ms-1">
                        <i className="bi bi-trash3-fill"></i>
                    </button>
                </div>
            ),
            width: "12%",
            right: true
        }
    ];

    let columns = [];

    if (tipoemp && typeof tipoemp === "string") {
        const firstTwoDigits = tipoemp.slice(0, 2);

        if (firstTwoDigits === '10') {
            columns = [...commonColumns2]; // Use common columns
        } else {
            columns = [...commonColumns]; // Use common columns

        }
    }

    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };


    const formatTelefone = (telefone) => {
        if (telefone) {
            return telefone.replace(/\D/g, '').replace(/(\d{1})(\d{4})(\d{4})/, '$1 $2-$3');
        }
    };

    const formatCEP = (cep) => {
        if (cep) {
            return cep.replace(/\D/g, '').replace(/(\d{5})(\d{3})/, '$1-$2');
        }
    };

    const paginationOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    function formatDdd(ddd) {
        if (ddd && ddd.length >= 2) {
            return ddd.substring(ddd.length - 2); // Retorna os dois últimos algarismos
        }
        return ddd;
    }

    function WhatsApp(fone) {
        if (fone) {
            const url = "https://web.whatsapp.com/send?phone=55" + fone;
            window.open(url, "_blank", "noreferrer");
        }
    }

    function Excluir(id) {
        confirmAlert({
            title: 'Exclusão',
            message: 'Confirma a exclusão do registro?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        api.delete("/t_cli/" + id, {
                            params: {
                                user: localStorage.getItem("user"),
                            }
                        })
                            .then((resp) => {
                                iniciarRequisicao(
                                    localStorage.getItem("user"),
                                    status === "" ? "Todos" : status,
                                    buscaTexto, 1, qtd_reg_pagina, filtros);
                                alert('Registro: ' + id + ' Excluído com sucesso!')
                            })
                            .catch((err) => {
                                alert("Erro ao excluir cliente.");
                            });


                    }


                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    function Editar(id) {
        const event = new CustomEvent("openSidebar", {
            detail: {
                operacao: "edit",
                codcli: id
            }
        });

        window.dispatchEvent(event);

    }

    function Incluir(id) {
        const event = new CustomEvent("openSidebar", {
            detail: {
                operacao: "Incluir"
            }
        });

        window.dispatchEvent(event);

    }

    function Listar(user, status, decli, page, pageSize) {
        // get JSON do Servidor
        api.get("/t_cli", {
            params: {
                user,
                status,
                decli,
                page,
                pageSize,
                unidade: filtros.unidade,
                curso: filtros.curso,
                situacao: filtros.situacao,
                aniversariantes: filtros.aniversariantes,
                inifiliacao: filtros.inifiliacao,
                fimfiliacao: filtros.fimfiliacao,
                tipocontribuicao: filtros.tipocontribuicao,
                cpf: filtros.cpf,
                departamento: filtros.departamento,
            }
        })
            .then((resp) => {
                const modifiedData = {
                    total_registros: resp.data.count,
                    dados: resp.data.rows
                };
                setDados(modifiedData.dados);
                setTotalRegistros(modifiedData.total_registros);
            })
            .catch((err) => {
                setTotalRegistros(0)
                setDados([]);
                console.log("Erro", err);
                alert("Erro ao carregar clientes...");
            });
    }

    function ChangeStatus(e) {
        setStatus(e.target.value);
        iniciarRequisicao(
            localStorage.getItem("user"),
            e.target.value,
            buscaTexto, 1, qtd_reg_pagina, filtros);

    }

    function ChangePerRows(newQtd, page) {
        iniciarRequisicao(
            localStorage.getItem("user"),
            status === "" ? "Todos" : status,
            buscaTexto, page, newQtd, filtros);
        setQtdRegPagina(newQtd);
    }

    function ChangePage(page) {
        iniciarRequisicao(localStorage.getItem("user"),
            status === "" ? "Todos" : status,
            buscaTexto, page, qtd_reg_pagina, filtros);
    }

    function handleBusca(texto) {
        setBuscaTexto(texto);
        iniciarRequisicao(
            localStorage.getItem("user"),
            status === "" ? "Todos" : status,
            texto, 1, qtd_reg_pagina, filtros);
    }

    function isFiltersInInitialState(filtros) {
        // Verifique se cada campo do estado filtros é igual ao valor inicial
        for (const key in filtros) {
            if (filtros[key] !== initialState[key]) {
                // Se qualquer campo for diferente do valor inicial, retornar false
                return false;
            }
        }
        // Se todos os campos forem iguais ao valor inicial, retornar true
        return true;
    }

    // Função para iniciar a requisição REST
    const iniciarRequisicao = async (user, status, decli,
        page, pageSize, filtros) => {
        try {
            // get JSON do Servidor

            api.get("/t_cli", {
                params: {
                    user,
                    status,
                    decli,
                    page,
                    pageSize,
                    unidade: filtros.unidade,
                    curso: filtros.curso,
                    situacao: filtros.situacao,
                    aniversariantes: filtros.aniversariantes,
                    inifiliacao: filtros.inifiliacao,
                    fimfiliacao: filtros.fimfiliacao,
                    tipocontribuicao: filtros.tipocontribuicao,
                    cpf: filtros.cpf,
                    departamento: filtros.departamento,
                    gmt: 'AMT - Amazon Time'
                }
            })
                .then((resp) => {
                    const modifiedData = {
                        total_registros: resp.data.count,
                        dados: resp.data.dados
                    };

                    setDados(modifiedData.dados);
                    setTotalRegistros(modifiedData.total_registros);
                })
                .catch((err) => {
                    setTotalRegistros(0)
                    setDados([]);
                    alert("Erro ao carregar clientes...");
                });
        } catch (err) {
            setTotalRegistros(0);
            setDados([]);
            alert("Erro ao carregar clientes...");
        }
    };

    useEffect(() => {
        setFiltros({
            unidade: '',
            curso: '',
            situacao: '',
            aniversariantes: '',
            inifiliacao: '',
            fimfiliacao: '',
            tipocontribuicao: '',
            cpf: '',
            departamento: '',
        });


        // Listar(localStorage.getItem("user"),
        //     status === "" ? "Todos" : status,
        //     buscaTexto, 1, qtd_reg_pagina);

        iniciarRequisicao(
            localStorage.getItem("user"),
            status === "" ? "Todos" : status,
            buscaTexto, 1, qtd_reg_pagina, filtros);

    }, [qtd_reg_pagina, status, buscaTexto]);

    useEffect(() => {
        setTipoemp(localStorage.getItem("tipoemp"));
    }, []);

    useEffect(() => {
        if (tipoemp && typeof tipoemp === "string") {
            const firstTwoDigits = tipoemp.slice(0, 2);

            if (firstTwoDigits === '10') {
                setTitulo("Sindicalizados");
            } else {
                setTitulo("Clientes");
            }
        }
    }, [tipoemp]);

    const formatarData = (data) => {
        const dataFormatada = new Date(data);
        const dia = dataFormatada.getUTCDate().toString().padStart(2, '0');
        const mes = (dataFormatada.getMonth() + 1).toString().padStart(2, '0');
        const ano = dataFormatada.getFullYear();
        return `${dia}/${mes}/${ano}`;
    };


    const exportToExcel = () => {
        // Selecione as colunas que você deseja exportar
        const columnsToExport = dados.map((item) => ({
            Codigo: item.cdcli,
            Nome: item.decli,
            DDD: item.dddcli,
            Fone: item.fonecli,
            ST: item.ativocli === 'S' ? 'ATIVO' : 'APOSENTADA', // Exemplo de formatação de Situacao
            Admissao: formatarData(item.DTFILICAO), // Formate a data de admissão
            Situacao: item.situacao === 'ATIVO' ? 'ATIVO' : 'APOSENTADA', // Exemplo de formatação de Situacao
            Nascimento: formatarData(item.nasctocli), // Formate a data de nascimento
            Curso: item.curso,
            // Adicione mais colunas conforme necessário
        }));

        // Crie uma planilha Excel
        const ws = XLSXUtils.json_to_sheet(columnsToExport);

        // Crie um livro Excel e adicione a planilha criada
        const wb = XLSXUtils.book_new();
        XLSXUtils.book_append_sheet(wb, ws, "Clientes");

        // Exporte o livro Excel para um arquivo .xlsx
        XLSXWriteFile(wb, "clientes.xlsx");
    };


    // const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
    return (
        <div className={isMenuVisible ? "app-container" : "app-container-local"} >

            {isMenuVisible && (
                <div className="menu-visible col-auto col-md-3 col-xl-2 px-sm-2 px-0">
                    <Menu page="empresas" />
                </div>
            )}

            <div className="row flex-nowrap">
                <ClienteCad />
                <div className="container-fluid">
                    <div className="row flex-nowrap">

                        <div className={`col py-3 me-4 ${isMenuVisible ? '' : 'expand-content'}`}>
                            <div className="mb-5">
                                <Busca texto="Digite o nome do cliente"
                                    onSearch={handleBusca}
                                    onToggleMenu={toggleMenu} />
                            </div>

                            <div className="bg-white p-4 rounded-4 border">
                                <div className="d-flex justify-content-between mb-3" >

                                    <div className="d-inline-flex">

                                        <h2>{titulo}</h2>

                                        <div className="form-control ms-4">
                                            <select name="status" id="status" onChange={ChangeStatus}  >
                                                <option value="Todos">Todos</option>
                                                <option value="Ativos">Ativos</option>
                                                <option value="Inativos">Inativos</option>
                                            </select>
                                        </div>

                                    </div>

                                    <button onClick={Incluir} className="btn btn-primary ms-4"><i className="bi bi-plus-lg me-2"></i>Novo</button>

                                </div>

                                <div>
                                    <Filtrossindicalizados
                                        filtros={filtros}
                                        onDataChange={handleDataChange}
                                        iniciarRequisicao={iniciarRequisicao}
                                        initialState={initialState}
                                        qtd_reg_pagina={qtd_reg_pagina} />
                                    <br />
                                </div>

                                <div className="container-tabela">
                                    <MyDataTable
                                        columns={columns}
                                        data={dados}
                                        pagination={true}
                                        paginationComponentOptions={paginationOptions}
                                        noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}
                                        paginationServer={true}
                                        paginationTotalRows={total_registros}
                                        onChangeRowsPerPage={ChangePerRows}
                                        onChangePage={ChangePage}
                                        responsive={true}
                                    // expandableRows
                                    // expandableRowsComponent={ExpandedComponent}
                                    />
                                </div>

                                <button onClick={exportToExcel} className="btn btn-success btn-cli">
                                    <i className="bi bi-file-excel me-2"></i>Gerar Excel
                                </button>
                                <button onClick={() => ClientesPDF(dados, filtros)} className="btn btn-danger btn-cli">
                                    <i className="fa-regular fa-file-pdf me-2"></i>Gerar PDF
                                </button>

                            </div>

                            <div className="row">

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}

export default Clientes;