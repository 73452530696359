
import Menu from "../../../../components/menu/menu";
import Busca from "../../../../components/busca/busca";

function ContasAPagar() {

    return <>
        <div className="container-fluid">
            <div className="row flex-nowrap">
                <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
                    <Menu page="contasapagar" />
                </div>
                <div className="col py-3 me-3">

                    <Busca texto="Digite a nome do fornecedor" />
                    <h2>Consulta de Contas a Pagar</h2>
                </div>
            </div>
        </div>
    </>
}

export default ContasAPagar;